import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/arrow-left-gray.svg'
import _imports_1 from '@/assets/arrow-left.svg'
import _imports_2 from '@/assets/arrow-right-gray.svg'
import _imports_3 from '@/assets/arrow-right.svg'
import _imports_4 from '@/assets/calendar-icon.svg'
import _imports_5 from '@/assets/download-icon.svg'
import _imports_6 from '@/assets/trash-icoon.svg'
import _imports_7 from '@/assets/arrow-up-right.svg'
import _imports_8 from '@/assets/no-documents-image.svg'


const _hoisted_1 = { class: "h-[356px] w-full p-3 bg-white rounded-primary-radius" }
const _hoisted_2 = { class: "h-full overflow-hidden" }
const _hoisted_3 = { class: "flex items-center justify-between mb-2" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center gap-6"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: ""
}
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  src: _imports_2,
  alt: "Arrow right"
}
const _hoisted_10 = {
  src: _imports_3,
  alt: "Arrow right"
}
const _hoisted_11 = {
  key: 1,
  class: "w-full h-[290px] overflow-y-auto flex flex-col items-start gap-2"
}
const _hoisted_12 = { class: "text-base leading-[20px] text-[#393840] font-medium tracking-[0.01rem] py-1" }
const _hoisted_13 = { class: "p-2 list-none flex flex-col gap-[2px] rounded-primary-radius border" }
const _hoisted_14 = { class: "flex flex-col pt-[6px] pb-[4px]" }
const _hoisted_15 = { class: "flex-[80%] text-base text-primary-text-color font-normal tracking-[0.01rem]" }
const _hoisted_16 = { class: "flex items-center text-sm gap-1 text-[#a7a5ae]" }
const _hoisted_17 = { class: "text-[#a7a5ae] font-normal tracking-[0.01rem]" }
const _hoisted_18 = { class: "flex-[20%] flex items-center justify-end" }
const _hoisted_19 = ["href"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["href"]
const _hoisted_23 = {
  key: 0,
  class: "w-full mt-[70px] flex items-center justify-center"
}

import { Project } from "@/types";
import { computed, ref } from "vue";
import CardLoader from "@/components/Loaders/CardLoader.vue";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import ConfirmModal from "@/components/Re-usable/ConfirmModal.vue";

type Props = {
  isLoading: boolean;
  projectDetails: Project | null;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDocuments',
  props: {
    isLoading: { type: Boolean },
    projectDetails: {}
  },
  emits: ["callgetProjectDetails"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const selectedPage = ref<number>(1);
const isModalVisible = ref(false);

let isLoadingSpinner = ref(false);

let documentPages = computed(() => {
  if (props.projectDetails?.documents) {
    let documentSections = [
      {
        id: 1,
        title: "Baumappe",
        sectionNumber: 1,
        typeIds: [4],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 4
        ),
      },
      {
        id: 2,
        title: "Stage reports",
        sectionNumber: 1,
        typeIds: [5, 6, 7],
        documents: props.projectDetails?.documents.filter(
          (item: any) =>
            item.project_document_type?.id === 5 ||
            item.project_document_type?.id === 6 ||
            item.project_document_type?.id === 7
        ),
      },

      {
        id: 3,
        title: "Abnahmeprotocoll",
        sectionNumber: 2,
        typeIds: [2],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 2
        ),
      },
      {
        id: 4,
        title: "Prüfbericht",
        sectionNumber: 2,
        typeIds: [3],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 3
        ),
      },

      {
        id: 5,
        title: "Other",
        sectionNumber: 3,
        typeIds: [null],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type === null
        ),
      },
    ];

    const filteredSections = documentSections.filter(
      (section: any) => section.documents && section.documents.length > 0
    );

    const pages = [];
    let tempSections = [];
    let currentSectionNumber = filteredSections.length
      ? filteredSections[0].sectionNumber
      : null;
    let pageNumber = 1;

    for (const section of filteredSections) {
      if (section.sectionNumber !== currentSectionNumber) {
        pages.push({
          id: pageNumber++,
          sections: [...tempSections],
        });
        tempSections = [];
        currentSectionNumber = section.sectionNumber;
      }

      tempSections.push(section);
    }
    if (tempSections.length) {
      pages.push({
        id: pageNumber,
        sections: [...tempSections],
      });
    }
    return pages;
  }

  return [];
});

const selectedDocumentId = ref<any>(null);

const refreshBaumappe = async () => {
  isLoadingSpinner.value = true;
  try {
    await client.post(
      `/projects/${props.projectDetails?.id}/document/${selectedDocumentId.value}/refresh`
    );
    successToast("Document refreshed.");
    isLoadingSpinner.value = false;
    emit("callgetProjectDetails");
  } catch (error: any) {
    isLoadingSpinner.value = false;
    console.log(error);
    errorToast(error);
  }
};

const deleteDocument = async () => {
  try {
    await client.delete(
      `/projects/${props.projectDetails?.id}/document/${selectedDocumentId.value}`
    );
    successToast("Document deleted.");
    emit("callgetProjectDetails");
  } catch (error: any) {
    errorToast(error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ConfirmModal, {
      show: isModalVisible.value,
      message: "Are you sure you want to delete this document?",
      onConfirm: deleteDocument,
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (isModalVisible.value = false))
    }, null, 8, ["show"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-xl text-primary-text-color tracking-[0.01rem] font-semibold text-prim" }, " Documents ", -1)),
          (_unref(documentPages).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("button", {
                  disabled: selectedPage.value === 1,
                  class: "cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (selectedPage.value = selectedPage.value - 1))
                }, [
                  (selectedPage.value === 1)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                    : _createCommentVNode("", true),
                  (selectedPage.value !== 1)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_5),
                _createElementVNode("button", {
                  disabled: selectedPage.value === _unref(documentPages).length,
                  class: "cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (selectedPage.value = selectedPage.value + 1))
                }, [
                  (selectedPage.value === _unref(documentPages).length)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
                    [_vShow, selectedPage.value !== _unref(documentPages).length]
                  ])
                ], 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(CardLoader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(documentPages), (page) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: page.id,
                  class: "w-full flex flex-col gap-2"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(page?.sections, (section) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: section.id,
                      class: "border-gray-300"
                    }, [
                      _createElementVNode("h5", _hoisted_12, _toDisplayString(section.title), 1),
                      _createElementVNode("ul", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section?.documents, (document, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: document.id,
                            class: _normalizeClass([{ 'border-t border-[#E3E1EA]': index !== 0 }, "flex items-center justify-between"])
                          }, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("span", _hoisted_15, _toDisplayString(document.name), 1),
                              _createElementVNode("div", _hoisted_16, [
                                _cache[4] || (_cache[4] = _createElementVNode("img", {
                                  class: "w-[14px] h-[14px] opacity-50",
                                  src: _imports_4,
                                  alt: "User icon"
                                }, null, -1)),
                                _createElementVNode("span", _hoisted_17, _toDisplayString(new Date(document.created_at).toLocaleDateString(
                          "de-DE"
                        )), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("a", {
                                class: "bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300",
                                href: `${document.url}?download`,
                                target: "_blank"
                              }, _cache[5] || (_cache[5] = [
                                _createElementVNode("img", {
                                  class: "min-w-[16px] w-[16px] min-h-[16px]",
                                  src: _imports_5,
                                  alt: ""
                                }, null, -1)
                              ]), 8, _hoisted_19),
                              (section.typeIds[0] === 4)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    class: "bg-transparent p-[5px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300",
                                    onClick: 
                      () => {
                        selectedDocumentId.value = document.id;
                        refreshBaumappe();
                      }
                    
                                  }, [
                                    (_openBlock(), _createElementBlock("svg", {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 14 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      class: _normalizeClass(`${_unref(isLoadingSpinner) ? 'animate-spin' : ''}`)
                                    }, _cache[6] || (_cache[6] = [
                                      _createElementVNode("path", {
                                        d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
                                        stroke: "#A7A5AE",
                                        "stroke-width": "2",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round"
                                      }, null, -1)
                                    ]), 2))
                                  ], 8, _hoisted_20))
                                : (_openBlock(), _createElementBlock("button", {
                                    key: 1,
                                    class: "bg-transparent p-[5px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300",
                                    onClick: 
                      () => {
                        selectedDocumentId.value = document.id;
                        isModalVisible.value = true;
                      }
                    
                                  }, _cache[7] || (_cache[7] = [
                                    _createElementVNode("img", {
                                      class: "min-w-[12px] w-[12px] min-h-[12px] filter grayscale",
                                      fill: "currentColor",
                                      src: _imports_6,
                                      alt: ""
                                    }, null, -1)
                                  ]), 8, _hoisted_21)),
                              _createElementVNode("a", {
                                class: "bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300",
                                href: document.url + '?' + new Date().toISOString(),
                                target: "_blank"
                              }, _cache[8] || (_cache[8] = [
                                _createElementVNode("img", {
                                  class: "min-w-[16px] w-[16px] min-h-[16px]",
                                  src: _imports_7,
                                  alt: ""
                                }, null, -1)
                              ]), 8, _hoisted_22)
                            ])
                          ], 2))
                        }), 128))
                      ])
                    ]))
                  }), 128))
                ])), [
                  [_vShow, page.id === selectedPage.value]
                ])
              }), 128)),
              (_unref(documentPages).length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[9] || (_cache[9] = [
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        src: _imports_8,
                        alt: "No documents"
                      }),
                      _createElementVNode("p", { class: "text-sm mt-2 tracking-[0.01rem] font-medium text-[#898790]" }, " No documents ")
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ]))
      ])
    ])
  ], 64))
}
}

})