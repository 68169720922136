<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

defineProps({
  show: Boolean,
  message: {
    type: String,
    default: "Are you sure you want to delete this item?",
  },
});

const emit = defineEmits(["confirm", "cancel"]);

const confirm = () => {
  emit("confirm");
  emit("cancel");
};

const cancel = () => {
  emit("cancel");
};
</script>

<template>
  <Teleport to="body">
    <div
      v-if="show"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-[0.2] transition-opacity z-[1000] tracking-[0.01rem]"
    >
      <div
        class="bg-white flex flex-col gap-[2px] rounded-lg w-[588px] h-[214px] px-[20px] z-[1001]"
      >
        <div class="flex items-center justify-between h-[64px] py-[16px]">
          <h2 class="text-lg font-semibold text-[#393840]">Delete document</h2>
          <button @click="cancel">
            <img class="opacity-75" src="@/assets/close-icon-2.svg" alt="" />
          </button>
        </div>
        <div class="flex flex-col h-[70px] border-b-2 pb-[6px]">
          <p class="text-[#393840]">{{ message }}</p>
          <p class="text-[#393840]">This cannot be undone.</p>
        </div>
        <div class="flex justify-end h-[76px] space-x-[16px] py-[16px]">
          <button
            @click="cancel"
            class="rounded-[7px] border-2 border-primary-text-color px-[12px] font-medium"
          >
            Cancel
          </button>
          <button
            @click="confirm"
            class="flex items-center gap-2 px-[12px] py-[12px] bg-[#F4B6B2] rounded font-semibold"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 18 20"
              fill="#F4B6B2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 15.8V4H5C5 3.06812 5 2.60241 5.15224 2.23486C5.35523 1.74481 5.74432 1.35523 6.23438 1.15224C6.60192 1 7.06812 1 8 1H10C10.9319 1 11.3978 1 11.7654 1.15224C12.2554 1.35523 12.6447 1.74481 12.8477 2.23486C12.9999 2.6024 13 3.06812 13 4H15V15.8031C15 16.921 15 17.4802 14.7822 17.9076C14.5905 18.2839 14.2837 18.5905 13.9074 18.7822C13.48 19 12.921 19 11.8031 19H6.19691C5.07899 19 4.5192 19 4.0918 18.7822C3.71547 18.5905 3.40973 18.2839 3.21799 17.9076C3 17.4798 3 16.9201 3 15.8Z"
                fill="white"
              />
              <path
                d="M11 8V15M7 8V15M3 4V15.8C3 16.9201 3 17.4798 3.21799 17.9076C3.40973 18.2839 3.71547 18.5905 4.0918 18.7822C4.5192 19 5.07899 19 6.19691 19H11.8031C12.921 19 13.48 19 13.9074 18.7822C14.2837 18.5905 14.5905 18.2839 14.7822 17.9076C15 17.4802 15 16.921 15 15.8031V4M3 4H5M3 4H1M5 4H13M5 4C5 3.06812 5 2.60241 5.15224 2.23486C5.35523 1.74481 5.74432 1.35523 6.23438 1.15224C6.60192 1 7.06812 1 8 1H10C10.9319 1 11.3978 1 11.7654 1.15224C12.2554 1.35523 12.6447 1.74481 12.8477 2.23486C12.9999 2.6024 13 3.06812 13 4M13 4H15M15 4H17"
                stroke="#393840"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Delete document
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>
