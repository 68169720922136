import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "w-[calc(100%-220px)]" }
const _hoisted_4 = {
  key: 0,
  class: "flex w-full"
}
const _hoisted_5 = {
  key: 0,
  class: "flex w-full"
}
const _hoisted_6 = {
  key: 1,
  class: "flex w-full"
}
const _hoisted_7 = {
  key: 2,
  class: "flex w-full"
}

import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import AbnahmeView from "./AbnahmeView.vue";
import QcView from "./QcView.vue";
import RegularView from "./RegularView.vue";
import { createClient } from "@supabase/supabase-js";
import { ImageBatch } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'PhasePage',
  setup(__props) {

const route = useRoute();

const phaseData = ref();
const phaseStatusName = ref("");
const isLoading = ref(false);

const selectedPhase = ref();

let supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL || "",
  process.env.VUE_APP_SUPABASE_API_KEY || ""
);

let batches = ref<Array<ImageBatch>>([]);

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

// Fetch data
const getPhaseDetails = async () => {
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;
  isLoading.value = true;
  try {
    const response = await client.get(
      `/projects/${projectId}/phase/${phaseId}`
    );
    phaseData.value = response.data;
    batches.value = response.data.batches;

    let tempPhase = response.data.phases.find(
      (item: any) => Number(item.phaseId) === Number(phaseId)
    );

    phaseStatusName.value = tempPhase ? tempPhase.phaseStatus.name : "";
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const handleImagesChanges = (payload: any) => {
  let newBatchImage = payload.new;
  if (payload.eventType === "INSERT") {
    let tempBatch: any = batches.value.find(
      (item: any) => item.id === newBatchImage.images_batch
    );
    if (tempBatch) {
      tempBatch.images.push(newBatchImage);
    }
  }
  if (payload.eventType === "UPDATE") {
    let tempBatch: any = batches.value.find(
      (item: any) => item.id === newBatchImage.images_batch
    );
    if (tempBatch) {
      let tempImage = tempBatch.images.find(
        (image: any) => image.id === newBatchImage.id
      );
      if (tempImage) {
        tempImage.status = newBatchImage.status;
        tempImage.internet_speed = newBatchImage.internet_speed;
      }
    }
  }
};

const handleImagesBatchChanges = (payload: any) => {
  if (payload.eventType === "INSERT") {
    let newBatch = payload.new;
    newBatch.images = [];
    batches.value.push(newBatch);
  }
};

watch(
  () => route.params.phaseId, // Watch for the 'id' param change in the route
  async (newId, oldId) => {
    if (newId !== oldId) {
      await getPhaseDetails();

      let currentPhaseProgress = phaseData.value.phases.find(
        (item: any) => item.phaseId === Number(newId)
      );

      if (currentPhaseProgress) {
        supabase
          .channel("images_batch")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: "installation_images_batch",
              filter: `phase_progress_id=eq.${currentPhaseProgress?.phaseProgressId}`,
            },
            handleImagesBatchChanges
          )
          .subscribe();
        supabase
          .channel("images")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: "installation_images",
              filter: `phase_progress_id=eq.${currentPhaseProgress?.phaseProgressId}`,
            },
            handleImagesChanges
          )
          .subscribe();
      } else {
        console.error("Cannot find phase progress id.");
      }
      selectedPhase.value = newId; // Trigger the API call when the route param changes
    }
  },
  { immediate: true } // Immediately run on component mount
);

onUnmounted(() => {
  supabase.removeAllChannels();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(MainSidebar, {
            "is-loading": isLoading.value,
            "phase-progress": phaseData.value?.phases,
            "phase-steps": phaseData.value?.steps,
            "selected-phase": Number(selectedPhase.value),
            onOnPhaseSelect: onPhaseSelect,
            "is-link": true
          }, null, 8, ["is-loading", "phase-progress", "phase-steps", "selected-phase"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (phaseData.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (phaseStatusName.value === 'abnahme')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(AbnahmeView, {
                        batches: _unref(batches),
                        "phase-id": Number(_unref(route).params.phaseId),
                        "project-id": _unref(route).params.id + '',
                        "phase-details": phaseData.value,
                        onRefreshData: getPhaseDetails
                      }, null, 8, ["batches", "phase-id", "project-id", "phase-details"])
                    ]))
                  : _createCommentVNode("", true),
                (phaseStatusName.value === 'qc')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(QcView, {
                        batches: _unref(batches),
                        "phase-id": Number(_unref(route).params.phaseId),
                        "project-id": _unref(route).params.id + '',
                        "phase-details": phaseData.value,
                        onRefreshData: getPhaseDetails
                      }, null, 8, ["batches", "phase-id", "project-id", "phase-details"])
                    ]))
                  : _createCommentVNode("", true),
                (phaseStatusName.value !== 'abnahme' && phaseStatusName.value !== 'qc')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(RegularView, {
                        batches: _unref(batches),
                        "phase-id": Number(_unref(route).params.phaseId),
                        "phase-details": phaseData.value,
                        onRefresh: getPhaseDetails
                      }, null, 8, ["batches", "phase-id", "phase-details"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})