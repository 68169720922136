import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/example-archive-trash-icon.svg'
import _imports_1 from '@/assets/pencil-icon.svg'
import _imports_2 from '@/assets/edit-plus-icon.svg'


const _hoisted_1 = { class: "bg-[#E5E4E2] p-3 rounded-primary-radius w-[300px]" }
const _hoisted_2 = { class: "flex items-center justify-between min-h-5 gap-1" }
const _hoisted_3 = { class: "text-primary-text-color text-base font-semibold tracking-[0.01rem]" }
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = {
  key: 0,
  class: "flex items-center gap-2"
}
const _hoisted_6 = { class: "w-6 h-6" }
const _hoisted_7 = { class: "w-6 h-6" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "w-6 h-6" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "mt-3 mb-5 w-[276px] h-[297px] rounded-primary-radius" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "bg-white rounded-primary-radius p-3 shadow-primary-box-shadow" }
const _hoisted_14 = { class: "flex items-center gap-3" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "p-3 rounded-primary-radius bg-[#EEF3F8] mt-4" }
const _hoisted_18 = {
  key: 0,
  class: "text-base leading-[26px] tracking-[0.01rem] font-normal text-primary-text-color"
}
const _hoisted_19 = {
  key: 1,
  class: "text-base leading-[26px] tracking-[0.01rem] font-normal text-primary-text-color"
}

import { ref } from "vue";

type Props = {
  input: any;
};

type Emits = {
  (event: "openDeleteModal", selectedInput: any): void;
  (event: "openArchiveModal", selectedInput: any, isUpdate: boolean): void;
  (event: "closeUpdateModal"): void;
  (event: "closeDeleteModal"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ExampleArchiveCard',
  props: {
    input: {}
  },
  emits: ["openDeleteModal", "openArchiveModal", "closeUpdateModal", "closeDeleteModal"],
  setup(__props: any) {




const selectedLanguage = ref("EN");

const onLanguageSwitchHandler = (lang: string) => {
  selectedLanguage.value = lang;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.input.name), 1),
      _createElementVNode("div", _hoisted_4, [
        (
            _ctx.input &&
            (_ctx.input.exampleImage ||
              (_ctx.input.description &&
                (_ctx.input.description.de || _ctx.input.description.en)))
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openDeleteModal', _ctx.input))),
                  class: "cursor-pointer w-full h-full",
                  src: _imports_0,
                  alt: ""
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('openArchiveModal', _ctx.input, true))),
                  class: "cursor-pointer w-full h-full",
                  src: _imports_1,
                  alt: ""
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('openArchiveModal', _ctx.input, false))),
                  class: "cursor-pointer w-full h-full",
                  src: _imports_2,
                  alt: ""
                })
              ])
            ]))
      ])
    ]),
    (
        _ctx.input &&
        (_ctx.input.exampleImage ||
          (_ctx.input.description && (_ctx.input.description.de || _ctx.input.description.en)))
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_ctx.input.exampleImage)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "w-full h-full object-cover rounded-primary-radius",
                  src: _ctx.input.exampleImage,
                  alt: "Image"
                }, null, 8, _hoisted_12))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (onLanguageSwitchHandler('EN'))),
                class: _normalizeClass(["flex items-center gap-1 px-2 py-1 rounded-primary-radius cursor-pointer", 
              selectedLanguage.value === 'EN' ? 'bg-[#CBDCE9]' : 'bg-transparent'
            ])
              }, [
                _createElementVNode("img", {
                  src: 
                selectedLanguage.value === 'EN'
                  ? require('@/assets/dark-earth-icon.svg')
                  : require('@/assets/light-earth-icon.svg')
              
                }, null, 8, _hoisted_15),
                _createElementVNode("p", {
                  class: _normalizeClass(["text-sm font-normal text-primary-text-color", 
                selectedLanguage.value === 'EN'
                  ? 'text-primary-text-color'
                  : 'text-[#898790]'
              ])
                }, " EN ", 2)
              ], 2),
              _createElementVNode("div", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (onLanguageSwitchHandler('DE'))),
                class: _normalizeClass(["flex items-center px-2 py-1 gap-1 rounded-primary-radius cursor-pointer", 
              selectedLanguage.value === 'DE' ? 'bg-[#CBDCE9]' : 'bg-transparent'
            ])
              }, [
                _createElementVNode("img", {
                  src: 
                selectedLanguage.value === 'DE'
                  ? require('@/assets/dark-earth-icon.svg')
                  : require('@/assets/light-earth-icon.svg')
              ,
                  alt: "Earth icon"
                }, null, 8, _hoisted_16),
                _createElementVNode("p", {
                  class: _normalizeClass(["text-sm font-normal", 
                selectedLanguage.value === 'DE'
                  ? 'text-primary-text-color'
                  : 'text-[#898790]'
              ])
                }, " DE ", 2)
              ], 2)
            ]),
            _createElementVNode("div", _hoisted_17, [
              (selectedLanguage.value === 'EN')
                ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.input ? _ctx.input.description?.en : ""), 1))
                : _createCommentVNode("", true),
              (selectedLanguage.value === 'DE')
                ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.input ? _ctx.input.description?.de : ""), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})