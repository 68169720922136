import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-center justify-center" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-center justify-center" }
const _hoisted_5 = { class: "flex items-start gap-[85px]" }
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = { class: "w-full" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col h-[calc(100vh-130px)] max-w-[916px] overflow-y-auto"
}
const _hoisted_9 = { class: "text-primary-text-color font-semibold text-[24px] leading-[30px] tracking-[0.01rem]" }
const _hoisted_10 = {
  key: 0,
  class: "w-full grid grid-cols-3 gap-2 items-stretch mt-3"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "text-lg leading-8 tracking-[0.01rem] mt-3 text-[#A7A5AE] font-semibold" }
const _hoisted_13 = {
  key: 0,
  class: "w-full grid grid-cols-3 gap-2 items-stretch mt-3"
}

import MainLayout from "@/layouts/MainLayout.vue";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { onMounted, ref, computed } from "vue";
import client from "@/utils/axios";
import ExampleArchiveCard from "@/components/Card/ExampleArchiveCard.vue";
import ExampleArchiveModal from "@/components/Card/example-archive/ExampleArchiveModal.vue";
import DeleteModal from "@/components/Card/example-archive/DeleteModal.vue";
import { errorToast, successToast } from "@/utils/custom-toast";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExampleArchiveView',
  setup(__props) {

const exampleArchive = ref([]);
const selectedStepId = ref<number>();
const selectedPhaseId = ref<number>();
const selectedInput = ref<any>();
const isDeleteModalOpen = ref(false);
const isArchiveModalOpen = ref(false);
const isArchiveModalUpdate = ref(false);
const isLoading = ref(false);
// Example archive fetch
const getExampleArchive = async () => {
  const response = await client.get("/input-examples");
  exampleArchive.value = response.data;
};
onMounted(() => {
  getExampleArchive();
});

const step = computed(() => {
  if (exampleArchive.value) {
    let phaseWithSteps: any = exampleArchive.value.find(
      (phase: any) => phase.id === selectedPhaseId.value
    );
    let step = phaseWithSteps?.steps.find(
      (step: any) => step.id === selectedStepId.value
    );

    return step;
  }
  return {};
});

const openDeleteModal = (input: any) => {
  selectedInput.value = input;
  isDeleteModalOpen.value = true;
};
const openArchiveModal = (input: any) => {
  selectedInput.value = input;
  isArchiveModalOpen.value = true;
};

const currentStep = computed(() => {
  let currentPhase: any = exampleArchive.value.find(
    (item: any) => item.id === selectedPhaseId.value
  );

  let currentStep = currentPhase?.steps.find(
    (item: any) => item.id === selectedStepId.value
  );
  return currentStep;
});

const onUpdateExample = async (payload: any, onFinish: any) => {
  const formData = new FormData();

  formData.append(`files[0]`, payload.file);
  formData.append("inputId", selectedInput.value.id + "");
  formData.append("stepId", selectedStepId.value + "");
  formData.append("descriptionEn", payload.descriptionEn);
  formData.append("descriptionDe", payload.descriptionDe);

  try {
    let resp: any = await client.post(`/input-examples`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await onFinish();
    isLoading.value = false;
    successToast(
      resp && resp.message ? resp.message : "Succesfully added description."
    );
    getExampleArchive();
    isArchiveModalOpen.value = false;
  } catch (error: any) {
    console.log(error);
    errorToast(error);
  }
};

const onDeleteExample = async () => {
  isLoading.value = true;
  try {
    let resp: any = await client.delete(
      `/input-examples/${selectedInput.value.id}`
    );
    isLoading.value = false;
    successToast(
      resp && resp.message ? resp.message : "Succesfully removed description."
    );
    getExampleArchive();
    isLoading.value = false;
    isDeleteModalOpen.value = false;
  } catch (error: any) {
    console.log(error);
    errorToast(error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isArchiveModalOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(ExampleArchiveModal, {
              "selected-input": selectedInput.value,
              "is-loading": isLoading.value,
              "step-name": currentStep.value ? currentStep.value.name : '',
              "is-update": isArchiveModalUpdate.value,
              onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (isArchiveModalOpen.value = false)),
              onOnUpdate: onUpdateExample,
              onSetIsLoading: _cache[1] || (_cache[1] = ($event: any) => (isLoading.value = true))
            }, null, 8, ["selected-input", "is-loading", "step-name", "is-update"])
          ])
        ]))
      : _createCommentVNode("", true),
    (isDeleteModalOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(DeleteModal, {
              "is-loading": isLoading.value,
              onOnDelete: onDeleteExample,
              onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (isDeleteModalOpen.value = false))
            }, null, 8, ["is-loading"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(MainLayout, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createVNode(MainSidebar, {
              "is-example-archive-view": true,
              "example-archive": exampleArchive.value,
              "selected-step-id": selectedStepId.value,
              "selected-phase": selectedPhaseId.value,
              onOnPhaseSelect: _cache[3] || (_cache[3] = (phaseId) => (selectedPhaseId.value = phaseId)),
              onSelectStep: _cache[4] || (_cache[4] = (stepId) => (selectedStepId.value = stepId))
            }, null, 8, ["example-archive", "selected-step-id", "selected-phase"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-[20px] mb-[32px] leading-8 tracking-[0.01rem] text-[#A7A5AE] font-semibold h-[44px]" }, " Example image archive ", -1)),
              (step.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("h2", _hoisted_9, _toDisplayString(step.value ? step.value.name : ""), 1),
                    (step.value.inputs)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.value.inputs, (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "h-full w-fit"
                            }, [
                              _createVNode(ExampleArchiveCard, {
                                onOpenDeleteModal: openDeleteModal,
                                onOpenArchiveModal: openArchiveModal,
                                onCloseDeleteModal: _cache[5] || (_cache[5] = 
                    () => {
                      selectedInput.value = null;
                      isDeleteModalOpen.value = false;
                    }
                  ),
                                onCloseArchiveModal: _cache[6] || (_cache[6] = 
                    () => {
                      selectedInput.value = null;
                      isArchiveModalOpen.value = false;
                    }
                  ),
                                input: input
                              }, null, 8, ["input"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (step.value.groupInputs)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.value.groupInputs, (groupInput) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: groupInput.id,
                              class: "flex flex-col"
                            }, [
                              _createElementVNode("h4", _hoisted_12, _toDisplayString(groupInput.name), 1),
                              (groupInput.inputs)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupInput.inputs, (childInput) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: childInput.id,
                                        class: "h-full"
                                      }, [
                                        _createVNode(ExampleArchiveCard, {
                                          onOpenDeleteModal: openDeleteModal,
                                          onOpenArchiveModal: openArchiveModal,
                                          onCloseDeleteModal: _cache[7] || (_cache[7] = 
                        () => {
                          selectedInput.value = null;
                          isDeleteModalOpen.value = false;
                        }
                      ),
                                          onCloseArchiveModal: _cache[8] || (_cache[8] = 
                        () => {
                          selectedInput.value = null;
                          isArchiveModalOpen.value = false;
                        }
                      ),
                                          input: childInput
                                        }, null, 8, ["input"])
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})