<script setup lang="ts">
import { defineEmits, defineProps } from "vue";
type Emits = {
  (event: "closeModal"): void;
  (event: "onDelete"): void;
};

type Props = {
  isLoading: boolean;
};

const props = defineProps<Props>();

const emits = defineEmits<Emits>();
</script>

<template>
  <div
    class="w-[628px] p-5 mx-3 bg-white rounded-primary-radius flex flex-col items-start"
  >
    <div class="w-full flex items-center justify-between pb-[18px]">
      <h3
        class="text-primary-text-color text-xl leading-8 tracking-[0.01rem] font-semibold"
      >
        Delete section
      </h3>
      <img
        @click="emits('closeModal')"
        class="cursor-pointer h-[16px] w-[16px]"
        src="@/assets/close-icon-2.svg"
        alt=""
      />
    </div>
    <p
      class="text-xl leading-8 tracking-[0.01rem] text-primary-text-color font-normal pb-2"
    >
      Are you sure you want to delete this content?<br />
      This cannot be undone.
    </p>
    <div class="w-full flex flex-col gap-4 pt-3 border-t border-[#e3e1ea]">
      <div class="self-end flex items-center gap-3">
        <button
          @click="emits('closeModal')"
          class="text-base border-2 border-primary-text-color leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-[10px] rounded-primary-radius"
        >
          Cancel
        </button>
        <button
          :disabled="isLoading"
          @click="$emit('onDelete')"
          class="flex min-w-6 items-center gap-2 text-base border-2 border-transparent leading-5 tracking-[0.01rem] bg-[#F4B6B2] text-primary-text-color font-semibold p-[10px] rounded-primary-radius"
        >
          <div
            v-if="isLoading"
            class="w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
          ></div>
          <div v-else class="flex">
            <img
              class="w-[18px] h-[18px]"
              src="@/assets/example-archive-trash-icon.svg"
              alt=""
            />
            <p>Delete content</p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
