import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "max-w-[934px] mx-auto h-full" }
const _hoisted_2 = { class: "flex flex-col items-start sm:flex-row sm:items-center justify-between" }
const _hoisted_3 = { class: "flex items-center gap-2 pb-[9px]" }
const _hoisted_4 = { class: "text-xl leading-[26px] font-medium text-primary-text-color tracking-[0.01rem]" }
const _hoisted_5 = {
  key: 1,
  class: "text-xl leading-[26px] font-medium text-primary-text-color tracking-[0.01rem]"
}
const _hoisted_6 = {
  key: 1,
  class: "text-sm tracking-[0.01rem] text-primary-text-color px-[6px] py-[2px] bg-[#E3E1EA] rounded-primary-radius"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "h-full overflow-y-scroll" }
const _hoisted_10 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-3 mb-5" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

import { Project } from "@/types";
import BasicDetails from "./BasicDetails.vue";
import ProjectDocuments from "./ProjectDocuments.vue";
import TerminTimeline from "./TerminTimeline.vue";
import ProjectAppointments from "./ProjectAppointments.vue";
import ThreeBlockRow from "./ThreeBlockRow.vue";

import { textTransform } from "@/utils/parse-data";
import { ref } from "vue";
import InlineLoader from "@/components/Loaders/InlineLoader.vue";

type Props = {
  projectDetails: Project | null;
  isLoading: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectOverview',
  props: {
    projectDetails: {},
    isLoading: { type: Boolean }
  },
  emits: ["callgetProjectDetails"],
  setup(__props: any, { emit: __emit }) {

const isTimeline = ref(true);

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(InlineLoader, { key: 0 }))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.projectDetails?.name ? _ctx.projectDetails?.name : ""), 1))
        ]),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(InlineLoader, { key: 0 }))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.projectDetails ? _unref(textTransform)(_ctx.projectDetails?.status) : ""), 1))
      ]),
      (_ctx.projectDetails?.customerLogo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _ctx.projectDetails?.customerLogo,
              alt: "Customer logo",
              width: "84"
            }, null, 8, _hoisted_8)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", null, [
        _createVNode(ThreeBlockRow, {
          "is-loading": _ctx.isLoading,
          "customer-name": _ctx.projectDetails ? _ctx.projectDetails?.name : '',
          "cost-carrier-code": 
            _ctx.projectDetails ? _ctx.projectDetails?.costCarrierCode + '' : ''
          ,
          meister: _ctx.projectDetails ? _ctx.projectDetails?.meister : '',
          "next-termin": _ctx.projectDetails ? _ctx.projectDetails.nextTermin : '',
          "last-termin": _ctx.projectDetails ? _ctx.projectDetails.lastTermin : ''
        }, null, 8, ["is-loading", "customer-name", "cost-carrier-code", "meister", "next-termin", "last-termin"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(BasicDetails, {
          "is-loading": _ctx.isLoading,
          vnb: _ctx.projectDetails?.vnb,
          wechselrichter: _ctx.projectDetails?.wechselrichter,
          speicher: _ctx.projectDetails?.speicher,
          masskonzept: _ctx.projectDetails?.masskonzept,
          wb: _ctx.projectDetails?.wb,
          "number-of-modules": _ctx.projectDetails?.numberOfModules,
          "modul-type": _ctx.projectDetails?.modulType,
          "ibn-date": _ctx.projectDetails?.ibnDate,
          "home-owner-name": _ctx.projectDetails?.homeownerName,
          "home-owner-address": _ctx.projectDetails?.homeownerAddress,
          "home-owner-phone": _ctx.projectDetails?.homeownerPhone,
          "email-address": _ctx.projectDetails?.ownerEmail
        }, null, 8, ["is-loading", "vnb", "wechselrichter", "speicher", "masskonzept", "wb", "number-of-modules", "modul-type", "ibn-date", "home-owner-name", "home-owner-address", "home-owner-phone", "email-address"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", null, [
          (isTimeline.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(TerminTimeline, {
                  "is-loading": _ctx.isLoading,
                  projectDetails: _ctx.projectDetails,
                  onShowAppointments: _cache[0] || (_cache[0] = ($event: any) => (isTimeline.value = false))
                }, null, 8, ["is-loading", "projectDetails"])
              ]))
            : _createCommentVNode("", true),
          (!isTimeline.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(ProjectAppointments, {
                  projectDetails: _ctx.projectDetails,
                  onShowTimeline: _cache[1] || (_cache[1] = ($event: any) => (isTimeline.value = true))
                }, null, 8, ["projectDetails"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(ProjectDocuments, {
            "is-loading": _ctx.isLoading,
            projectDetails: _ctx.projectDetails,
            onCallgetProjectDetails: _cache[2] || (_cache[2] = ($event: any) => (emit('callgetProjectDetails')))
          }, null, 8, ["is-loading", "projectDetails"])
        ])
      ])
    ])
  ]))
}
}

})