<script setup lang="ts">
import { defineEmits, defineProps, ref } from "vue";

type Props = {
  selectedInput: any;
  stepName: string;
  parentInputName?: string;
  isUpdate: boolean;
  isLoading: boolean;
};

type Emits = {
  (event: "closeModal"): void;
  (event: "setIsLoading"): void;
  (event: "onUpdate", payload: any, onFinish: any): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const englishInput = ref(
  props.selectedInput ? props.selectedInput.description.en : ""
);
const germanInput = ref(
  props.selectedInput ? props.selectedInput.description.de : ""
);
const englishInputError = ref("");
const germanInputError = ref("");
const imageInputError = ref("");
const files = ref([]);
const imagePreviews = ref<string[]>([]);
let formErrors: Array<string> = [];

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
  imagePreviews.value = files.value.map((file: any) =>
    URL.createObjectURL(file)
  );
};

const validate = () => {
  if (germanInput.value && germanInput.value.trim().length > 0) {
    if (germanInput.value.length > 130) {
      germanInputError.value = "Max 130 characters";
      formErrors.push("germanInput");
    } else {
      formErrors = formErrors.filter((item: string) => item !== "germanInput");
      germanInputError.value = "";
    }
  } else {
    germanInputError.value = "Description required";
    formErrors.push("germanInput");
  }

  if (englishInput.value && englishInput.value.trim().length > 0) {
    if (englishInput.value.trim().length > 130) {
      englishInputError.value = "Max 130 characters";
      formErrors.push("englishInput");
    } else {
      formErrors = formErrors.filter((item: string) => item !== "englishInput");
      englishInputError.value = "";
    }
  } else {
    englishInputError.value = "Description required";
    formErrors.push("englishInput");
  }

  if (props.selectedInput.exampleImage) {
    formErrors = formErrors.filter((item: string) => item !== "imageInput");
    imageInputError.value = "";
  } else {
    if (files.value.length === 0) {
      imageInputError.value = "Image required";
      formErrors.push("imageInput");
    } else {
      imageInputError.value = "";
      formErrors = formErrors.filter((item: string) => item !== "imageInput");
    }
  }

  return formErrors.length === 0;
};

const onSubmit = () => {
  const isFormValid = validate();
  if (isFormValid) {
    let payload: any = {
      file: files.value ? files.value[0] : null,
      descriptionEn: englishInput.value,
      descriptionDe: germanInput.value,
    };

    emits("setIsLoading");
    emits("onUpdate", payload, clearForm);
  }
};

const clearForm = () => {
  englishInput.value = "";
  germanInput.value = "";
  files.value = [];
  imagePreviews.value = [];
};
</script>

<template>
  <div
    class="w-[628px] p-5 mx-3 bg-white rounded-primary-radius flex flex-col items-start max-h-[90vh]"
  >
    <div
      class="w-full h-[85px] flex items-start justify-between pb-[16px] mb-[16px] border-b border-[#e3e1ea]"
    >
      <div class="flex flex-col items-start">
        <h3
          class="text-primary-text-color text-xl leading-8 tracking-[0.01rem] font-semibold"
        >
          {{ stepName ? stepName : "" }}
        </h3>
        <h4
          class="text-[#898790] text-base leading-5 tracking-[0.01rem] font-normal"
        >
          {{ parentInputName ? parentInputName + "/" : "" }}
          {{ selectedInput ? selectedInput.name : "" }}
        </h4>
      </div>
      <img
        @click="
          () => {
            clearForm();
            emits('closeModal');
          }
        "
        class="cursor-pointer h-[16px] w-[16px]"
        src="@/assets/close-icon-2.svg"
        alt=""
      />
    </div>
    <!-- Content -->
    <div class="flex w-full flex-col overflow-y-auto">
      <!-- Inputs -->
      <div class="w-full">
        <!-- INput -->
        <div class="w-full">
          <div class="w-full flex items-center justify-between mb-[6px]">
            <p
              class="text-[#898790] text-base leading-[26px] tracking-[0.01rem] font-medium"
            >
              Describe how to take a good image
            </p>
            <div class="flex items-center gap-1">
              <img src="@/assets/light-earth-icon.svg" alt="Earth icon" />
              <h4 class="text-sm font-normal text-primary-text-color">EN</h4>
            </div>
          </div>
          <textarea
            rows="4"
            v-model="englishInput"
            class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color"
            type="text"
            :class="`${englishInputError.length > 0 ? 'border-[#ed857f]' : ''}`"
          />
          <div class="flex gap-1 items-center" v-if="englishInputError">
            <img src="@/assets/warning-red.svg" alt="Warning" />
            <span class="text-[#ed857f] text-sm">{{ englishInputError }}</span>
          </div>
        </div>
        <!-- INput -->
        <div class="w-full mt-[24px]">
          <div class="w-full flex items-center justify-between mb-[6px]">
            <p
              class="text-[#898790] text-base leading-[26px] tracking-[0.01rem] font-medium"
            >
              Describe how to take a good image
            </p>
            <div class="flex items-center gap-1">
              <img src="@/assets/light-earth-icon.svg" alt="Earth icon" />
              <h4 class="text-sm font-normal text-primary-text-color">DE</h4>
            </div>
          </div>
          <textarea
            rows="4"
            v-model="germanInput"
            class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color"
            :class="`${germanInputError ? 'border-[#ed857f]' : ''}`"
            type="text"
          />
          <div class="flex gap-1 items-center" v-if="germanInputError">
            <img src="@/assets/warning-red.svg" alt="Warning" />
            <span class="text-[#ed857f] text-sm">{{ germanInputError }}</span>
          </div>
        </div>
      </div>
      <!-- Existing images -->
      <div
        class="mt-[24px] rounded-primary-radius"
        v-if="
          selectedInput &&
          selectedInput.exampleImage &&
          imagePreviews.length === 0
        "
      >
        <img
          class="w-[123px] h-[123px] rounded-primary-radius object-cover"
          :src="selectedInput ? selectedInput.exampleImage : ''"
        />
      </div>
      <div
        v-if="imagePreviews.length > 0"
        class="w-[123px] h-[123px] flex o gap-1 mt-6"
      >
        <img
          :src="imagePreviews[0]"
          alt="Uploaded image"
          class="shrink-0 object-cover w-[123px] h-[123px] rounded-md"
        />
      </div>
      <!-- Attach image -->
      <div class="w-full mt-[24px] mb-4">
        <div class="flex items-center gap-2 mb-2">
          <label
            for="file"
            class="px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
          >
            <img src="@/assets/attach-icon.svg" alt="Attach icon" />
            {{
              (selectedInput && selectedInput.exampleImage) ||
              imagePreviews.length > 0
                ? "Change example image"
                : "Attach example image"
            }}
          </label>
          <input
            multiple
            class="hidden"
            type="file"
            id="file"
            @change="handleFileChange"
          />
        </div>
        <div class="flex gap-1 items-center" v-if="imageInputError">
          <img src="@/assets/warning-red.svg" alt="Warning" />
          <span class="text-[#ed857f] text-sm">{{ imageInputError }}</span>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col gap-4 pt-3 border-t border-[#e3e1ea]">
      <div class="flex items-center justify-between">
        <button
          @click="clearForm"
          class="text-base leading-5 tracking-[0.01rem] text-[#898790] font-semibold p-[10px] rounded-primary-radius"
        >
          Clear
        </button>
        <button
          :disabled="isLoading"
          @click="onSubmit"
          class="ml-auto w-[150px] flex items-center justify-center gap-2 text-base border-2 border-transparent leading-5 tracking-[0.01rem] bg-primary-text-color text-white font-semibold p-[10px] rounded-primary-radius"
        >
          <div
            v-if="isLoading"
            class="w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
          ></div>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>
