import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/chevron-up.svg'


const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex gap-6 justify-end w-fit" }
const _hoisted_3 = { key: 0 }

import { ref } from "vue";

type Props = {
  stepId?: number;
  stepName: string;
  showDetails?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomAccordion',
  props: {
    stepId: {},
    stepName: {},
    showDetails: { type: Boolean }
  },
  setup(__props: any) {



const isOpen = ref(true);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full mt-9 h-[70px]", `${_ctx.stepId ? 'sticky z-[18] bg-[#EFEEEC]' : ''} ${
        _ctx.showDetails ? 'top-[308px]' : 'top-[119px]'
      }`])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center justify-between gap-2 p-3 mb-[12px] cursor-pointer rounded-primary-radius hover:bg-[#dfdedc] duration-500", isOpen.value ? 'bg-transparent' : 'bg-[#dfdedc]'])
      }, [
        _createElementVNode("h2", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value)),
          class: "w-full text-lg sm:text-2xl leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold"
        }, _toDisplayString(_ctx.stepName), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (isOpen.value = !isOpen.value)),
            class: _normalizeClass(isOpen.value ? 'rotate-0' : 'rotate-180'),
            src: _imports_0,
            alt: ""
          }, null, 2)
        ])
      ], 2)
    ], 2),
    (isOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})