<script setup lang="ts">
import SelectMenu from "@/components/Re-usable/SelectMenu.vue";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { defineProps, defineEmits, reactive, ref } from "vue";

type Props = {
  issue: any;
  options: Array<{ id: number; name: string }>;
  issueBlockTypes: Array<{ id: number; name: string }>;
  isOpen: boolean;
  justView: boolean;
  headerTitle: string;
  terminId?: string;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const selectedOption = ref<any | null>(
  props.issue
    ? props.options.find((item: any) => item.id === props.issue.typeId)
    : null
);

const selectedBlockTypeOption = ref<any | null>(
  props.issue
    ? props.issueBlockTypes.find(
        (item: any) => item.id === props.issue.blockTypeId
      )
    : null
);
const files = ref([]);
const isLoading = ref(false);

const state = reactive({
  timeLost: props.issue && props.issue.timeLost ? props.issue?.timeLost : "",
  description:
    props.issue && props.issue.description ? props.issue?.description : "",
});

const imagePreviews = ref<string[]>(
  props.issue && props.issue.attachments ? props.issue.attachments : []
);

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);

  imagePreviews.value = [
    ...imagePreviews.value,
    ...files.value.map((file: any) => URL.createObjectURL(file)),
  ];
};

const errors = reactive({
  selectedOption: "",
  selectedBlockTypeOption: "",
  description: "",
});

const validate = () => {
  let valid = true;
  errors.selectedOption = "";
  errors.selectedBlockTypeOption = "";
  errors.description = "";

  if (!selectedOption.value) {
    errors.selectedOption = "Please select issue type!";
    valid = false;
  }

  if (!state.description) {
    errors.description = "Please add description of the issue!";
    valid = false;
  }

  if (!selectedBlockTypeOption.value) {
    errors.selectedBlockTypeOption =
      "Please select if you were blocked by this issue!";
    valid = false;
  }

  return valid;
};

const onSubmit = async () => {
  if (!validate()) return;

  isLoading.value = true;

  const formData = new FormData();
  formData.append("timeLost", state.timeLost);
  formData.append("description", state.description);
  formData.append("typeId", selectedOption.value.id);
  formData.append("blockTypeId", selectedBlockTypeOption.value.id);

  if (files.value) {
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }
  try {
    await client.post(`/termin/${props.terminId}/issue`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    isLoading.value = false;
    successToast("Successfully added issue.");
    emits("refresh");
    emits("toggleModal");
  } catch (error: any) {
    isLoading.value = false;
    errorToast(error);
  }
};

const onEdit = async () => {
  if (!validate()) return;

  isLoading.value = true;

  const formData = new FormData();
  formData.append("timeLost", state.timeLost);
  formData.append("description", state.description);
  formData.append("typeId", selectedOption.value.id);
  formData.append("blockTypeId", selectedBlockTypeOption.value.id);

  if (files.value) {
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }

  await client.put(
    `/termin/${props.terminId}/issue/${props.issue.id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  isLoading.value = false;
  successToast("Successfully updated issue.");
  emits("refresh");
  emits("toggleModal");
};
</script>

<template>
  <div
    class="fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out"
    :class="`${isOpen ? 'flex' : 'hidden'}`"
  >
    <div
      class="absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md"
    >
      <div
        class="flex items-center justify-between border-b border-gray-300 pb-3"
      >
        <div class="flex items-center gap-[10px]">
          <div v-if="!justView">
            <img
              :class="state.description ? 'hidden' : 'block'"
              src="@/assets/edit-plus-icon.svg"
              alt="Icon"
            />
            <img
              :class="state.description ? 'block' : 'hidden'"
              src="@/assets/pencil-icon.svg"
              alt="Icon"
            />
          </div>

          <h4
            class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
          >
            {{ justView ? issue?.title : headerTitle }}
          </h4>
        </div>
        <div class="flex gap-4">
          <span class="text">
            {{ issue ? issue.terminName : "" }}
          </span>
          <button @click="emits('toggleModal')">
            <img src="@/assets/close-icon-2.svg" alt="Close icon" />
          </button>
        </div>
      </div>
      <!-- Form -->
      <form @submit.prevent="state.description ? onSubmit() : onEdit()">
        <div class="flex flex-col gap-6">
          <div class="flex flex-col gap-2">
            <h4
              class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
            >
              Choose an issue type
            </h4>
            <SelectMenu
              :is-error="!!errors.selectedOption"
              @update:error="errors.selectedOption = $event"
              :disabled="justView"
              :options="options"
              v-model="selectedOption"
            />
            <p
              v-if="errors.selectedOption"
              class="text-sm text-red-400 font-medium"
            >
              {{ errors.selectedOption }}
            </p>
          </div>
          <div class="flex flex-col gap-2">
            <h4
              class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium"
            >
              Description
            </h4>
            <textarea
              :disabled="justView"
              v-model="state.description"
              @input="errors.description = ''"
              rows="7"
              class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border rounded-[5px] resize-none placeholder:text-primary-text-color"
              :class="
                errors.description ? 'border-red-500' : 'border-[#E3E1EA]'
              "
            ></textarea>
            <p
              v-if="errors.description"
              class="text-sm text-red-400 font-medium"
            >
              {{ errors.description }}
            </p>
          </div>
          <div class="flex flex-col gap-2">
            <h4
              class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium"
            >
              Are you blocked?
            </h4>
            <SelectMenu
              :is-error="!!errors.selectedBlockTypeOption"
              :disabled="justView"
              :options="issueBlockTypes"
              v-model="selectedBlockTypeOption"
              @update:error="errors.selectedBlockTypeOption = $event"
            />
            <p
              v-if="errors.selectedBlockTypeOption"
              class="text-sm text-red-400 font-medium"
            >
              {{ errors.selectedBlockTypeOption }}
            </p>
          </div>
          <div class="flex flex-col gap-2">
            <h4
              class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium"
            >
              Approx. time lost due to issue
            </h4>
            <input
              type="text"
              :disabled="justView"
              v-model="state.timeLost"
              class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color"
            />
          </div>
        </div>
        <!-- Existing attachments -->
        <div
          v-if="imagePreviews && imagePreviews.length"
          class="flex w-full overflow-x-auto gap-2 mt-6 whitespace-nowrap scrollable-container"
        >
          <div
            v-for="(src, index) in imagePreviews"
            :key="index"
            class="shrink-0 w-[123px] h-[123px]"
          >
            <img
              :src="src"
              alt="Uploaded image"
              class="shrink-0 object-cover w-[123px] h-[123px] rounded-md"
            />
          </div>
        </div>
        <div class="flex items-center gap-2 mt-[36px]">
          <label
            for="file"
            class="px-4 py-2 text-[#393840] font-medium rounded-md transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
            :class="`${justView ? '' : 'cursor-pointer hover:bg-[#F9F8F6]'}`"
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108"
                stroke="#393840"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Attach Files
          </label>
          <input
            :disabled="justView"
            multiple
            class="hidden"
            type="file"
            id="file"
            @change="handleFileChange"
          />
        </div>
        <!-- Submit button -->
        <div v-if="!justView" class="flex justify-end">
          <div
            @click="issue ? onEdit() : onSubmit()"
            class="mt-5 w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] duration-200"
            :class="`${justView ? '' : 'cursor-pointer hover:bg-[#28272c]'}`"
          >
            <div
              v-if="isLoading"
              class="w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
            ></div>
            <span v-else>Save</span>
          </div>
        </div>
        <div v-if="justView" class="flex justify-end">
          <div
            :disabled="justView"
            @click="$emit('toggleModal')"
            class="mt-5 w-[150px] flex items-center justify-center cursor-pointer hover:bg-[#28272c] py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] duration-200"
          >
            <span>Close</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
