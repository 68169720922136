import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/sun-icon.svg'
import _imports_1 from '@/assets/this-week-icon.svg'
import _imports_2 from '@/assets/file-icon.svg'
import _imports_3 from '@/assets/double-play.svg'
import _imports_4 from '@/assets/search-icon.svg'
import _imports_5 from '@/assets/chevron-down.svg'
import _imports_6 from '@/assets/close-icon-2.svg'
import _imports_7 from '@/assets/checkbox-checked.svg'
import _imports_8 from '@/assets/refresh-icon.svg'


const _hoisted_1 = { class: "flex items-start" }
const _hoisted_2 = { class: "w-full flex flex-col items-center" }
const _hoisted_3 = { class: "w-full flex flex-col gap-5 mb-6 lg:flex-row items-center justify-center lg:items-start lg:justify-between" }
const _hoisted_4 = { class: "flex flex-wrap items-center justify-center gap-3" }
const _hoisted_5 = { class: "w-[122px] flex items-center gap-2 px-2 bg-white rounded-lg" }
const _hoisted_6 = { class: "flex items-center gap-[30px] relative" }
const _hoisted_7 = { class: "custom-select" }
const _hoisted_8 = {
  key: 0,
  class: "w-full h-fit relative top-0 right-0 bottom-0 left-0 z-[777] bg-black/25 max-h-[100vh]"
}
const _hoisted_9 = { class: "w-[307px] max-h-[calc(100vh-100px)] absolute flex flex-col gap-3 top-0 left-[-90px] z-50 min-w-[170px] bg-white p-4 rounded-lg shadow-sm" }
const _hoisted_10 = { class: "flex flex-col gap-1 overflow-y-auto" }
const _hoisted_11 = ["onUpdate:modelValue"]
const _hoisted_12 = {
  key: 0,
  src: _imports_7,
  alt: ""
}
const _hoisted_13 = {
  key: 0,
  class: "mt-28"
}
const _hoisted_14 = {
  key: 1,
  class: "h-[calc(100vh-125px)] overflow-y-auto self-center"
}

import "/custom.css";
import MainLayout from "@/layouts/MainLayout.vue";
import MainContent from "@/components/MainPage/MainContent.vue";
import { computed, ref, watch, onMounted } from "vue";
import client from "@/utils/axios";
import { textTransform } from "@/utils/parse-data";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { Status } from "@/types";
import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";
import { useUserStore } from "@/stores/user";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const isSelectedMenu = ref(false);
const searchQuery = ref<string>("");
const isLoading = ref(false);
const userStore = useUserStore();

const isSelectMenuHandler = () => {
  isSelectedMenu.value = !isSelectedMenu.value;
};

// Fetch data
const allStatuses = ref<Status[]>([]);
const aLLProjects = ref<any>([]);
const activeFilter = ref<any>();
const selectedStatuses = ref<Status[]>([]);

const getAllStatuses = async () => {
  try {
    const response = await client.get("/project-statuses");
    if (response.data) {
      let statuses = response.data.map((item: any) => {
        return {
          ...item,
          isSelected: true,
          isOpen: true,
        };
      });

      const userFilters = userStore.statusFilters;
      if (userFilters.length) {
        statuses = statuses.map((status: any) => {
          if (userFilters.includes(status.name)) {
            status.isSelected = true;
          } else {
            status.isSelected = false;
          }

          return status;
        });
      }

      allStatuses.value = statuses;
      selectedStatuses.value = statuses.filter(
        (status: any) => status.isSelected
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const getActiveFilter = async () => {
  try {
    if (userStore.filter) {
      activeFilter.value = userStore.filter;
    }
  } catch (error) {
    console.log(error);
  }
};

const getActiveSearchFilter = async () => {
  try {
    if (userStore.searchFilter) {
      searchQuery.value = userStore.searchFilter;
    }
  } catch (error) {
    console.log(error);
  }
};

const getAllProjects = async () => {
  isLoading.value = true;

  try {
    const response = await client.get("/projects");
    if (response.data) {
      aLLProjects.value = response.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const toggleStatusOpen = (statusId: number) => {
  const status = allStatuses.value.find(
    (status: any) => status.id === statusId
  );

  if (status) {
    status.isOpen = !status.isOpen;
  }
};

onMounted(() => {
  getAllStatuses();
  getAllProjects();
  getActiveFilter();
  getActiveSearchFilter();
});

const filteredProjects = computed(() => {
  let results = aLLProjects.value;
  switch (activeFilter.value) {
    case "today":
      {
        results = results.filter((project: any) => {
          const currentDateFull = new Date();
          const currentDate = new Date(
            currentDateFull.getFullYear(),
            currentDateFull.getMonth(),
            currentDateFull.getDate()
          );
          let match = false;

          project.termins?.forEach((termin: any) => {
            let start = null;
            let end = null;

            if (termin.start) {
              start = new Date(termin.start);
              start?.setHours(0, 0, 0, 0);
            }
            if (termin.end) {
              end = new Date(termin.end);
              end?.setHours(0, 0, 0, 0);
            }

            const projectStartToday = start
              ? start >= currentDate && start <= currentDate
              : false;

            const projectEndToday = end
              ? end >= currentDate && end <= currentDate
              : false;

            const projectInProgress =
              start && end ? start < currentDate && end > currentDate : false;

            if (projectStartToday || projectEndToday || projectInProgress) {
              match = true;
            }
          });

          return match;
        });
      }
      break;
    case "week":
      results = results.filter((project: any) => {
        const currentDateFull = new Date();
        const currentDate = new Date(
          currentDateFull.getFullYear(),
          currentDateFull.getMonth(),
          currentDateFull.getDate()
        );
        let match = false;

        project.termins?.forEach((termin: any) => {
          let start = null;
          let end = null;

          if (termin.start) {
            start = new Date(termin.start);
            start?.setHours(0, 0, 0, 0);
          }
          if (termin.end) {
            end = new Date(termin.end);
            end?.setHours(0, 0, 0, 0);
          }

          const day = currentDate.getDay();
          const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
          const monday = new Date(currentDate.setDate(diff));
          const sunday = new Date(currentDate.setDate(diff + 6));

          const projectStartThisWeek = start
            ? start >= monday && start <= sunday
            : false;

          const projectEndThisWeek = end
            ? end >= monday && end <= sunday
            : false;

          const projectInProgress =
            start && end ? start < currentDate && end > currentDate : false;

          if (projectStartThisWeek || projectEndThisWeek || projectInProgress) {
            match = true;
          }
        });

        return match;
      });
      break;
    case "abnahme":
      results = results.filter((project: any) => {
        return (
          project.phases?.filter((item: any) => item.status.name === "abnahme")
            .length > 0
        );
      });
      break;
    case "qc":
      results = results.filter((project: any) => {
        return (
          project.phases?.filter((item: any) => item.status.name === "qc")
            .length > 0
        );
      });
      break;
  }

  if (searchQuery.value) {
    results = results.filter((query: any) => {
      return query.name.toLowerCase().includes(searchQuery.value.toLowerCase());
    });
  }

  return results;
});

const setActiveFilter = (filter: string) => {
  if (activeFilter.value === filter) {
    activeFilter.value = null;
  } else {
    activeFilter.value = filter;
  }
  onSaveSelectedStatuses();
};

const handleSaveClick = () => {
  onSaveSelectedStatuses();
  isSelectMenuHandler();
};

const onSaveSelectedStatuses = () => {
  selectedStatuses.value = allStatuses.value.filter(
    (status: any) => status.isSelected
  );
  // make API request to save selected statuses

  const activeFilterNames = selectedStatuses.value.map(
    (status: any) => status.name
  );

  const activeFilterName = activeFilter.value;

  const activeSearchQuery = searchQuery.value;

  client.post(`/user/save-filters`, {
    statusFilters: activeFilterNames,
    filter: activeFilterName,
    searchFilter: activeSearchQuery,
  });

  userStore.setStatusFilters(activeFilterNames);
  userStore.setFilter(activeFilterName);
  userStore.setSearchFilter(activeSearchQuery);
};

const onResetSelectedStatuses = () => {
  allStatuses.value.forEach((status: any) => {
    status.isSelected = true;
  });

  selectedStatuses.value = [...allStatuses.value];
};

// Debaunce Timer
let debounceTimer: any;
watch(searchQuery, () => {
  clearTimeout(debounceTimer); // Clear any existing timer
  debounceTimer = setTimeout(() => {
    onSaveSelectedStatuses(); // Call function
  }, 1500);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(MainSidebar, { "is-home-view": true })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "hidden sm:block" }, null, -1)),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (setActiveFilter('today'))),
                  id: "today",
                  class: _normalizeClass(["flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300", 
                  activeFilter.value === 'today' ? 'bg-white' : 'bg-transparent'
                ])
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Sun icon"
                  }, null, -1),
                  _createElementVNode("p", { class: "text-sm font-normal text-primary-text-color" }, "Today", -1)
                ]), 2),
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (setActiveFilter('week'))),
                  id: "week",
                  class: _normalizeClass(["flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300", activeFilter.value === 'week' ? 'bg-white' : 'bg-transparent'])
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("img", {
                    src: _imports_1,
                    alt: "Sun icon"
                  }, null, -1),
                  _createElementVNode("p", { class: "text-sm font-normal text-primary-text-color" }, " This week ", -1)
                ]), 2),
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (setActiveFilter('abnahme'))),
                  id: "abnahme",
                  class: _normalizeClass(["flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300", 
                  activeFilter.value === 'abnahme' ? 'bg-white' : 'bg-transparent'
                ])
                }, _cache[7] || (_cache[7] = [
                  _createElementVNode("img", {
                    src: _imports_2,
                    alt: "Sun icon"
                  }, null, -1),
                  _createElementVNode("p", { class: "text-sm font-normal text-primary-text-color" }, " Abnahme ready ", -1)
                ]), 2),
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (setActiveFilter('qc'))),
                  id: "qc",
                  class: _normalizeClass(["flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300", activeFilter.value === 'qc' ? 'bg-white' : 'bg-transparent'])
                }, _cache[8] || (_cache[8] = [
                  _createElementVNode("img", {
                    src: _imports_3,
                    alt: "Sun icon"
                  }, null, -1),
                  _createElementVNode("p", { class: "text-sm font-normal text-primary-text-color" }, " QC ready ", -1)
                ]), 2),
                _createElementVNode("form", _hoisted_5, [
                  _cache[9] || (_cache[9] = _createElementVNode("img", {
                    src: _imports_4,
                    alt: ""
                  }, null, -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((searchQuery).value = $event)),
                    class: "w-full h-9 text-sm text-normal bg-transparent focus:outline-none",
                    type: "text",
                    placeholder: "Search"
                  }, null, 512), [
                    [_vModelText, searchQuery.value]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  onClick: isSelectMenuHandler,
                  class: "selected"
                }, [
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-sm text-primary-text-color font-normal" }, " Filter status ", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: _normalizeClass(["duration-200", isSelectedMenu.value ? '-rotate-180' : 'rotate-0']),
                      src: _imports_5,
                      alt: ""
                    }, null, 2)
                  ])
                ]),
                (isSelectedMenu.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", { class: "flex items-start justify-between mb-1" }, [
                          _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "text-lg leading-[20px] text-primary-text-color tracking-[0.01rem] font-semibold" }, " Filter status ", -1)),
                          _createElementVNode("img", {
                            onClick: isSelectMenuHandler,
                            src: _imports_6,
                            alt: "Close"
                          })
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allStatuses.value, (column) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: column.id,
                              class: "select-items flex flex-col"
                            }, [
                              _createElementVNode("label", null, [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["w-full flex items-center gap-2 p-2 rounded-primary-radius text-sm text-primary-text-color font-medium cursor-pointer", 
                            column.isSelected
                              ? ' bg-[#DFDEDC]'
                              : 'bg-[#F9F8F6] '
                          ])
                                }, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "checkbox",
                                    "onUpdate:modelValue": ($event: any) => ((column.isSelected) = $event),
                                    class: _normalizeClass(column.isSelected ? 'hidden' : 'block')
                                  }, null, 10, _hoisted_11), [
                                    [_vModelCheckbox, column.isSelected]
                                  ]),
                                  (column.isSelected)
                                    ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(" " + _toDisplayString(_unref(textTransform)(column.name)), 1)
                                ], 2)
                              ])
                            ]))
                          }), 128))
                        ]),
                        _createElementVNode("div", { class: "flex items-center justify-between pt-3 border-t border-gray-200" }, [
                          _createElementVNode("button", {
                            onClick: onResetSelectedStatuses,
                            class: "text-base leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-2 rounded-primary-radius border-2 border-[#393840]"
                          }, " Reset "),
                          _createElementVNode("button", {
                            onClick: handleSaveClick,
                            class: "text-base leading-5 tracking-[0.01rem] text-white font-semibold p-2 rounded-primary-radius border-2 border-[#393840] bg-[#393840]"
                          }, " Save ")
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  onClick: getAllProjects,
                  class: "flex items-center gap-2 bg-transparent outline-none p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                }, [
                  _createElementVNode("img", {
                    src: _imports_8,
                    alt: "",
                    class: _normalizeClass(isLoading.value ? 'animate-spin' : 'animate-none')
                  }, null, 2),
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-sm text-primary-text-color font-normal" }, " Refresh ", -1))
                ])
              ])
            ])
          ]),
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(SpinnerLoader)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(MainContent, {
                  "status-list": selectedStatuses.value,
                  "project-list": filteredProjects.value,
                  onToggleStatusOpen: toggleStatusOpen
                }, null, 8, ["status-list", "project-list"])
              ]))
        ])
      ])
    ]),
    _: 1
  }))
}
}

})