import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/edit-plus-icon.svg'
import _imports_1 from '@/assets/pencil-icon.svg'
import _imports_2 from '@/assets/trash-icoon.svg'
import _imports_3 from '@/assets/no-image.svg'
import _imports_4 from '@/assets/calendar-icon.svg'
import _imports_5 from '@/assets/image-user-hover.svg'


const _hoisted_1 = { class: "w-full bg-[#E5E4E2] p-3 rounded-primary-radius overflow-x-hidden" }
const _hoisted_2 = { class: "text-lg sm:text-[20px] leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_3 = { class: "flex gap-3 overflow-x-auto scrollable-container" }
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = { class: "w-full flex items-center justify-between mb-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-end gap-3 w-2/12"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/55 flex items-center justify-center"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "flex items-center gap-3 mt-5"
}
const _hoisted_13 = { class: "flex gap-2 overflow-x-auto scrollable-container" }
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  key: 1,
  class: "h-[230px] flex flex-col items-center justify-center"
}
const _hoisted_16 = { class: "mt-3 flex gap-4" }
const _hoisted_17 = { class: "flex items-center gap-1" }
const _hoisted_18 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_19 = { class: "flex items-center gap-1" }
const _hoisted_20 = { key: 0 }

import DeletePopup from "./Modal/DeletePopup.vue";
import IssueModal from "./Modal/IssueModal.vue";
import { ref } from "vue";

type Props = {
  issues: any[];
  issueTypes: Array<{ id: number; name: string }>;
  issueBlockTypes: Array<{ id: number; name: string }>;
  termin: string;
  isIssuesFeatureFlagActive: boolean;
};

type Emits = {
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'IssuesBlock',
  props: {
    issues: {},
    issueTypes: {},
    issueBlockTypes: {},
    termin: {},
    isIssuesFeatureFlagActive: { type: Boolean }
  },
  emits: ["refresh"],
  setup(__props: any) {

const props = __props;



const isModal = ref(false);
const isPopup = ref(false);
const selectedIssue = ref<any | null>(null);
const issues = ref(props.issues);
const isJustViewIssueForm = ref();

// Issue modal
const toggleIssueModal = (isJustView: boolean, issue?: any) => {
  selectedIssue.value = issue ? issue : null;
  isJustViewIssueForm.value = isJustView;
  isModal.value = !isModal.value;
};

const togglePopup = (issue?: any) => {
  selectedIssue.value = issue ? issue : null;
  isPopup.value = !isPopup.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center justify-between", issues.value?.length > 0 ? 'pb-3' : 'pb-0'])
    }, [
      _createElementVNode("h2", _hoisted_2, " Issues (" + _toDisplayString(issues.value?.length ? issues.value?.length : "0") + ") ", 1),
      (!_ctx.isIssuesFeatureFlagActive)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleIssueModal(false, null))),
            class: "cursor-pointer",
            src: _imports_0,
            alt: "Edit"
          }))
        : _createCommentVNode("", true)
    ], 2),
    (isModal.value)
      ? (_openBlock(), _createBlock(IssueModal, {
          key: 0,
          issue: selectedIssue.value,
          "is-open": isModal.value,
          "just-view": isJustViewIssueForm.value,
          options: _ctx.issueTypes,
          "issue-block-types": _ctx.issueBlockTypes,
          "header-title": 
        selectedIssue.value ? 'Edit ' + selectedIssue.value.title : 'New Issue'
      ,
          "termin-id": _ctx.termin,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => {
        isModal.value = false;
        selectedIssue.value = null;
      }),
          onRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('refresh')))
        }, null, 8, ["issue", "is-open", "just-view", "options", "issue-block-types", "header-title", "termin-id"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(issues.value, (issue) => {
          return (_openBlock(), _createElementBlock("div", {
            key: issue.id,
            class: "w-[450px] shrink-0 h-full flex flex-col items-start justify-start p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", {
                onClick: ($event: any) => (toggleIssueModal(true, issue)),
                class: _normalizeClass(["cursor-pointer text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold", `${_ctx.isIssuesFeatureFlagActive ? 'w-full' : 'w-10/12'}`])
              }, _toDisplayString(issue.title), 11, _hoisted_6),
              (!_ctx.isIssuesFeatureFlagActive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("img", {
                      onClick: ($event: any) => (toggleIssueModal(false, issue)),
                      class: "cursor-pointer",
                      src: _imports_1,
                      alt: "Pencil"
                    }, null, 8, _hoisted_8),
                    _createElementVNode("img", {
                      onClick: ($event: any) => (togglePopup(issue)),
                      class: "cursor-pointer",
                      src: _imports_2,
                      alt: "Icon"
                    }, null, 8, _hoisted_9),
                    (isPopup.value === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(DeletePopup, {
                            "termin-id": _ctx.termin,
                            id: selectedIssue.value ? selectedIssue.value.id : null,
                            title: "issue",
                            "is-note": false,
                            onTogglePopup: togglePopup,
                            onRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('refresh')))
                          }, null, 8, ["termin-id", "id"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              onClick: ($event: any) => (toggleIssueModal(true, issue)),
              class: "w-full flex flex-col gap-3 cursor-pointer"
            }, [
              _createElementVNode("p", null, _toDisplayString(issue.description), 1),
              (issue.attachments && issue.attachments?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(issue.attachments, (image, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "flex-shrink-0"
                        }, [
                          _createElementVNode("img", {
                            class: "w-[200px] h-[230px] object-cover rounded-primary-radius",
                            src: image,
                            alt: "Termin image"
                          }, null, 8, _hoisted_14)
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: _imports_3,
                      alt: ""
                    }, null, -1),
                    _createElementVNode("p", { class: "text-sm text-[#898790] font-medium pt-2" }, "No images", -1)
                  ]))),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                    class: "w-3 h-3 cursor-pointer",
                    src: _imports_4,
                    alt: "Calendar"
                  }, null, -1)),
                  _createElementVNode("p", _hoisted_18, _toDisplayString(new Date(issue.createdAt).toLocaleDateString("DE")), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _cache[6] || (_cache[6] = _createElementVNode("img", {
                    class: "w-3 h-3",
                    src: _imports_5,
                    alt: "User"
                  }, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(issue.workers, (worker, index) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: index,
                      class: "text-sm text-[#898790] font-medium"
                    }, [
                      _createTextVNode(_toDisplayString(worker) + " ", 1),
                      (index < issue.workers.length - 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, ","))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ])
            ], 8, _hoisted_11)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})