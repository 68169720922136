import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/edit-plus-icon.svg'
import _imports_1 from '@/assets/pencil-icon.svg'
import _imports_2 from '@/assets/close-icon-2.svg'


const _hoisted_1 = { class: "absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md" }
const _hoisted_2 = { class: "flex items-center justify-between border-b border-gray-300 pb-3" }
const _hoisted_3 = { class: "flex items-center gap-[10px]" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold" }
const _hoisted_6 = { class: "flex gap-4" }
const _hoisted_7 = { class: "text" }
const _hoisted_8 = { class: "flex flex-col gap-6" }
const _hoisted_9 = { class: "flex flex-col gap-2" }
const _hoisted_10 = {
  key: 0,
  class: "text-sm text-red-400 font-medium"
}
const _hoisted_11 = { class: "flex flex-col gap-2" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = {
  key: 0,
  class: "text-sm text-red-400 font-medium"
}
const _hoisted_14 = { class: "flex flex-col gap-2" }
const _hoisted_15 = {
  key: 0,
  class: "text-sm text-red-400 font-medium"
}
const _hoisted_16 = { class: "flex flex-col gap-2" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 0,
  class: "flex w-full overflow-x-auto gap-2 mt-6 whitespace-nowrap scrollable-container"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "flex items-center gap-2 mt-[36px]" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = {
  key: 1,
  class: "flex justify-end"
}
const _hoisted_23 = {
  key: 0,
  class: "w-6 h-6 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_24 = { key: 1 }
const _hoisted_25 = {
  key: 2,
  class: "flex justify-end"
}
const _hoisted_26 = ["disabled"]

import SelectMenu from "@/components/Re-usable/SelectMenu.vue";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { reactive, ref } from "vue";

type Props = {
  issue: any;
  options: Array<{ id: number; name: string }>;
  issueBlockTypes: Array<{ id: number; name: string }>;
  isOpen: boolean;
  justView: boolean;
  headerTitle: string;
  terminId?: string;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'IssueModal',
  props: {
    issue: {},
    options: {},
    issueBlockTypes: {},
    isOpen: { type: Boolean },
    justView: { type: Boolean },
    headerTitle: {},
    terminId: {}
  },
  emits: ["toggleModal", "refresh"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const selectedOption = ref<any | null>(
  props.issue
    ? props.options.find((item: any) => item.id === props.issue.typeId)
    : null
);

const selectedBlockTypeOption = ref<any | null>(
  props.issue
    ? props.issueBlockTypes.find(
        (item: any) => item.id === props.issue.blockTypeId
      )
    : null
);
const files = ref([]);
const isLoading = ref(false);

const state = reactive({
  timeLost: props.issue && props.issue.timeLost ? props.issue?.timeLost : "",
  description:
    props.issue && props.issue.description ? props.issue?.description : "",
});

const imagePreviews = ref<string[]>(
  props.issue && props.issue.attachments ? props.issue.attachments : []
);

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);

  imagePreviews.value = [
    ...imagePreviews.value,
    ...files.value.map((file: any) => URL.createObjectURL(file)),
  ];
};

const errors = reactive({
  selectedOption: "",
  selectedBlockTypeOption: "",
  description: "",
});

const validate = () => {
  let valid = true;
  errors.selectedOption = "";
  errors.selectedBlockTypeOption = "";
  errors.description = "";

  if (!selectedOption.value) {
    errors.selectedOption = "Please select issue type!";
    valid = false;
  }

  if (!state.description) {
    errors.description = "Please add description of the issue!";
    valid = false;
  }

  if (!selectedBlockTypeOption.value) {
    errors.selectedBlockTypeOption =
      "Please select if you were blocked by this issue!";
    valid = false;
  }

  return valid;
};

const onSubmit = async () => {
  if (!validate()) return;

  isLoading.value = true;

  const formData = new FormData();
  formData.append("timeLost", state.timeLost);
  formData.append("description", state.description);
  formData.append("typeId", selectedOption.value.id);
  formData.append("blockTypeId", selectedBlockTypeOption.value.id);

  if (files.value) {
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }
  try {
    await client.post(`/termin/${props.terminId}/issue`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    isLoading.value = false;
    successToast("Successfully added issue.");
    emits("refresh");
    emits("toggleModal");
  } catch (error: any) {
    isLoading.value = false;
    errorToast(error);
  }
};

const onEdit = async () => {
  if (!validate()) return;

  isLoading.value = true;

  const formData = new FormData();
  formData.append("timeLost", state.timeLost);
  formData.append("description", state.description);
  formData.append("typeId", selectedOption.value.id);
  formData.append("blockTypeId", selectedBlockTypeOption.value.id);

  if (files.value) {
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }

  await client.put(
    `/termin/${props.terminId}/issue/${props.issue.id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  isLoading.value = false;
  successToast("Successfully updated issue.");
  emits("refresh");
  emits("toggleModal");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out", `${_ctx.isOpen ? 'flex' : 'hidden'}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.justView)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("img", {
                  class: _normalizeClass(state.description ? 'hidden' : 'block'),
                  src: _imports_0,
                  alt: "Icon"
                }, null, 2),
                _createElementVNode("img", {
                  class: _normalizeClass(state.description ? 'block' : 'hidden'),
                  src: _imports_1,
                  alt: "Icon"
                }, null, 2)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.justView ? _ctx.issue?.title : _ctx.headerTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.issue ? _ctx.issue.terminName : ""), 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('toggleModal')))
          }, _cache[11] || (_cache[11] = [
            _createElementVNode("img", {
              src: _imports_2,
              alt: "Close icon"
            }, null, -1)
          ]))
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (state.description ? onSubmit() : onEdit()), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4" }, " Choose an issue type ", -1)),
            _createVNode(SelectMenu, {
              "is-error": !!errors.selectedOption,
              "onUpdate:error": _cache[1] || (_cache[1] = ($event: any) => (errors.selectedOption = $event)),
              disabled: _ctx.justView,
              options: _ctx.options,
              modelValue: selectedOption.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedOption).value = $event))
            }, null, 8, ["is-error", "disabled", "options", "modelValue"]),
            (errors.selectedOption)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(errors.selectedOption), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium" }, " Description ", -1)),
            _withDirectives(_createElementVNode("textarea", {
              disabled: _ctx.justView,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((state.description) = $event)),
              onInput: _cache[4] || (_cache[4] = ($event: any) => (errors.description = '')),
              rows: "7",
              class: _normalizeClass(["w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border rounded-[5px] resize-none placeholder:text-primary-text-color", 
                errors.description ? 'border-red-500' : 'border-[#E3E1EA]'
              ])
            }, null, 42, _hoisted_12), [
              [_vModelText, state.description]
            ]),
            (errors.description)
              ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(errors.description), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium" }, " Are you blocked? ", -1)),
            _createVNode(SelectMenu, {
              "is-error": !!errors.selectedBlockTypeOption,
              disabled: _ctx.justView,
              options: _ctx.issueBlockTypes,
              modelValue: selectedBlockTypeOption.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((selectedBlockTypeOption).value = $event)),
              "onUpdate:error": _cache[6] || (_cache[6] = ($event: any) => (errors.selectedBlockTypeOption = $event))
            }, null, 8, ["is-error", "disabled", "options", "modelValue"]),
            (errors.selectedBlockTypeOption)
              ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(errors.selectedBlockTypeOption), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium" }, " Approx. time lost due to issue ", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              disabled: _ctx.justView,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((state.timeLost) = $event)),
              class: "w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color"
            }, null, 8, _hoisted_17), [
              [_vModelText, state.timeLost]
            ])
          ])
        ]),
        (imagePreviews.value && imagePreviews.value.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imagePreviews.value, (src, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "shrink-0 w-[123px] h-[123px]"
                }, [
                  _createElementVNode("img", {
                    src: src,
                    alt: "Uploaded image",
                    class: "shrink-0 object-cover w-[123px] h-[123px] rounded-md"
                  }, null, 8, _hoisted_19)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("label", {
            for: "file",
            class: _normalizeClass(["px-4 py-2 text-[#393840] font-medium rounded-md transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]", `${_ctx.justView ? '' : 'cursor-pointer hover:bg-[#F9F8F6]'}`])
          }, _cache[16] || (_cache[16] = [
            _createElementVNode("svg", {
              width: "15",
              height: "16",
              viewBox: "0 0 15 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108",
                stroke: "#393840",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ], -1),
            _createTextVNode(" Attach Files ")
          ]), 2),
          _createElementVNode("input", {
            disabled: _ctx.justView,
            multiple: "",
            class: "hidden",
            type: "file",
            id: "file",
            onChange: handleFileChange
          }, null, 40, _hoisted_21)
        ]),
        (!_ctx.justView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.issue ? onEdit() : onSubmit())),
                class: _normalizeClass(["mt-5 w-[150px] flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] duration-200", `${_ctx.justView ? '' : 'cursor-pointer hover:bg-[#28272c]'}`])
              }, [
                (isLoading.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23))
                  : (_openBlock(), _createElementBlock("span", _hoisted_24, "Save"))
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.justView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("div", {
                disabled: _ctx.justView,
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('toggleModal'))),
                class: "mt-5 w-[150px] flex items-center justify-center cursor-pointer hover:bg-[#28272c] py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] duration-200"
              }, _cache[17] || (_cache[17] = [
                _createElementVNode("span", null, "Close", -1)
              ]), 8, _hoisted_26)
            ]))
          : _createCommentVNode("", true)
      ], 32)
    ])
  ], 2))
}
}

})