import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/salesforce.svg'


const _hoisted_1 = { class: "h-full flex relative" }
const _hoisted_2 = { class: "desktop-custom:absolute desktop-custom:top-0 desktop-custom:left-0" }
const _hoisted_3 = { class: "w-full mx-auto" }
const _hoisted_4 = {
  key: 0,
  class: "w-fit ml-auto"
}
const _hoisted_5 = ["href"]

import MainLayout from "@/layouts/MainLayout.vue";
import ProjectOverview from "@/components/Project/ProjectOverview.vue";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { onMounted, ref } from "vue";
import client from "@/utils/axios";
import { useRoute } from "vue-router";
import { Project } from "@/types";
import { errorToast } from "@/utils/custom-toast";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDetails',
  setup(__props) {

const selectedPhase = ref();
const isLoading = ref(false);

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

const route = useRoute();
const id = route.params.id;
const projectDetails = ref<Project | null>(null);

// Fetch data
const getProjectDetails = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/projects/${id}`);
    projectDetails.value = response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      errorToast(error.response.data?.message);
    }
  } finally {
    isLoading.value = false;
  }
};
onMounted(() => {
  getProjectDetails();
});
//

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(MainSidebar, {
            "is-loading": isLoading.value,
            "phase-progress": projectDetails.value?.phases,
            "phase-steps": [],
            "selected-phase": selectedPhase.value,
            onOnPhaseSelect: onPhaseSelect,
            "is-link": true
          }, null, 8, ["is-loading", "phase-progress", "selected-phase"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(ProjectOverview, {
            "project-details": projectDetails.value,
            "is-loading": isLoading.value,
            onCallgetProjectDetails: getProjectDetails
          }, null, 8, ["project-details", "is-loading"])
        ]),
        (projectDetails.value?.notionId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                href: `https://smalt.lightning.force.com/lightning/r/Project__c/${projectDetails.value?.notionId}/view`,
                target: "_blank",
                class: "w-9 h-9"
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("img", {
                  class: "w-9 h-9 cursor-pointer",
                  src: _imports_0,
                  alt: ""
                }, null, -1)
              ]), 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})