<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from "vue";

type Props = {
  options?: any;
  modelValue?: any | null;
  disabled?: boolean;
  isError?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue", "update:error"]);

const optionsVisible = ref(false);
const selectedOption = ref<any | null>(props.modelValue);

const toggleOptionsHandler = () => {
  optionsVisible.value = !optionsVisible.value;
};

const selectedOptionHandler = (option: any) => {
  selectedOption.value = option;
  emit("update:modelValue", option);
  optionsVisible.value = false;
};

watch(
  () => props.modelValue,
  (newValue) => {
    selectedOption.value = newValue;
  }
);

watch(
  () => selectedOption.value,
  (newValue) => {
    if (newValue) {
      emit("update:error", "");
    }
  }
);
</script>

<template>
  <div
    class="mt-[6px] bg-[#F9F8F6] border rounded-[5px]"
    :class="{
      'cursor-pointer': disabled,
      'border-red-400': isError,
      'border-[#E3E1EA]': !isError,
    }"
  >
    <!-- Default selected option -->
    <div
      @click="!disabled ? toggleOptionsHandler() : () => {}"
      class="flex items-center justify-between p-3 h-[50px]"
    >
      <p
        class="text-base text-primary-text-color font-normal tracking-[0.01rem]"
      >
        {{ selectedOption ? selectedOption.name : "" }}
      </p>
      <img
        class="w-4 h-[10px] duration-200"
        src="@/assets/select-menu-arrow.svg"
        alt="Chevron"
        :class="optionsVisible ? '-rotate-180' : 'rotate-0'"
      />
    </div>
    <!-- Options -->
    <div v-if="options.length !== 0">
      <ul
        v-if="optionsVisible === true"
        class="list-none flex flex-col items-start"
        :class="props.options.length !== 0 ? 'p-3 pt-0' : 'p-0'"
      >
        <li
          @click="selectedOptionHandler(option)"
          v-for="option in props.options"
          :key="option.id"
          class="w-full rounded-primary-radius text-base text-primary-text-color font-normal hover:bg-[#e5e4e2] cursor-pointer hover:pl-[8px] duration-200"
          :class="option ? 'pl-0 pr-[5px] py-[10px]' : 'p-0'"
        >
          {{ option.name }}
        </li>
      </ul>
    </div>
    <div v-else>No options found.</div>
  </div>
</template>
