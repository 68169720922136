<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout.vue";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { onMounted, ref, defineEmits, computed } from "vue";
import client from "@/utils/axios";
import ExampleArchiveCard from "@/components/Card/ExampleArchiveCard.vue";
import ExampleArchiveModal from "@/components/Card/example-archive/ExampleArchiveModal.vue";
import DeleteModal from "@/components/Card/example-archive/DeleteModal.vue";
import { errorToast, successToast } from "@/utils/custom-toast";

const exampleArchive = ref([]);
const selectedStepId = ref<number>();
const selectedPhaseId = ref<number>();
const selectedInput = ref<any>();
const isDeleteModalOpen = ref(false);
const isArchiveModalOpen = ref(false);
const isArchiveModalUpdate = ref(false);
const isLoading = ref(false);
// Example archive fetch
const getExampleArchive = async () => {
  const response = await client.get("/input-examples");
  exampleArchive.value = response.data;
};
onMounted(() => {
  getExampleArchive();
});

const step = computed(() => {
  if (exampleArchive.value) {
    let phaseWithSteps: any = exampleArchive.value.find(
      (phase: any) => phase.id === selectedPhaseId.value
    );
    let step = phaseWithSteps?.steps.find(
      (step: any) => step.id === selectedStepId.value
    );

    return step;
  }
  return {};
});

const openDeleteModal = (input: any) => {
  selectedInput.value = input;
  isDeleteModalOpen.value = true;
};
const openArchiveModal = (input: any) => {
  selectedInput.value = input;
  isArchiveModalOpen.value = true;
};

const currentStep = computed(() => {
  let currentPhase: any = exampleArchive.value.find(
    (item: any) => item.id === selectedPhaseId.value
  );

  let currentStep = currentPhase?.steps.find(
    (item: any) => item.id === selectedStepId.value
  );
  return currentStep;
});

const onUpdateExample = async (payload: any, onFinish: any) => {
  const formData = new FormData();

  formData.append(`files[0]`, payload.file);
  formData.append("inputId", selectedInput.value.id + "");
  formData.append("stepId", selectedStepId.value + "");
  formData.append("descriptionEn", payload.descriptionEn);
  formData.append("descriptionDe", payload.descriptionDe);

  try {
    let resp: any = await client.post(`/input-examples`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await onFinish();
    isLoading.value = false;
    successToast(
      resp && resp.message ? resp.message : "Succesfully added description."
    );
    getExampleArchive();
    isArchiveModalOpen.value = false;
  } catch (error: any) {
    console.log(error);
    errorToast(error);
  }
};

const onDeleteExample = async () => {
  isLoading.value = true;
  try {
    let resp: any = await client.delete(
      `/input-examples/${selectedInput.value.id}`
    );
    isLoading.value = false;
    successToast(
      resp && resp.message ? resp.message : "Succesfully removed description."
    );
    getExampleArchive();
    isLoading.value = false;
    isDeleteModalOpen.value = false;
  } catch (error: any) {
    console.log(error);
    errorToast(error);
  }
};
</script>

<template>
  <div v-if="isArchiveModalOpen">
    <div
      class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-center justify-center"
    >
      <ExampleArchiveModal
        :selected-input="selectedInput"
        :is-loading="isLoading"
        :step-name="currentStep ? currentStep.name : ''"
        :is-update="isArchiveModalUpdate"
        @close-modal="isArchiveModalOpen = false"
        @on-update="onUpdateExample"
        @set-is-loading="isLoading = true"
      />
    </div>
  </div>
  <div v-if="isDeleteModalOpen">
    <div
      class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-center justify-center"
    >
      <DeleteModal
        :is-loading="isLoading"
        @on-delete="onDeleteExample"
        @close-modal="isDeleteModalOpen = false"
      />
    </div>
  </div>
  <MainLayout>
    <div class="flex items-start gap-[85px]">
      <div>
        <MainSidebar
          :is-example-archive-view="true"
          :example-archive="exampleArchive"
          :selected-step-id="selectedStepId"
          :selected-phase="selectedPhaseId"
          @on-phase-select="(phaseId) => (selectedPhaseId = phaseId)"
          @select-step="(stepId) => (selectedStepId = stepId)"
        />
      </div>
      <div class="w-full">
        <div class="w-full">
          <h3
            class="text-[20px] mb-[32px] leading-8 tracking-[0.01rem] text-[#A7A5AE] font-semibold h-[44px]"
          >
            Example image archive
          </h3>
          <!-- Step content -->
          <div
            v-if="step"
            class="flex flex-col h-[calc(100vh-130px)] max-w-[916px] overflow-y-auto"
          >
            <h2
              class="text-primary-text-color font-semibold text-[24px] leading-[30px] tracking-[0.01rem]"
            >
              {{ step ? step.name : "" }}
            </h2>
            <div
              v-if="step.inputs"
              class="w-full grid grid-cols-3 gap-2 items-stretch mt-3"
            >
              <!-- Card -->
              <div
                v-for="input in step.inputs"
                :key="input.id"
                class="h-full w-fit"
              >
                <ExampleArchiveCard
                  @open-delete-modal="openDeleteModal"
                  @open-archive-modal="openArchiveModal"
                  @close-delete-modal="
                    () => {
                      selectedInput = null;
                      isDeleteModalOpen = false;
                    }
                  "
                  @close-archive-modal="
                    () => {
                      selectedInput = null;
                      isArchiveModalOpen = false;
                    }
                  "
                  :input="input"
                />
              </div>
            </div>
            <!-- Group inputs -->
            <div v-if="step.groupInputs">
              <!-- Card -->
              <div
                v-for="groupInput in step.groupInputs"
                :key="groupInput.id"
                class="flex flex-col"
              >
                <h4
                  class="text-lg leading-8 tracking-[0.01rem] mt-3 text-[#A7A5AE] font-semibold"
                >
                  {{ groupInput.name }}
                </h4>

                <div
                  v-if="groupInput.inputs"
                  class="w-full grid grid-cols-3 gap-2 items-stretch mt-3"
                >
                  <!-- Card -->
                  <div
                    v-for="childInput in groupInput.inputs"
                    :key="childInput.id"
                    class="h-full"
                  >
                    <ExampleArchiveCard
                      @open-delete-modal="openDeleteModal"
                      @open-archive-modal="openArchiveModal"
                      @close-delete-modal="
                        () => {
                          selectedInput = null;
                          isDeleteModalOpen = false;
                        }
                      "
                      @close-archive-modal="
                        () => {
                          selectedInput = null;
                          isArchiveModalOpen = false;
                        }
                      "
                      :input="childInput"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Cards container -->
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
