<script setup lang="ts">
import { ref, defineProps } from "vue";

type Props = {
  stepId?: number;
  stepName: string;
  showDetails?: boolean;
};

defineProps<Props>();

const isOpen = ref(true);
</script>

<template>
  <div class="w-full">
    <div
      class="w-full mt-9 h-[70px]"
      :class="`${stepId ? 'sticky z-[18] bg-[#EFEEEC]' : ''} ${
        showDetails ? 'top-[308px]' : 'top-[119px]'
      }`"
    >
      <div
        class="flex items-center justify-between gap-2 p-3 mb-[12px] cursor-pointer rounded-primary-radius hover:bg-[#dfdedc] duration-500"
        :class="isOpen ? 'bg-transparent' : 'bg-[#dfdedc]'"
      >
        <h2
          @click="isOpen = !isOpen"
          class="w-full text-lg sm:text-2xl leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold"
        >
          {{ stepName }}
        </h2>
        <div class="flex gap-6 justify-end w-fit">
          <img
            @click="isOpen = !isOpen"
            :class="isOpen ? 'rotate-0' : 'rotate-180'"
            src="@/assets/chevron-up.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div v-if="isOpen">
      <div>
        <slot />
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <div class="p-3 bg-white rounded-primary-radius shadow-primary-box-shadow">
      <h3
        class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color pb-4 font-semibold"
      >
        {{ props.title }}
      </h3>
      <div class="flex flex-col items-center gap-[18px] my-[30px]">
        <img src="@/assets/text-block-list-image.svg" alt="" />
        <p class="text-sm tracking-[0.01rem] text-[#898790] font-semibold">
          All done!
        </p>
      </div>
    </div>
  </div> -->
</template>
