import { defineStore } from "pinia";
import type { User } from "@/types";

export const useUserStore = defineStore("user", {
  state: (): User => ({
    fullName: "",
    email: "",
    statusFilters: [],
    filter: "",
    searchFilter: "",
    lastLogin: "",
    accessToken: "",
    refreshToken: "",
  }),

  getters: {
    getUser: (state) => {
      return {
        fullName: state.fullName,
        email: state.email,
        lastLogin: state.lastLogin,
      };
    },
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
  },

  actions: {
    async setTokens(accessToken: any, refreshToken: any) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    },
    async setUser(
      name?: string,
      email?: string,
      statusFilters?: Array<string>,
      filter?: string,
      searchFilter?: string
    ) {
      this.fullName = name ? name : "";
      this.email = email ? email : "";
      this.statusFilters = statusFilters ? statusFilters : [];
      this.filter = filter ? filter : "";
      this.searchFilter = searchFilter ? searchFilter : "";
    },
    setStatusFilters(statusFilters: Array<string>) {
      this.statusFilters = statusFilters;
    },
    setFilter(filter: string) {
      this.filter = filter;
    },
    setSearchFilter(searchFilter: string) {
      this.searchFilter = searchFilter;
    },
    logout() {
      this.fullName = "";
      this.email = "";
      this.lastLogin = "";
      this.accessToken = "";
      this.refreshToken = "";
      this.statusFilters = [];
      this.filter = "";
      this.searchFilter = "";
    },
  },
  persist: true,
});
