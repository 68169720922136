import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/edit-plus-icon.svg'
import _imports_1 from '@/assets/calendar-icon.svg'
import _imports_2 from '@/assets/image-user-hover.svg'


const _hoisted_1 = { class: "flex flex-col relative" }
const _hoisted_2 = { class: "text-base leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold bg-[#DFDEDC]" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = { class: "gap-[6px] grid grid-cols-3 w-full" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "group-hover:block hidden absolute max-w-[300px] max-h-[300px] inset-0 bg-[#DFDEDC] p-3 rounded-primary-radius opacity-0 group-hover:opacity-100 cursor-pointer overflow-hidden z-10" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "mt-2 flex flex-col items-start gap-1" }
const _hoisted_10 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_11 = { class: "flex items-center gap-2" }
const _hoisted_12 = { class: "flex items-center gap-1" }
const _hoisted_13 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_14 = { class: "flex items-center gap-1" }
const _hoisted_15 = { key: 0 }



type Props = {
  input: any;
  step: any;
  selectedTermins: any;
  showDetails?: boolean;
  isGroup?: boolean;
};

type Emits = {
  (event: "addImageModalOpen"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SlideImagesBlock',
  props: {
    input: {},
    step: {},
    selectedTermins: {},
    showDetails: { type: Boolean },
    isGroup: { type: Boolean }
  },
  emits: ["addImageModalOpen"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex items-center justify-between bg-[#DFDEDC] min-h-12", `${_ctx.showDetails ? 'top-[378px]' : 'top-[188px]'} ${
        _ctx.isGroup ? '' : 'sticky top-[188px] z-[15]'
      }`])
    }, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.input.name), 1),
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addImageModalOpen'))),
          class: "cursor-pointer",
          src: _imports_0,
          alt: "Add icon"
        })
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.value.filter((item)=> _ctx.selectedTermins?.includes(item.terminId)), (image) => {
          return (_openBlock(), _createElementBlock("a", {
            href: image.imageUrl,
            target: "_blank",
            class: "w-full h-[300px] shrink-0 group no-underline relative rounded-primary-radius",
            key: image.logId
          }, [
            _createElementVNode("img", {
              class: "w-full h-[300px] object-cover rounded-primary-radius",
              src: image.imageUrl,
              alt: "Project image"
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                class: "w-full h-[225px] object-cover rounded-primary-radius",
                src: image.imageUrl,
                alt: "Project image"
              }, null, 8, _hoisted_8),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(image.terminName), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[1] || (_cache[1] = _createElementVNode("img", {
                      class: "w-4 h-4",
                      src: _imports_1
                    }, null, -1)),
                    _createElementVNode("p", _hoisted_13, _toDisplayString(new Date(image.createdAt).toLocaleDateString("de")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[2] || (_cache[2] = _createElementVNode("img", {
                      src: _imports_2,
                      alt: ""
                    }, null, -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(image.workers, (worker, index) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: index,
                        class: "text-sm text-primary-text-color font-normal"
                      }, [
                        _createTextVNode(_toDisplayString(worker), 1),
                        (index < image.workers.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, ","))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ], 8, _hoisted_5))
        }), 128))
      ])
    ])
  ]))
}
}

})