<script setup lang="ts">
import TerminSideMenu from "@/components/Sidebar/TerminSideMenu.vue";
import TerminVIew from "./TerminVIew.vue";
import { useRoute } from "vue-router";
import client from "@/utils/axios";
import { onMounted, onUnmounted, ref, watch } from "vue";
import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";
import { createClient } from "@supabase/supabase-js";

const route = useRoute();
const termin = ref<any>([]);
const project = ref();
const selectedTermin = ref();
const selectedPhase = ref();
const isLoading = ref(false);
const isIssuesFeatureFlagActive = ref();

const onTerminSelect = (terminId: any) => {
  selectedPhase.value = terminId;
};

const getTerminData = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(
      `/projects/${route.params.id}/termin/${route.params.terminId}`
    );
    termin.value = response.data;

    isIssuesFeatureFlagActive.value = response?.data?.isIssuesFeatureFlagActive;

    let newSelectedPhase = "";

    termin.value.phases.forEach((phase: any) => {
      phase.termins.forEach((termin: any) => {
        if (termin.id === route.params.terminId) {
          newSelectedPhase = phase.phase.id;
          if (selectedPhase.value != newSelectedPhase) {
            selectedPhase.value = newSelectedPhase;
          }
        }
      });
    });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => route.params.terminId, // Watch for the 'id' param change in the route
  (newId, oldId) => {
    if (newId !== oldId) {
      getTerminData();
      selectedTermin.value = newId;
    }
  },
  { immediate: true } // Immediately run on component mount
);

const getProjectData = async () => {
  try {
    const response = await client.get(`/projects/${route.params.id}`);
    project.value = response.data;
  } catch (error) {
    console.log(error);
  }
};

let supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL || "",
  process.env.VUE_APP_SUPABASE_API_KEY || ""
);

const handleFeatureFlag = (payload: any) => {
  if (payload) {
    isIssuesFeatureFlagActive.value = payload.new?.is_active;
  }
};

onMounted(() => {
  getProjectData();

  supabase
    .channel("feature_flag_issues")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "feature_flag",
        filter: `id=eq.1`,
      },
      handleFeatureFlag
    )
    .subscribe();
});
onUnmounted(() => {
  supabase.removeAllChannels();
});
</script>

<template>
  <div
    class="w-full h-screen relative flex items-start justify-center gap-5 py-[33px] px-6 bg-[#EFEEEC]"
  >
    <div v-if="termin.phases" class="absolute left-6">
      <TerminSideMenu
        @on-termin-select="onTerminSelect"
        :selected-termin="selectedTermin"
        :selected-phase="selectedPhase"
        :phases="termin.phases"
        :is-loading="isLoading"
        :key="termin"
      />
    </div>
    <div
      class="w-full custom-lg:w-[734px] xl:w-[834px] 2xl:w-[934px] ml-0 lg:ml-[160px] xl:ml-0"
    >
      <TerminVIew
        v-if="!isLoading"
        @refresh="getTerminData"
        :termin="termin"
        :key="termin"
        :project-name="project?.name"
        :is-issues-feature-flag-active="isIssuesFeatureFlagActive"
      />
      <div v-if="isLoading" class="flex items-center justify-center mt-20">
        <SpinnerLoader />
      </div>
    </div>
    <div v-if="termin?.notion_id" class="w-fit absolute right-0 pr-10">
      <a
        :href="`https://smalt.lightning.force.com/lightning/r/Appointment__c/${termin.notion_id}/view`"
        target="_blank"
        class="w-9 h-9"
      >
        <img
          class="w-9 h-9 cursor-pointer"
          src="@/assets/salesforce.svg"
          alt=""
      /></a>
    </div>
  </div>
</template>
