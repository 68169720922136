import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/calendar-icon.svg'
import _imports_1 from '@/assets/gray-user.svg'
import _imports_2 from '@/assets/pencil-gray.svg'


const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex flex-col gap-3 relative p-3 pt-0" }
const _hoisted_3 = {
  key: 0,
  class: "p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col gap-3"
}
const _hoisted_6 = { class: "w-full flex items-center justify-between" }
const _hoisted_7 = { class: "flex items-center gap-3" }
const _hoisted_8 = { class: "flex items-center gap-1" }
const _hoisted_9 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_10 = { class: "flex items-center gap-1" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "mt-4" }
const _hoisted_14 = { class: "text-base font-normal text-" }
const _hoisted_15 = { class: "p-3 rounded-primary-radius bg-[#e5ecf6] mt-2" }
const _hoisted_16 = { class: "text-base font-normal text-" }
const _hoisted_17 = {
  key: 3,
  class: "flex gap-1"
}
const _hoisted_18 = { class: "w-full flex items-center justify-between" }
const _hoisted_19 = { class: "flex items-center gap-3" }
const _hoisted_20 = { class: "flex items-center gap-1" }
const _hoisted_21 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_22 = { class: "flex items-center gap-1" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "mt-4 flex gap-2" }
const _hoisted_26 = { class: "text-base font-normal grow" }
const _hoisted_27 = { class: "px-3 py-[1px] rounded-primary-radius bg-[#e5ecf6] mt-2 h-fit w-fit" }
const _hoisted_28 = { class: "text-base font-normal h-fit w-fit" }
const _hoisted_29 = {
  key: 4,
  class: "flex flex-col gap-3"
}
const _hoisted_30 = { class: "w-full flex items-center justify-between" }
const _hoisted_31 = { class: "flex items-center gap-3" }
const _hoisted_32 = { class: "flex items-center gap-1" }
const _hoisted_33 = { class: "text-sm text-[#898790] foont-medium" }
const _hoisted_34 = { class: "flex items-center gap-1" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "mt-4" }
const _hoisted_38 = { class: "text-base font-normal text-" }
const _hoisted_39 = { class: "p-3 rounded-primary-radius bg-[#e5ecf6] mt-2" }
const _hoisted_40 = { class: "text-base font-normal text-" }

import { ref } from "vue";
import InputModal from "../InputModal.vue";
import SlideImagesBlock from "./SlideImagesBlock.vue";
import CheckListBlock from "./CheckListBlock.vue";
import AddImageModal from "../AddImageModal.vue";

type Props = {
  input: any;
  childInputs: any;
  project: string | string[];
  phase: number;
  phaseDetails: any;
  step: any;
  selectedTermins: any;
  showDetails?: boolean;
};
type Emits = {
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupInput',
  props: {
    input: {},
    childInputs: {},
    project: {},
    phase: {},
    phaseDetails: {},
    step: {},
    selectedTermins: {},
    showDetails: { type: Boolean }
  },
  emits: ["refresh"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;

const props = __props;

const isInputModalOpen = ref(false);
const isAddImageModalOpen = ref(false);
const selectedInput = ref<any>(null);
const selectedStep = ref<any>(null);

const toggleModalHandler: any = (input: any) => {
  selectedInput.value = input;
  isInputModalOpen.value = !isInputModalOpen.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isInputModalOpen.value && selectedInput.value)
      ? (_openBlock(), _createBlock(InputModal, {
          key: 0,
          input: selectedInput.value,
          "is-open": isInputModalOpen.value,
          project: _ctx.project,
          phase: _ctx.phase,
          termins: _ctx.phaseDetails.termins,
          onRefresh: _cache[0] || (_cache[0] = ($event: any) => (emits('refresh'))),
          onToggleModal: _cache[1] || (_cache[1] = 
      () => {
        isInputModalOpen.value = false;
        selectedInput.value = null;
      }
    )
        }, null, 8, ["input", "is-open", "project", "phase", "termins"]))
      : _createCommentVNode("", true),
    (isAddImageModalOpen.value && selectedInput.value)
      ? (_openBlock(), _createBlock(AddImageModal, {
          key: 1,
          steps: _ctx.phaseDetails.steps,
          termins: _ctx.phaseDetails.termins,
          "is-open": isAddImageModalOpen.value,
          project: _ctx.project,
          input: selectedInput.value,
          step: selectedStep.value,
          "header-title": selectedInput.value ? selectedInput.value.name : '',
          phase: _ctx.phase,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (isAddImageModalOpen.value = false)),
          onRefresh: _cache[3] || (_cache[3] = ($event: any) => (emits('refresh')))
        }, null, 8, ["steps", "termins", "is-open", "project", "input", "step", "header-title", "phase"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        class: _normalizeClass(["text-xl p-3 leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold sticky z-[15] bg-[#DFDEDC] rounded-t-primary-radius", `${_ctx.showDetails ? 'top-[378px]' : 'top-[189px]'}`])
      }, _toDisplayString(_ctx.input.name), 3),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.childInputs.some((input) => input.type === 'checklist' && input.value?.length > 0))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(CheckListBlock, {
                checklist: _ctx.childInputs.filter((input) => input.type === 'checkbox')
              }, null, 8, ["checklist"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.childInputs.some((input) => input.type === 'image' && input.value?.length > 0))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childInputs.filter((input) => input.type === 'image' && input.value?.length > 0), (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "flex flex-col gap-1"
                }, [
                  _createVNode(SlideImagesBlock, {
                    "is-group": true,
                    "show-details": _ctx.showDetails,
                    "selected-termins": _ctx.selectedTermins,
                    onAddImageModalOpen: 
              () => {
                selectedInput.value = item;
                selectedStep.value = _ctx.step;
                isAddImageModalOpen.value = true;
              }
            ,
                    input: item,
                    step: _ctx.step
                  }, null, 8, ["show-details", "selected-termins", "onAddImageModalOpen", "input", "step"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.childInputs.some((input) => input.type === 'text' && input.value ))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childInputs.filter((input) => input.type === 'text' && input.value && _ctx.selectedTermins?.includes(input.terminId)), (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _cache[4] || (_cache[4] = _createElementVNode("img", {
                          class: "w-3 h-3 cursor-pointer",
                          src: _imports_0,
                          alt: "Calendar"
                        }, null, -1)),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(item.createdAt
                      ? new Date(item.createdAt).toLocaleDateString("de")
                      : ""), 1)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _cache[5] || (_cache[5] = _createElementVNode("img", {
                          class: "w-3 h-3",
                          src: _imports_1,
                          alt: "User"
                        }, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.workers, (worker, index) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: index,
                            class: "text-sm text-[#898790] foont-medium"
                          }, [
                            _createTextVNode(_toDisplayString(worker), 1),
                            (index < item.workers.length - 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, ","))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("img", {
                      onClick: ($event: any) => (toggleModalHandler(item)),
                      class: "w-4 h-4 cursor-pointer",
                      src: _imports_2,
                      alt: "Pencil"
                    }, null, 8, _hoisted_12)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("p", _hoisted_14, _toDisplayString(item.name), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("p", _hoisted_16, _toDisplayString(item.value), 1)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.childInputs.some((input) => input.type === 'number' && input.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childInputs.filter((input) => input.type === 'number' && input.value && _ctx.selectedTermins?.includes(input.terminId)), (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "p-3 w-1/3 bg-white rounded-primary-radius shadow-primary-box-shadow"
                }, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _cache[6] || (_cache[6] = _createElementVNode("img", {
                          class: "w-3 h-3 cursor-pointer",
                          src: _imports_0,
                          alt: "Calendar"
                        }, null, -1)),
                        _createElementVNode("p", _hoisted_21, _toDisplayString(item.createdAt
                      ? new Date(item.createdAt).toLocaleDateString("de")
                      : ""), 1)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[7] || (_cache[7] = _createElementVNode("img", {
                          class: "w-3 h-3",
                          src: _imports_1,
                          alt: "User"
                        }, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.workers, (worker, index) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: index,
                            class: "text-sm text-[#898790] foont-medium"
                          }, [
                            _createTextVNode(_toDisplayString(worker), 1),
                            (index < item.workers.length - 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_23, ","))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("img", {
                      onClick: ($event: any) => (toggleModalHandler(item)),
                      class: "w-4 h-4 cursor-pointer",
                      src: _imports_2,
                      alt: "Pencil"
                    }, null, 8, _hoisted_24)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("p", _hoisted_26, _toDisplayString(item.name), 1),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("p", _hoisted_28, _toDisplayString(item.value), 1)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.childInputs.some((input) => input.type === 'select' && input.value && _ctx.selectedTermins?.includes(input.terminId)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childInputs.filter((input) => input.type === 'select' && input.value), (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
                }, [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _cache[8] || (_cache[8] = _createElementVNode("img", {
                          class: "w-3 h-3 cursor-pointer",
                          src: _imports_0,
                          alt: "Calendar"
                        }, null, -1)),
                        _createElementVNode("p", _hoisted_33, _toDisplayString(item.createdAt
                      ? new Date(item.createdAt).toLocaleDateString("de")
                      : ""), 1)
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _cache[9] || (_cache[9] = _createElementVNode("img", {
                          class: "w-3 h-3",
                          src: _imports_1,
                          alt: "User"
                        }, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.workers, (worker, index) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: index,
                            class: "text-sm text-[#898790] foont-medium"
                          }, [
                            _createTextVNode(_toDisplayString(worker), 1),
                            (index < item.workers.length - 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_35, ","))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("img", {
                      onClick: ($event: any) => (toggleModalHandler(item)),
                      class: "w-4 h-4 cursor-pointer",
                      src: _imports_2,
                      alt: "Pencil"
                    }, null, 8, _hoisted_36)
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("p", _hoisted_38, _toDisplayString(item.name), 1),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("p", _hoisted_40, _toDisplayString(item.value), 1)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
}

})