import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/smalt-logo-login.svg'


const _hoisted_1 = { class: "flex flex-col items-start" }

import LoginLayout from "@/layouts/LoginLayout.vue";
import {
  GoogleSignInButton,
  useOneTap,
  type CredentialResponse,
} from "vue3-google-signin";
import { createClient } from "@supabase/supabase-js";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { errorToast } from "@/utils/custom-toast";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL || "",
  process.env.VUE_APP_SUPABASE_API_KEY || ""
);

// handle success event
const handleLoginSuccess = async (response: CredentialResponse) => {
  if (response.credential !== null) {
    const { data, error } = await supabase.auth.signInWithIdToken({
      provider: "google",
      token: response.credential!,
    });
    if (error) {
      errorToast(error.message);
      return;
    }
    if (data && data.session) {
      let userStore = useUserStore();
      userStore.setUser(
        data.user.user_metadata.full_name
          ? data.user.user_metadata.full_name
          : data.user.user_metadata.name,
        data.user.email,
        data.user.user_metadata.statusFilters || [],
        data.user.user_metadata.filter || ""
      );
      userStore.setTokens(
        data.session.access_token,
        data.session.refresh_token
      );

      const redirectPath =
        (router.currentRoute.value.query.redirect as string) || "/";

      router.push(redirectPath);
    }
  }
};

// handle an error event
const handleLoginError = () => {
  console.error("Login failed");
};

// initialize One Tap
useOneTap({
  onSuccess: handleLoginSuccess,
  onError: handleLoginError,
  cancelOnTapOutside: true,
  // options
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LoginLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mb-[60px] md:mb-[118px]" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Smalt logo"
          })
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-[#FFFFFF] text-[42px] leading-[50px] tracking-[0.01em] pb-[41px] font-bold" }, [
          _createTextVNode(" Be welcomed to "),
          _createElementVNode("br"),
          _createTextVNode(" the "),
          _createElementVNode("span", { class: "text-[#C6C7F8]" }, "QC-dash")
        ], -1)),
        _createVNode(_unref(GoogleSignInButton), {
          shape: "pill",
          onSuccess: handleLoginSuccess,
          onError: handleLoginError
        })
      ])
    ]),
    _: 1
  }))
}
}

})