import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'
import _imports_1 from '@/assets/light-earth-icon.svg'
import _imports_2 from '@/assets/warning-red.svg'
import _imports_3 from '@/assets/attach-icon.svg'


const _hoisted_1 = { class: "w-[628px] p-5 mx-3 bg-white rounded-primary-radius flex flex-col items-start max-h-[90vh]" }
const _hoisted_2 = { class: "w-full h-[85px] flex items-start justify-between pb-[16px] mb-[16px] border-b border-[#e3e1ea]" }
const _hoisted_3 = { class: "flex flex-col items-start" }
const _hoisted_4 = { class: "text-primary-text-color text-xl leading-8 tracking-[0.01rem] font-semibold" }
const _hoisted_5 = { class: "text-[#898790] text-base leading-5 tracking-[0.01rem] font-normal" }
const _hoisted_6 = { class: "flex w-full flex-col overflow-y-auto" }
const _hoisted_7 = { class: "w-full" }
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = {
  key: 0,
  class: "flex gap-1 items-center"
}
const _hoisted_10 = { class: "text-[#ed857f] text-sm" }
const _hoisted_11 = { class: "w-full mt-[24px]" }
const _hoisted_12 = {
  key: 0,
  class: "flex gap-1 items-center"
}
const _hoisted_13 = { class: "text-[#ed857f] text-sm" }
const _hoisted_14 = {
  key: 0,
  class: "mt-[24px] rounded-primary-radius"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "w-[123px] h-[123px] flex o gap-1 mt-6"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "w-full mt-[24px] mb-4" }
const _hoisted_19 = { class: "flex items-center gap-2 mb-2" }
const _hoisted_20 = {
  for: "file",
  class: "px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
}
const _hoisted_21 = {
  key: 0,
  class: "flex gap-1 items-center"
}
const _hoisted_22 = { class: "text-[#ed857f] text-sm" }
const _hoisted_23 = { class: "w-full flex flex-col gap-4 pt-3 border-t border-[#e3e1ea]" }
const _hoisted_24 = { class: "flex items-center justify-between" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = {
  key: 0,
  class: "w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_27 = { key: 1 }

import { ref } from "vue";

type Props = {
  selectedInput: any;
  stepName: string;
  parentInputName?: string;
  isUpdate: boolean;
  isLoading: boolean;
};

type Emits = {
  (event: "closeModal"): void;
  (event: "setIsLoading"): void;
  (event: "onUpdate", payload: any, onFinish: any): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ExampleArchiveModal',
  props: {
    selectedInput: {},
    stepName: {},
    parentInputName: {},
    isUpdate: { type: Boolean },
    isLoading: { type: Boolean }
  },
  emits: ["closeModal", "setIsLoading", "onUpdate"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const englishInput = ref(
  props.selectedInput ? props.selectedInput.description.en : ""
);
const germanInput = ref(
  props.selectedInput ? props.selectedInput.description.de : ""
);
const englishInputError = ref("");
const germanInputError = ref("");
const imageInputError = ref("");
const files = ref([]);
const imagePreviews = ref<string[]>([]);
let formErrors: Array<string> = [];

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
  imagePreviews.value = files.value.map((file: any) =>
    URL.createObjectURL(file)
  );
};

const validate = () => {
  if (germanInput.value && germanInput.value.trim().length > 0) {
    if (germanInput.value.length > 130) {
      germanInputError.value = "Max 130 characters";
      formErrors.push("germanInput");
    } else {
      formErrors = formErrors.filter((item: string) => item !== "germanInput");
      germanInputError.value = "";
    }
  } else {
    germanInputError.value = "Description required";
    formErrors.push("germanInput");
  }

  if (englishInput.value && englishInput.value.trim().length > 0) {
    if (englishInput.value.trim().length > 130) {
      englishInputError.value = "Max 130 characters";
      formErrors.push("englishInput");
    } else {
      formErrors = formErrors.filter((item: string) => item !== "englishInput");
      englishInputError.value = "";
    }
  } else {
    englishInputError.value = "Description required";
    formErrors.push("englishInput");
  }

  if (props.selectedInput.exampleImage) {
    formErrors = formErrors.filter((item: string) => item !== "imageInput");
    imageInputError.value = "";
  } else {
    if (files.value.length === 0) {
      imageInputError.value = "Image required";
      formErrors.push("imageInput");
    } else {
      imageInputError.value = "";
      formErrors = formErrors.filter((item: string) => item !== "imageInput");
    }
  }

  return formErrors.length === 0;
};

const onSubmit = () => {
  const isFormValid = validate();
  if (isFormValid) {
    let payload: any = {
      file: files.value ? files.value[0] : null,
      descriptionEn: englishInput.value,
      descriptionDe: germanInput.value,
    };

    emits("setIsLoading");
    emits("onUpdate", payload, clearForm);
  }
};

const clearForm = () => {
  englishInput.value = "";
  germanInput.value = "";
  files.value = [];
  imagePreviews.value = [];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.stepName ? _ctx.stepName : ""), 1),
        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.parentInputName ? _ctx.parentInputName + "/" : "") + " " + _toDisplayString(_ctx.selectedInput ? _ctx.selectedInput.name : ""), 1)
      ]),
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = 
          () => {
            clearForm();
            emits('closeModal');
          }
        ),
        class: "cursor-pointer h-[16px] w-[16px]",
        src: _imports_0,
        alt: ""
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"w-full flex items-center justify-between mb-[6px]\"><p class=\"text-[#898790] text-base leading-[26px] tracking-[0.01rem] font-medium\"> Describe how to take a good image </p><div class=\"flex items-center gap-1\"><img src=\"" + _imports_1 + "\" alt=\"Earth icon\"><h4 class=\"text-sm font-normal text-primary-text-color\">EN</h4></div></div>", 1)),
          _withDirectives(_createElementVNode("textarea", {
            rows: "4",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((englishInput).value = $event)),
            class: _normalizeClass(["w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color", `${englishInputError.value.length > 0 ? 'border-[#ed857f]' : ''}`]),
            type: "text"
          }, null, 2), [
            [_vModelText, englishInput.value]
          ]),
          (englishInputError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _cache[3] || (_cache[3] = _createElementVNode("img", {
                  src: _imports_2,
                  alt: "Warning"
                }, null, -1)),
                _createElementVNode("span", _hoisted_10, _toDisplayString(englishInputError.value), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"w-full flex items-center justify-between mb-[6px]\"><p class=\"text-[#898790] text-base leading-[26px] tracking-[0.01rem] font-medium\"> Describe how to take a good image </p><div class=\"flex items-center gap-1\"><img src=\"" + _imports_1 + "\" alt=\"Earth icon\"><h4 class=\"text-sm font-normal text-primary-text-color\">DE</h4></div></div>", 1)),
          _withDirectives(_createElementVNode("textarea", {
            rows: "4",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((germanInput).value = $event)),
            class: _normalizeClass(["w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] placeholder:text-primary-text-color", `${germanInputError.value ? 'border-[#ed857f]' : ''}`]),
            type: "text"
          }, null, 2), [
            [_vModelText, germanInput.value]
          ]),
          (germanInputError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[5] || (_cache[5] = _createElementVNode("img", {
                  src: _imports_2,
                  alt: "Warning"
                }, null, -1)),
                _createElementVNode("span", _hoisted_13, _toDisplayString(germanInputError.value), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (
          _ctx.selectedInput &&
          _ctx.selectedInput.exampleImage &&
          imagePreviews.value.length === 0
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("img", {
              class: "w-[123px] h-[123px] rounded-primary-radius object-cover",
              src: _ctx.selectedInput ? _ctx.selectedInput.exampleImage : ''
            }, null, 8, _hoisted_15)
          ]))
        : _createCommentVNode("", true),
      (imagePreviews.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("img", {
              src: imagePreviews.value[0],
              alt: "Uploaded image",
              class: "shrink-0 object-cover w-[123px] h-[123px] rounded-md"
            }, null, 8, _hoisted_17)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("label", _hoisted_20, [
            _cache[7] || (_cache[7] = _createElementVNode("img", {
              src: _imports_3,
              alt: "Attach icon"
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString((_ctx.selectedInput && _ctx.selectedInput.exampleImage) ||
              imagePreviews.value.length > 0
                ? "Change example image"
                : "Attach example image"), 1)
          ]),
          _createElementVNode("input", {
            multiple: "",
            class: "hidden",
            type: "file",
            id: "file",
            onChange: handleFileChange
          }, null, 32)
        ]),
        (imageInputError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _cache[8] || (_cache[8] = _createElementVNode("img", {
                src: _imports_2,
                alt: "Warning"
              }, null, -1)),
              _createElementVNode("span", _hoisted_22, _toDisplayString(imageInputError.value), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("button", {
          onClick: clearForm,
          class: "text-base leading-5 tracking-[0.01rem] text-[#898790] font-semibold p-[10px] rounded-primary-radius"
        }, " Clear "),
        _createElementVNode("button", {
          disabled: _ctx.isLoading,
          onClick: onSubmit,
          class: "ml-auto w-[150px] flex items-center justify-center gap-2 text-base border-2 border-transparent leading-5 tracking-[0.01rem] bg-primary-text-color text-white font-semibold p-[10px] rounded-primary-radius"
        }, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26))
            : (_openBlock(), _createElementBlock("span", _hoisted_27, "Save"))
        ], 8, _hoisted_25)
      ])
    ])
  ]))
}
}

})