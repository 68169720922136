<script setup lang="ts">
import { Project } from "@/types";
import { computed, defineProps, defineEmits, ref } from "vue";
import CardLoader from "@/components/Loaders/CardLoader.vue";
import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import ConfirmModal from "@/components/Re-usable/ConfirmModal.vue";

type Props = {
  isLoading: boolean;
  projectDetails: Project | null;
};
const props = defineProps<Props>();
const emit = defineEmits(["callgetProjectDetails"]);

const selectedPage = ref<number>(1);
const isModalVisible = ref(false);

let isLoadingSpinner = ref(false);

let documentPages = computed(() => {
  if (props.projectDetails?.documents) {
    let documentSections = [
      {
        id: 1,
        title: "Baumappe",
        sectionNumber: 1,
        typeIds: [4],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 4
        ),
      },
      {
        id: 2,
        title: "Stage reports",
        sectionNumber: 1,
        typeIds: [5, 6, 7],
        documents: props.projectDetails?.documents.filter(
          (item: any) =>
            item.project_document_type?.id === 5 ||
            item.project_document_type?.id === 6 ||
            item.project_document_type?.id === 7
        ),
      },

      {
        id: 3,
        title: "Abnahmeprotocoll",
        sectionNumber: 2,
        typeIds: [2],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 2
        ),
      },
      {
        id: 4,
        title: "Prüfbericht",
        sectionNumber: 2,
        typeIds: [3],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type?.id === 3
        ),
      },

      {
        id: 5,
        title: "Other",
        sectionNumber: 3,
        typeIds: [null],
        documents: props.projectDetails?.documents.filter(
          (item: any) => item.project_document_type === null
        ),
      },
    ];

    const filteredSections = documentSections.filter(
      (section: any) => section.documents && section.documents.length > 0
    );

    const pages = [];
    let tempSections = [];
    let currentSectionNumber = filteredSections.length
      ? filteredSections[0].sectionNumber
      : null;
    let pageNumber = 1;

    for (const section of filteredSections) {
      if (section.sectionNumber !== currentSectionNumber) {
        pages.push({
          id: pageNumber++,
          sections: [...tempSections],
        });
        tempSections = [];
        currentSectionNumber = section.sectionNumber;
      }

      tempSections.push(section);
    }
    if (tempSections.length) {
      pages.push({
        id: pageNumber,
        sections: [...tempSections],
      });
    }
    return pages;
  }

  return [];
});

const selectedDocumentId = ref<any>(null);

const refreshBaumappe = async () => {
  isLoadingSpinner.value = true;
  try {
    await client.post(
      `/projects/${props.projectDetails?.id}/document/${selectedDocumentId.value}/refresh`
    );
    successToast("Document refreshed.");
    isLoadingSpinner.value = false;
    emit("callgetProjectDetails");
  } catch (error: any) {
    isLoadingSpinner.value = false;
    console.log(error);
    errorToast(error);
  }
};

const deleteDocument = async () => {
  try {
    await client.delete(
      `/projects/${props.projectDetails?.id}/document/${selectedDocumentId.value}`
    );
    successToast("Document deleted.");
    emit("callgetProjectDetails");
  } catch (error: any) {
    errorToast(error);
  }
};
</script>

<template>
  <ConfirmModal
    :show="isModalVisible"
    message="Are you sure you want to delete this document?"
    @confirm="deleteDocument"
    @cancel="isModalVisible = false"
  />
  <div class="h-[356px] w-full p-3 bg-white rounded-primary-radius">
    <div class="h-full overflow-hidden">
      <div class="flex items-center justify-between mb-2">
        <h2
          class="text-xl text-primary-text-color tracking-[0.01rem] font-semibold text-prim"
        >
          Documents
        </h2>
        <div v-if="documentPages.length > 0" class="flex items-center gap-6">
          <button
            :disabled="selectedPage === 1"
            class="cursor-pointer"
            @click="selectedPage = selectedPage - 1"
          >
            <img
              v-if="selectedPage === 1"
              src="@/assets/arrow-left-gray.svg"
              alt=""
            />
            <img
              v-if="selectedPage !== 1"
              src="@/assets/arrow-left.svg"
              alt=""
            />
          </button>
          <button
            :disabled="selectedPage === documentPages.length"
            class="cursor-pointer"
            @click="selectedPage = selectedPage + 1"
          >
            <img
              v-if="selectedPage === documentPages.length"
              src="@/assets/arrow-right-gray.svg"
              alt="Arrow right"
            />
            <img
              v-show="selectedPage !== documentPages.length"
              src="@/assets/arrow-right.svg"
              alt="Arrow right"
            />
          </button>
        </div>
      </div>
      <CardLoader v-if="isLoading" />
      <div
        v-else
        class="w-full h-[290px] overflow-y-auto flex flex-col items-start gap-2"
      >
        <div
          v-for="page in documentPages"
          :key="page.id"
          v-show="page.id === selectedPage"
          class="w-full flex flex-col gap-2"
        >
          <div
            v-for="section in page?.sections"
            :key="section.id"
            class="border-gray-300"
          >
            <h5
              class="text-base leading-[20px] text-[#393840] font-medium tracking-[0.01rem] py-1"
            >
              {{ section.title }}
            </h5>
            <ul
              class="p-2 list-none flex flex-col gap-[2px] rounded-primary-radius border"
            >
              <li
                v-for="(document, index) in section?.documents"
                :key="document.id"
                :class="{ 'border-t border-[#E3E1EA]': index !== 0 }"
                class="flex items-center justify-between"
              >
                <div class="flex flex-col pt-[6px] pb-[4px]">
                  <span
                    class="flex-[80%] text-base text-primary-text-color font-normal tracking-[0.01rem]"
                  >
                    {{ document.name }}
                  </span>
                  <div class="flex items-center text-sm gap-1 text-[#a7a5ae]">
                    <img
                      class="w-[14px] h-[14px] opacity-50"
                      src="@/assets/calendar-icon.svg"
                      alt="User icon"
                    />

                    <span class="text-[#a7a5ae] font-normal tracking-[0.01rem]">
                      {{
                        new Date(document.created_at).toLocaleDateString(
                          "de-DE"
                        )
                      }}</span
                    >
                  </div>
                </div>
                <div class="flex-[20%] flex items-center justify-end">
                  <a
                    class="bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300"
                    :href="`${document.url}?download`"
                    target="_blank"
                  >
                    <img
                      class="min-w-[16px] w-[16px] min-h-[16px]"
                      src="@/assets/download-icon.svg"
                      alt=""
                    />
                  </a>
                  <button
                    v-if="section.typeIds[0] === 4"
                    class="bg-transparent p-[5px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300"
                    @click="
                      () => {
                        selectedDocumentId = document.id;
                        refreshBaumappe();
                      }
                    "
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      :class="`${isLoadingSpinner ? 'animate-spin' : ''}`"
                    >
                      <path
                        d="M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179"
                        stroke="#A7A5AE"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    v-else
                    class="bg-transparent p-[5px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300"
                    @click="
                      () => {
                        selectedDocumentId = document.id;
                        isModalVisible = true;
                      }
                    "
                  >
                    <img
                      class="min-w-[12px] w-[12px] min-h-[12px] filter grayscale"
                      fill="currentColor"
                      src="@/assets/trash-icoon.svg"
                      alt=""
                    />
                  </button>
                  <a
                    class="bg-transparent p-[2px] rounded-primary-radius cursor-pointer hover:bg-[#e5e4e2] duration-300"
                    :href="document.url + '?' + new Date().toISOString()"
                    target="_blank"
                  >
                    <img
                      class="min-w-[16px] w-[16px] min-h-[16px]"
                      src="@/assets/arrow-up-right.svg"
                      alt=""
                    />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="documentPages.length === 0"
          class="w-full mt-[70px] flex items-center justify-center"
        >
          <div>
            <img src="@/assets/no-documents-image.svg" alt="No documents" />
            <p
              class="text-sm mt-2 tracking-[0.01rem] font-medium text-[#898790]"
            >
              No documents
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
