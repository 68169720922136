import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/chevron-right.svg'


const _hoisted_1 = { class: "w-full h-[calc(100vh-42px)] pl-3 sm:pl-0 overflow-y-auto" }
const _hoisted_2 = { class: "max-w-[934px] mx-auto" }
const _hoisted_3 = { class: "flex items-center justify-between pb-[9px]" }
const _hoisted_4 = { class: "flex items-center gap-[2px]" }
const _hoisted_5 = { class: "relative group" }
const _hoisted_6 = { class: "text-xl leading-[26px] tracking-[0.01rem] text-[#616068] font-medium" }
const _hoisted_7 = { class: "text-xs text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]" }
const _hoisted_8 = {
  key: 0,
  class: "flex items-center gap-[12.5px]"
}
const _hoisted_9 = { class: "flex-1" }
const _hoisted_10 = { class: "text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "flex-1" }
const _hoisted_13 = { class: "text-[14px] tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#A7A5AE]" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_16 = {
  key: 2,
  class: "mt-3"
}
const _hoisted_17 = { class: "mt-3" }

import HeadBlocks from "@/components/Project/Appointment/HeadBlocks.vue";
import IssuesBlock from "@/components/Project/Appointment/IssuesBlock.vue";
import MeisterNoteBlock from "@/components/Project/Appointment/MeisterNoteBlock.vue";
import PrufBerichtModal from "@/components/Project/Appointment/Modal/PrufBerichtModal.vue";
import AbnameProtocolModal from "@/components/Project/Appointment/Modal/AbnameProtocolModal.vue";
import NoteBlock from "@/components/Project/Appointment/NoteBlock.vue";
import client from "@/utils/axios";
import { errorToast } from "@/utils/custom-toast";
import { getStatusByName } from "@/utils/report-status";
import { ref, onMounted } from "vue";
import { textTransform } from "@/utils/parse-data";
import { RouterLink, useRoute } from "vue-router";

type Props = {
  termin: any;
  projectName: string;
  isIssuesFeatureFlagActive: boolean;
};

type Emits = {
  (event: "refresh"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminVIew',
  props: {
    termin: {},
    projectName: {},
    isIssuesFeatureFlagActive: { type: Boolean }
  },
  emits: ["refresh"],
  setup(__props: any) {

const props = __props;


const route = useRoute();
const path = route.path;
const transformedPath = path.split("/")[2];

const prufberichtModalOpen = ref(false);
const abnahmeProtocolModalOpen = ref(false);
const prufberichtReportData = ref<any>(null);
const abnahmeprotokollReportData = ref<any>(null);

const getPrufberichtReportData = async () => {
  try {
    let resp = await client.get(
      `/termin/${props.termin.id}/prufbericht-report`
    );

    prufberichtReportData.value = resp.data;
  } catch (error: any) {
    errorToast(error);
  }
};

const getAbnahmeprotokollReportData = async () => {
  try {
    let resp = await client.get(
      `/termin/${props.termin.id}/abnahmeprotokoll-report`
    );

    abnahmeprotokollReportData.value = resp.data;
  } catch (error: any) {
    errorToast(error);
  }
};

const openPrufberichtModal = async () => {
  await getPrufberichtReportData();
  prufberichtModalOpen.value = true;
};
const openAbnahmeprotokollModal = async () => {
  await getAbnahmeprotokollReportData();
  abnahmeProtocolModalOpen.value = true;
};

onMounted(async () => {
  if (props.termin.phase_progress.phase.id === 2) {
    await getPrufberichtReportData();
    await getAbnahmeprotokollReportData();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(RouterLink), {
              to: `/projects/${_unref(transformedPath)}`,
              class: "relative text-primary-text-color text-xl leading-[26px] tracking-[0.01rem] font-medium after:absolute after:bottom-[2px] after:left-0 after:w-full after:h-[1px] after:bg-primary-text-color after:opacity-0 group-hover:after:opacity-100 after:transition-opacity after:duration-200"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.projectName ? _ctx.projectName : ""), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Caret right"
          }, null, -1)),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.termin.name), 1)
        ]),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(textTransform)(_ctx.termin.termin_status?.name)), 1)
      ]),
      _createElementVNode("div", null, [
        _createVNode(HeadBlocks, { termin: _ctx.termin }, null, 8, ["termin"])
      ]),
      (_ctx.termin.phase_progress?.phase.id === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", {
                onClick: openAbnahmeprotokollModal,
                class: "flex items-center justify-between p-3 rounded-primary-radius bg-[#E5E4E2] cursor-pointer hover:bg-[#dfdedc] duration-500"
              }, [
                _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]" }, " Abnameprotokoll ", -1)),
                _createElementVNode("p", _hoisted_10, _toDisplayString(abnahmeprotokollReportData.value
                  ? _unref(getStatusByName)(abnahmeprotokollReportData.value?.status)
                  : ""), 1)
              ]),
              (abnahmeProtocolModalOpen.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(AbnameProtocolModal, {
                      termin: _ctx.termin,
                      data: abnahmeprotokollReportData.value,
                      onClose: _cache[0] || (_cache[0] = ($event: any) => (abnahmeProtocolModalOpen.value = false))
                    }, null, 8, ["termin", "data"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", {
                onClick: openPrufberichtModal,
                class: "flex items-center justify-between p-3 rounded-primary-radius bg-[#E5E4E2] cursor-pointer hover:bg-[#dfdedc] duration-500"
              }, [
                _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "text-primary-text-color text-xl leading-[32px] font-semibold tracking-[0.01rem]" }, " Prüfbericht ", -1)),
                _createElementVNode("p", _hoisted_13, _toDisplayString(prufberichtReportData.value
                  ? _unref(getStatusByName)(prufberichtReportData.value?.status)
                  : ""), 1)
              ]),
              (prufberichtModalOpen.value && prufberichtReportData.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(PrufBerichtModal, {
                      termin: _ctx.termin,
                      data: prufberichtReportData.value,
                      onClose: _cache[1] || (_cache[1] = ($event: any) => (prufberichtModalOpen.value = false))
                    }, null, 8, ["termin", "data"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.termin.issues)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(IssuesBlock, {
              "is-issues-feature-flag-active": _ctx.isIssuesFeatureFlagActive,
              "issue-types": _ctx.termin.issueTypes?.map((item)=> {
            return {
              id: item.id,
              name: item.name
            }
          }),
              "issue-block-types": _ctx.termin.issueBlockTypes?.map((item)=> {
            return {
              id: item.id,
              name: item.name
            }
          }),
              issues: _ctx.termin.issues,
              termin: _ctx.termin.id,
              onRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('refresh')))
            }, null, 8, ["is-issues-feature-flag-active", "issue-types", "issue-block-types", "issues", "termin"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.termin.notes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createVNode(NoteBlock, {
              termin: _ctx.termin.id,
              onRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('refresh'))),
              notes: _ctx.termin.notes
            }, null, 8, ["termin", "notes"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(MeisterNoteBlock, {
          termin: _ctx.termin.id,
          "meister-note": _ctx.termin.meisterNote,
          onRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('refresh')))
        }, null, 8, ["termin", "meister-note"])
      ])
    ])
  ]))
}
}

})