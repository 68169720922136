import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/Login/LoginView.vue";
import ProjectDetails from "@/views/projects/ProjectDetails.vue";
import PhasePage from "@/views/projects/PhasePage.vue";
import { useUserStore } from "@/stores/user";
import TerminPage from "@/views/projects/TerminPage.vue";
import ExampleArchiveView from "@/views/Example archive/ExampleArchiveView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/projects/:id",
    name: "Projects",
    component: ProjectDetails,
  },
  {
    path: "/projects/:id/phase/:phaseId",
    name: "Phase",
    component: PhasePage,
    props: true,
  },
  {
    path: "/projects/:id/termin/:terminId",
    name: "Termin",
    component: TerminPage,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/example-archive",
    name: "Example Archive",
    component: ExampleArchiveView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  if (!userStore.accessToken && to.name !== "Login") {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else {
    if (userStore.accessToken && to.name === "Login") {
      next({ name: "Home" });
    }
  }
  next();
});

export default router;
