<script setup lang="ts">
import DeletePopup from "./Modal/DeletePopup.vue";
import IssueModal from "./Modal/IssueModal.vue";
import { ref, defineProps, defineEmits } from "vue";

type Props = {
  issues: any[];
  issueTypes: Array<{ id: number; name: string }>;
  issueBlockTypes: Array<{ id: number; name: string }>;
  termin: string;
  isIssuesFeatureFlagActive: boolean;
};

type Emits = {
  (event: "refresh"): void;
};

const props = defineProps<Props>();

defineEmits<Emits>();

const isModal = ref(false);
const isPopup = ref(false);
const selectedIssue = ref<any | null>(null);
const issues = ref(props.issues);
const isJustViewIssueForm = ref();

// Issue modal
const toggleIssueModal = (isJustView: boolean, issue?: any) => {
  selectedIssue.value = issue ? issue : null;
  isJustViewIssueForm.value = isJustView;
  isModal.value = !isModal.value;
};

const togglePopup = (issue?: any) => {
  selectedIssue.value = issue ? issue : null;
  isPopup.value = !isPopup.value;
};
</script>

<template>
  <div class="w-full bg-[#E5E4E2] p-3 rounded-primary-radius overflow-x-hidden">
    <div
      class="flex items-center justify-between"
      :class="issues?.length > 0 ? 'pb-3' : 'pb-0'"
    >
      <h2
        class="text-lg sm:text-[20px] leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold"
      >
        Issues ({{ issues?.length ? issues?.length : "0" }})
      </h2>
      <img
        v-if="!isIssuesFeatureFlagActive"
        @click="toggleIssueModal(false, null)"
        class="cursor-pointer"
        src="@/assets/edit-plus-icon.svg"
        alt="Edit"
      />
    </div>
    <!-- Isuse modal -->
    <IssueModal
      v-if="isModal"
      :issue="selectedIssue"
      :is-open="isModal"
      :just-view="isJustViewIssueForm"
      :options="issueTypes"
      :issue-block-types="issueBlockTypes"
      :header-title="
        selectedIssue ? 'Edit ' + selectedIssue.title : 'New Issue'
      "
      :termin-id="termin"
      @toggle-modal="
        isModal = false;
        selectedIssue = null;
      "
      @refresh="$emit('refresh')"
    />
    <!-- Issue block -->
    <div class="flex gap-3 overflow-x-auto scrollable-container">
      <div class="flex items-center gap-2">
        <div
          v-for="issue in issues"
          :key="issue.id"
          class="w-[450px] shrink-0 h-full flex flex-col items-start justify-start p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
        >
          <!-- Header -->
          <div class="w-full flex items-center justify-between mb-4">
            <h3
              @click="toggleIssueModal(true, issue)"
              class="cursor-pointer text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
              :class="`${isIssuesFeatureFlagActive ? 'w-full' : 'w-10/12'}`"
            >
              {{ issue.title }}
            </h3>
            <div
              v-if="!isIssuesFeatureFlagActive"
              class="flex items-center justify-end gap-3 w-2/12"
            >
              <img
                @click="toggleIssueModal(false, issue)"
                class="cursor-pointer"
                src="@/assets/pencil-icon.svg"
                alt="Pencil"
              />
              <img
                @click="togglePopup(issue)"
                class="cursor-pointer"
                src="@/assets/trash-icoon.svg"
                alt="Icon"
              />
              <div
                v-if="isPopup === true"
                class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/55 flex items-center justify-center"
              >
                <DeletePopup
                  :termin-id="termin"
                  :id="selectedIssue ? selectedIssue.id : null"
                  title="issue"
                  :is-note="false"
                  @toggle-popup="togglePopup"
                  @refresh="$emit('refresh')"
                />
              </div>
            </div>
          </div>
          <!-- Content -->
          <div
            @click="toggleIssueModal(true, issue)"
            class="w-full flex flex-col gap-3 cursor-pointer"
          >
            <p>
              {{ issue.description }}
            </p>
            <!-- Images -->
            <div
              v-if="issue.attachments && issue.attachments?.length > 0"
              class="flex items-center gap-3 mt-5"
            >
              <div class="flex gap-2 overflow-x-auto scrollable-container">
                <div
                  v-for="(image, index) in issue.attachments"
                  :key="index"
                  class="flex-shrink-0"
                >
                  <img
                    class="w-[200px] h-[230px] object-cover rounded-primary-radius"
                    :src="image"
                    alt="Termin image"
                  />
                </div>
              </div>
            </div>
            <div
              v-else
              class="h-[230px] flex flex-col items-center justify-center"
            >
              <img src="@/assets/no-image.svg" alt="" />
              <p class="text-sm text-[#898790] font-medium pt-2">No images</p>
            </div>

            <div class="mt-3 flex gap-4">
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3 cursor-pointer"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar"
                />
                <p class="text-sm text-[#898790] foont-medium">
                  {{ new Date(issue.createdAt).toLocaleDateString("DE") }}
                </p>
              </div>
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3"
                  src="@/assets/image-user-hover.svg"
                  alt="User"
                />

                <p
                  v-for="(worker, index) in issue.workers"
                  :key="index"
                  class="text-sm text-[#898790] font-medium"
                >
                  {{ worker }}
                  <span v-if="index < issue.workers.length - 1">,</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.v-enter-from {
  transform: translateY(100%);
}

.v-leave-to {
  transform: translateY(-100%);
}
</style>
