import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/chevron-up.svg'


const _hoisted_1 = { class: "bg-[#DFDEDC] rounded-primary-radius" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col lg:flex-row items-start p-3 pt-0 gap-3 h-[196px]"
}
const _hoisted_3 = { class: "flex-1 flex flex-col items-start gap-2" }
const _hoisted_4 = { class: "flex flex-col gap-1" }
const _hoisted_5 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_6 = { class: "flex flex-col gap-1" }
const _hoisted_7 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_8 = { class: "flex flex-col gap-1" }
const _hoisted_9 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_10 = { class: "flex flex-col gap-1" }
const _hoisted_11 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_12 = { class: "flex-1 flex flex-col items-start gap-2" }
const _hoisted_13 = { class: "flex flex-col gap-1" }
const _hoisted_14 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_15 = { class: "flex flex-col gap-1" }
const _hoisted_16 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_17 = { class: "flex flex-col gap-1" }
const _hoisted_18 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-norma" }
const _hoisted_19 = { class: "flex flex-col gap-1" }
const _hoisted_20 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal" }

import { Project } from "@/types";


type Props = {
  project: Project;
  showDetails: boolean;
};

type Emits = {
  (event: "toggleDetails"): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnicalDetails',
  props: {
    project: {},
    showDetails: { type: Boolean }
  },
  emits: ["toggleDetails"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleDetails'))),
      class: "flex items-center justify-between p-3 cursor-pointer h-[54px]"
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-lg sm:text-2xl leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold" }, " Technical details ", -1)),
      _createElementVNode("img", {
        class: _normalizeClass(["cursor-pointer", _ctx.showDetails ? 'rotate-0' : 'rotate-180']),
        src: _imports_0,
        alt: ""
      }, null, 2)
    ]),
    (_ctx.showDetails)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " VNB ", -1)),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.project?.vnb ? _ctx.project?.vnb : "-"), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Wechselrichter ", -1)),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.project?.wechselrichter ? _ctx.project?.wechselrichter : "-"), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Speicher ", -1)),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.project?.speicher ? _ctx.project?.speicher : "-."), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Masskonzept ", -1)),
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.project?.masskonzept ? _ctx.project?.masskonzept : "-"), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " WB ", -1)),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.project?.wb ? _ctx.project?.wb : "-"), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Number of Modules ", -1)),
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.project?.numberOfModules ? _ctx.project?.numberOfModules : "-"), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Modul Type ", -1)),
              _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.project?.modulType ? _ctx.project?.modulType : "-"), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "text-sm font-sans font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " IMB Date ", -1)),
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.project?.ibnDate
                ? new Date(_ctx.project?.ibnDate).toLocaleDateString("de")
                : "-"), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})