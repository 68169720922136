<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";

type Props = {
  input: any;
};

type Emits = {
  (event: "openDeleteModal", selectedInput: any): void;
  (event: "openArchiveModal", selectedInput: any, isUpdate: boolean): void;
  (event: "closeUpdateModal"): void;
  (event: "closeDeleteModal"): void;
};

defineProps<Props>();
defineEmits<Emits>();

const selectedLanguage = ref("EN");

const onLanguageSwitchHandler = (lang: string) => {
  selectedLanguage.value = lang;
};
</script>

<template>
  <div class="bg-[#E5E4E2] p-3 rounded-primary-radius w-[300px]">
    <!-- Card header -->
    <div class="flex items-center justify-between min-h-5 gap-1">
      <h4
        class="text-primary-text-color text-base font-semibold tracking-[0.01rem]"
      >
        {{ input.name }}
      </h4>
      <div class="flex items-center gap-2">
        <div
          class="flex items-center gap-2"
          v-if="
            input &&
            (input.exampleImage ||
              (input.description &&
                (input.description.de || input.description.en)))
          "
        >
          <div class="w-6 h-6">
            <img
              @click="$emit('openDeleteModal', input)"
              class="cursor-pointer w-full h-full"
              src="@/assets/example-archive-trash-icon.svg"
              alt=""
            />
          </div>
          <div class="w-6 h-6">
            <img
              @click="$emit('openArchiveModal', input, true)"
              class="cursor-pointer w-full h-full"
              src="@/assets/pencil-icon.svg"
              alt=""
            />
          </div>
        </div>
        <div v-else>
          <div class="w-6 h-6">
            <img
              @click="$emit('openArchiveModal', input, false)"
              class="cursor-pointer w-full h-full"
              src="@/assets/edit-plus-icon.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Card image -->
    <div
      v-if="
        input &&
        (input.exampleImage ||
          (input.description && (input.description.de || input.description.en)))
      "
    >
      <div class="mt-3 mb-5 w-[276px] h-[297px] rounded-primary-radius">
        <img
          v-if="input.exampleImage"
          class="w-full h-full object-cover rounded-primary-radius"
          :src="input.exampleImage"
          alt="Image"
        />
      </div>
      <!-- Card language block -->
      <div
        class="bg-white rounded-primary-radius p-3 shadow-primary-box-shadow"
      >
        <!-- Header -->
        <div class="flex items-center gap-3">
          <div
            @click="onLanguageSwitchHandler('EN')"
            class="flex items-center gap-1 px-2 py-1 rounded-primary-radius cursor-pointer"
            :class="
              selectedLanguage === 'EN' ? 'bg-[#CBDCE9]' : 'bg-transparent'
            "
          >
            <img
              :src="
                selectedLanguage === 'EN'
                  ? require('@/assets/dark-earth-icon.svg')
                  : require('@/assets/light-earth-icon.svg')
              "
            />
            <p
              class="text-sm font-normal text-primary-text-color"
              :class="
                selectedLanguage === 'EN'
                  ? 'text-primary-text-color'
                  : 'text-[#898790]'
              "
            >
              EN
            </p>
          </div>
          <div
            @click="onLanguageSwitchHandler('DE')"
            class="flex items-center px-2 py-1 gap-1 rounded-primary-radius cursor-pointer"
            :class="
              selectedLanguage === 'DE' ? 'bg-[#CBDCE9]' : 'bg-transparent'
            "
          >
            <img
              :src="
                selectedLanguage === 'DE'
                  ? require('@/assets/dark-earth-icon.svg')
                  : require('@/assets/light-earth-icon.svg')
              "
              alt="Earth icon"
            />
            <p
              class="text-sm font-normal"
              :class="
                selectedLanguage === 'DE'
                  ? 'text-primary-text-color'
                  : 'text-[#898790]'
              "
            >
              DE
            </p>
          </div>
        </div>
        <!-- Content -->
        <div class="p-3 rounded-primary-radius bg-[#EEF3F8] mt-4">
          <p
            v-if="selectedLanguage === 'EN'"
            class="text-base leading-[26px] tracking-[0.01rem] font-normal text-primary-text-color"
          >
            {{ input ? input.description?.en : "" }}
          </p>
          <p
            v-if="selectedLanguage === 'DE'"
            class="text-base leading-[26px] tracking-[0.01rem] font-normal text-primary-text-color"
          >
            {{ input ? input.description?.de : "" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
