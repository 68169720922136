import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2 bg-[#DFDEDC] p-2 min-w-[230px] mt-5 rounded-lg" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "w-4 h-4 bg-green-200 border-2 border-black rounded-full"
}
const _hoisted_4 = {
  key: 2,
  class: "w-4 h-4 bg-[#ED857F] opacity-0 animate-fade-in-out rounded-full",
  style: {"animation-duration":"1s"}
}
const _hoisted_5 = {
  key: 3,
  class: "w-4 h-4 bg-transparent border-2 border-black rounded-full"
}
const _hoisted_6 = { class: "flex items-center ml-auto hover:cursor-pointer" }
const _hoisted_7 = {
  key: 0,
  width: "14",
  height: "13",
  viewBox: "0 0 14 13",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_8 = {
  key: 1,
  width: "14",
  height: "13",
  viewBox: "0 0 14 13",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "flex flex-col gap-1 max-h-[500px] overflow-y-auto scrollable-container"
}
const _hoisted_11 = {
  key: 0,
  class: "flex gap-2 items-center"
}
const _hoisted_12 = ["disabled", "onClick"]
const _hoisted_13 = {
  key: 1,
  class: "flex gap-2 items-center"
}
const _hoisted_14 = { class: "rounded-[3px] bg-[#FCD3AC] flex justify-center px-2 py-1 text-base select-none" }
const _hoisted_15 = ["disabled", "onClick"]
const _hoisted_16 = {
  key: 2,
  class: "flex gap-2 items-center"
}
const _hoisted_17 = { class: "rounded-[3px] bg-[#FDB5B1] flex justify-center px-2 py-1 text-base select-none" }
const _hoisted_18 = ["disabled", "onClick"]
const _hoisted_19 = { class: "flex items-center gap-2" }
const _hoisted_20 = { class: "flex items-center gap-1" }
const _hoisted_21 = { class: "text-sm text-[#898790] leading-[20px] text-nowrap select-none" }
const _hoisted_22 = {
  key: 0,
  class: "flex items-center gap-1"
}
const _hoisted_23 = { class: "text-sm text-[#898790] leading-[20px] overflow-hidden select-none" }
const _hoisted_24 = {
  key: 1,
  class: "bg-[#F9F8F6] p-2 rounded-lg flex flex-col gap-2"
}
const _hoisted_25 = {
  key: 0,
  class: "flex gap-2 items-center"
}
const _hoisted_26 = { class: "rounded-[3px] bg-[#FCD3AC] flex justify-center px-2 py-1 text-base select-none" }
const _hoisted_27 = {
  key: 1,
  class: "flex gap-2 items-center"
}
const _hoisted_28 = { class: "rounded-[3px] bg-[#FDB5B1] flex justify-center px-2 py-1 text-base select-none" }
const _hoisted_29 = {
  key: 2,
  class: "flex gap-2 items-center"
}
const _hoisted_30 = { class: "rounded-[3px] bg-green-200 flex justify-center px-2 py-1 text-base select-none" }
const _hoisted_31 = {
  key: 1,
  class: "bg-[#F9F8F6] p-2 rounded-lg flex"
}

import client from "@/utils/axios";
import { errorToast, successToast } from "@/utils/custom-toast";
import { computed, ref } from "vue";
type Props = {
  batches: Array<any>;
  workers: Array<any>;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SyncImageWidget',
  props: {
    batches: {},
    workers: {}
  },
  setup(__props: any) {

const props = __props;
const isSyncDetailOpen = ref(false);
const isRefreshLoading = ref(false);

const batchesByWorkers = computed(() => {
  let workersOnBatches = [
    ...new Set(props.batches.flatMap((item: any) => item.workers)),
  ];

  let workersFullInfo = props.workers.filter((element: any) =>
    workersOnBatches.includes(element.id)
  );

  let tempBatches = workersFullInfo.map((item: any) => {
    let batches = props.batches.filter((batch: any) =>
      batch.workers.includes(item.id)
    );

    let imagesFromAllBatches = batches.flatMap((item: any) => item.images);

    let imagesFromAllBatchesWithSpeed = imagesFromAllBatches.filter(
      (image: any) => image.internet_speed
    );

    const sortedImages = imagesFromAllBatchesWithSpeed
      ? imagesFromAllBatchesWithSpeed.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      : [];
    return {
      worker: item,
      internet_speed:
        sortedImages && sortedImages.length > 0
          ? sortedImages[0].internet_speed
          : "No data",
      images: imagesFromAllBatches,
    };
  });

  return tempBatches;
});

const allImagesSyncCount = computed(() => {
  let pendingImages = 0;
  let failedImages = 0;

  for (let index = 0; index < props.batches.length; index++) {
    const batch = props.batches[index];
    pendingImages += batch.images.filter(
      (item: any) => item.status === "pending"
    ).length;

    failedImages += batch.images.filter(
      (item: any) => item.status === "failed"
    ).length;
  }
  return {
    pendingImages: pendingImages,
    failedImages: failedImages,
  };
});

const allBatchesFinishedWithSuccess = computed(() => {
  for (let index = 0; index < props.batches.length; index++) {
    const batch = props.batches[index];
    let batchImagesNotSuccess = batch.images.filter(
      (image: any) => image.status !== "success"
    ).length;
    if (batchImagesNotSuccess > 0) {
      return false;
    }
  }
  return true;
});

const refreshImages = async (batch: any) => {
  isRefreshLoading.value = true;
  try {
    const resp = await client.post(`/send-images-again`, {
      workersOnBatch: [batch.worker?.id],
    });

    if (resp.status === 200) {
      successToast("Successfully created request for refresh of images.");
    }
  } catch (error: any) {
    errorToast(error.message);
  }

  isRefreshLoading.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isSyncDetailOpen.value = !isSyncDetailOpen.value)),
      class: "hover:cursor-pointer flex items-center gap-2"
    }, [
      _createElementVNode("div", null, [
        (
            allImagesSyncCount.value.pendingImages === 0 &&
            allImagesSyncCount.value.failedImages !== 0
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
              _createElementVNode("svg", {
                width: "14",
                height: "13",
                viewBox: "0 0 14 13",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  d: "M7 4.50012V7.16679M1.91927 8.63332C1.31298 9.68345 1.00992 10.2087 1.05521 10.6396C1.09471 11.0154 1.29195 11.3568 1.59766 11.5789C1.94802 11.8335 2.55394 11.8335 3.76569 11.8335H10.2343C11.4461 11.8335 12.0519 11.8335 12.4023 11.5789C12.708 11.3568 12.9053 11.0154 12.9448 10.6396C12.9901 10.2087 12.6871 9.68345 12.0808 8.63332L8.84766 3.03332C8.24137 1.9832 7.93809 1.45823 7.54232 1.28202C7.19711 1.12832 6.80267 1.12832 6.45746 1.28202C6.06185 1.45816 5.75876 1.98313 5.15298 3.03238L1.91927 8.63332ZM7.03385 9.16679V9.23345L6.9668 9.23358V9.16679H7.03385Z",
                  stroke: "#393840",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1)
            ])))
          : _createCommentVNode("", true),
        (
            _ctx.batches.length > 0 &&
            allImagesSyncCount.value.pendingImages === 0 &&
            allImagesSyncCount.value.failedImages === 0
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        (allImagesSyncCount.value.pendingImages > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.batches.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "text-sm leading-4 font-medium" }, "Synchronisation", -1)),
      _createElementVNode("div", _hoisted_6, [
        (!isSyncDetailOpen.value)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[2] || (_cache[2] = [
              _createElementVNode("path", {
                d: "M5.0013 11.8334V1.16675M5.0013 11.8334H10.2034C10.9486 11.8334 11.3213 11.8334 11.6062 11.6882C11.8571 11.5604 12.0616 11.3559 12.1895 11.105C12.3346 10.8201 12.3346 10.4474 12.3346 9.70213V3.29802C12.3346 2.55274 12.3346 2.17955 12.1895 1.89461C12.0616 1.64373 11.8571 1.4399 11.6062 1.31207C11.321 1.16675 10.9482 1.16675 10.2014 1.16675H5.0013M5.0013 11.8334H3.79925C3.05397 11.8334 2.68077 11.8334 2.39583 11.6882C2.14495 11.5604 1.94112 11.3559 1.81329 11.105C1.66797 10.8198 1.66797 10.4469 1.66797 9.70021V3.30021C1.66797 2.55347 1.66797 2.17983 1.81329 1.89461C1.94112 1.64373 2.14495 1.4399 2.39583 1.31207C2.68105 1.16675 3.0547 1.16675 3.80143 1.16675H5.0013",
                stroke: "#393840",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (isSyncDetailOpen.value)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[3] || (_cache[3] = [
              _createElementVNode("path", {
                d: "M9.0013 1.16675L9.0013 11.8334M9.0013 1.16675H3.80143C3.0547 1.16675 2.68105 1.16675 2.39583 1.31207C2.14495 1.4399 1.94112 1.64373 1.81329 1.89461C1.66797 2.17983 1.66797 2.55348 1.66797 3.30021V9.70021C1.66797 10.4469 1.66797 10.8198 1.81329 11.105C1.94112 11.3559 2.14495 11.5604 2.39583 11.6882C2.68077 11.8334 3.05397 11.8334 3.79926 11.8334L9.0013 11.8334M9.0013 1.16675H10.2014C10.9482 1.16675 11.321 1.16675 11.6062 1.31207C11.8571 1.4399 12.0616 1.64373 12.1895 1.89461C12.3346 2.17955 12.3346 2.55275 12.3346 3.29802L12.3346 9.70212C12.3346 10.4474 12.3346 10.8201 12.1895 11.105C12.0616 11.3559 11.8571 11.5604 11.6062 11.6882C11.3213 11.8334 10.9486 11.8334 10.2034 11.8334H9.0013",
                stroke: "#393840",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    (batchesByWorkers.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (isSyncDetailOpen.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(batchesByWorkers.value, (batch) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: batch.worker,
                    class: "bg-[#F9F8F6] p-2 rounded-lg flex flex-col gap-2"
                  }, [
                    (batch.images.filter((item) => item.status === 'pending' || item.status === 'failed').length === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "py-1 text-sm leading-[120%] select-none" }, "Device synced", -1)),
                          _createElementVNode("button", {
                            disabled: isRefreshLoading.value,
                            class: _normalizeClass(["h-4 w-4 ml-auto", `${isRefreshLoading.value ? 'cursor-wait' : 'cursor-pointer'}`]),
                            onClick: ($event: any) => (refreshImages(batch))
                          }, [
                            (_openBlock(), _createElementBlock("svg", {
                              width: "12",
                              height: "14",
                              viewBox: "0 0 14 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                              class: _normalizeClass(`${isRefreshLoading.value ? 'animate-spin' : ''}`)
                            }, _cache[5] || (_cache[5] = [
                              _createElementVNode("path", {
                                d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
                                stroke: "#393840",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }, null, -1)
                            ]), 2))
                          ], 10, _hoisted_12)
                        ]))
                      : _createCommentVNode("", true),
                    (batch.images.filter((item) => item.status === 'pending').length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("p", _hoisted_14, _toDisplayString(batch.images.filter((item) => item.status === "pending")
                  .length), 1),
                          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-sm leading-[120%] select-none" }, "Pending images", -1)),
                          _createElementVNode("button", {
                            disabled: isRefreshLoading.value,
                            class: _normalizeClass(["h-4 w-4 ml-auto", `${isRefreshLoading.value ? 'cursor-wait' : 'cursor-pointer'}`]),
                            onClick: ($event: any) => (refreshImages(batch))
                          }, [
                            (_openBlock(), _createElementBlock("svg", {
                              width: "12",
                              height: "14",
                              viewBox: "0 0 14 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                              class: _normalizeClass(`${isRefreshLoading.value ? 'animate-spin' : ''}`)
                            }, _cache[7] || (_cache[7] = [
                              _createElementVNode("path", {
                                d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
                                stroke: "#393840",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }, null, -1)
                            ]), 2))
                          ], 10, _hoisted_15)
                        ]))
                      : _createCommentVNode("", true),
                    (batch.images.filter((item) => item.status === 'failed').length > 0 && batch.images.filter((item) => item.status === 'pending').length === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createElementVNode("p", _hoisted_17, _toDisplayString(batch.images.filter((item) => item.status === "failed")
                  .length), 1),
                          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-sm leading-[120%] select-none" }, "Failed images", -1)),
                          _createElementVNode("button", {
                            disabled: isRefreshLoading.value,
                            class: _normalizeClass(["h-4 w-4 ml-auto", `${isRefreshLoading.value ? 'cursor-wait' : 'cursor-pointer'}`]),
                            onClick: ($event: any) => (refreshImages(batch))
                          }, [
                            (_openBlock(), _createElementBlock("svg", {
                              width: "12",
                              height: "14",
                              viewBox: "0 0 14 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                              class: _normalizeClass(`${isRefreshLoading.value ? 'animate-spin' : ''}`)
                            }, _cache[9] || (_cache[9] = [
                              _createElementVNode("path", {
                                d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
                                stroke: "#393840",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }, null, -1)
                            ]), 2))
                          ], 10, _hoisted_18)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "w-4 h-4 flex items-center" }, [
                          _createElementVNode("svg", {
                            width: "16",
                            height: "16",
                            viewBox: "0 0 16 16",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M5.5612 9.72645C5.87176 9.39341 6.24726 9.12754 6.66455 8.94523C7.08183 8.76292 7.53238 8.66817 7.98774 8.66659C8.44311 8.665 8.89392 8.75677 9.31246 8.93615C9.73101 9.11554 10.1085 9.37873 10.4214 9.70959M4.09896 7.69587C4.59476 7.16419 5.19403 6.73948 5.85996 6.44784C6.52588 6.15619 7.24454 6.00375 7.97152 5.99992M2.14844 5.87758C2.89387 5.07821 3.79519 4.44006 4.7968 4.00252C5.79841 3.56498 6.8792 3.33697 7.9722 3.33325M10.666 5.9999L11.9993 4.66657M11.9993 4.66657L13.3327 3.33325M11.9993 4.66657L10.666 3.33325M11.9993 4.66657L13.3327 5.9999M7.99935 12.6666C7.63116 12.6666 7.33268 12.3681 7.33268 11.9999C7.33268 11.6317 7.63116 11.3333 7.99935 11.3333C8.36754 11.3333 8.66602 11.6317 8.66602 11.9999C8.66602 12.3681 8.36754 12.6666 7.99935 12.6666Z",
                              stroke: "#898790",
                              "stroke-width": "2",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            })
                          ])
                        ], -1)),
                        _createElementVNode("p", _hoisted_21, _toDisplayString(batch ? batch.internet_speed : ""), 1)
                      ]),
                      (batch && batch.worker)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "w-4 h-4 flex items-center" }, [
                              _createElementVNode("svg", {
                                width: "10",
                                height: "14",
                                viewBox: "0 0 10 14",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, [
                                _createElementVNode("path", {
                                  d: "M1.66797 3.13346V10.8668C1.66797 11.6135 1.66797 11.9867 1.81329 12.2719C1.94112 12.5228 2.14495 12.727 2.39583 12.8548C2.68077 13 3.05396 13 3.79924 13H6.20336C6.94864 13 7.32129 13 7.60623 12.8548C7.85711 12.727 8.06162 12.5228 8.18945 12.2719C8.33464 11.987 8.33464 11.6143 8.33464 10.8691V3.13127C8.33464 2.386 8.33464 2.0128 8.18945 1.72786C8.06162 1.47698 7.85711 1.27316 7.60623 1.14532C7.32102 1 6.94817 1 6.20143 1H3.80143C3.0547 1 2.68105 1 2.39583 1.14532C2.14495 1.27316 1.94112 1.47698 1.81329 1.72786C1.66797 2.01308 1.66797 2.38673 1.66797 3.13346Z",
                                  stroke: "#898790",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                })
                              ])
                            ], -1)),
                            _createElementVNode("p", _hoisted_23, _toDisplayString(batch.worker ? batch.worker.fullname : ""), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (!isSyncDetailOpen.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                (allImagesSyncCount.value?.pendingImages > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("p", _hoisted_26, _toDisplayString(allImagesSyncCount.value?.pendingImages), 1),
                      _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-sm" }, "Pending images", -1))
                    ]))
                  : _createCommentVNode("", true),
                (
            allImagesSyncCount.value?.failedImages > 0 &&
            allImagesSyncCount.value?.pendingImages === 0
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("p", _hoisted_28, _toDisplayString(allImagesSyncCount.value?.failedImages), 1),
                      _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-sm" }, "Failed images", -1))
                    ]))
                  : _createCommentVNode("", true),
                (allBatchesFinishedWithSuccess.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                      _createElementVNode("p", _hoisted_30, _toDisplayString(batchesByWorkers.value.length), 1),
                      _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-sm" }, "Devices synced", -1))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.batches.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[16] || (_cache[16] = [
          _createElementVNode("div", { class: "flex gap-2 items-center" }, [
            _createElementVNode("p", { class: "rounded-[3px] bg-[#DFDEDC] flex justify-center px-2 py-1 text-base select-none" }, " 0 "),
            _createElementVNode("p", { class: "text-sm select-none" }, "Devices connected")
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})