<script setup lang="ts">
import client from "@/utils/axios";
import SelectMenu from "./SelectMenu.vue";
import { ref, defineEmits, defineProps, computed, watch, reactive } from "vue";
import { errorToast, successToast } from "@/utils/custom-toast";

type Props = {
  steps: Array<any>;
  termins?: Array<any>;
  isOpen?: boolean;
  project: string | string[];
  phase: number;
  step?: any;
  input?: any;
  headerTitle: string;
};

const props = defineProps<Props>();

type Emits = {
  (event: "toggleModal"): void;
  (event: "refresh"): void;
};

const emits = defineEmits<Emits>();

const selectedStep = ref<any>(
  props.step
    ? {
        id: props.step.id,
        name: props.step.name,
      }
    : null
);
const selectedInput = ref<any>(
  props.input
    ? {
        id: props.input.id,
        name: props.input.name,
      }
    : null
);
const selectedTermin = ref<any>(null);
const description = ref<any>("");
const files = ref([]);
const isLoading = ref(false);

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
  imagePreviews.value = files.value.map((file: any) =>
    URL.createObjectURL(file)
  );
};

const imagePreviews = ref<string[]>([]);

const steps = computed(() => {
  let stepsWithImage = props.steps.filter((item: any) =>
    item.inputs.some((input: any) => input.type === "image" && !item.parentId)
  );
  return [...stepsWithImage, { id: -1, name: "Other" }];
});

const inputs = computed(() => {
  let tempSelectedStep = props.steps.find(
    (item: any) => item.id === selectedStep.value?.id
  );

  return selectedStep.value && selectedStep.value?.id !== -1
    ? tempSelectedStep.inputs
        .filter((item: any) => item.type === "image" && !item.parentId)
        .map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        })
    : [];
});

const errors = reactive({
  selectedStep: "",
  selectedTermin: "",
});

const validate = () => {
  let valid = true;
  errors.selectedStep = "";
  errors.selectedTermin = "";

  if (!selectedStep.value) {
    errors.selectedStep = "Please select step!";
    valid = false;
  }

  if (!selectedTermin.value) {
    errors.selectedTermin = "Please select step!";
    valid = false;
  }

  return valid;
};

const onSubmit = async () => {
  if (!validate()) return;

  isLoading.value = true;

  const formData = new FormData();
  formData.append("isNote", selectedStep.value.id === -1 ? "true" : "false");
  formData.append("terminId", selectedTermin.value.id);

  if (selectedStep.value.id === -1) {
    formData.append("description", description.value);
  } else {
    formData.append("stepId", selectedStep.value.id);
    formData.append("inputId", selectedInput.value.id);
  }

  files.value.forEach((file, index) => {
    formData.append(`files[${index}]`, file);
  });
  try {
    await client.post(
      `/projects/${props.project}/phase/${props.phase}/add-picture`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    isLoading.value = false;
    successToast("Successfully added images.");
    emits("refresh");
    emits("toggleModal");
  } catch (error: any) {
    errorToast(error);
  }
};

watch(
  () => selectedStep.value,
  () => {
    if (!props.input) {
      selectedInput.value = null;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="fixed overflow-y-auto inset-0 bg-black bg-opacity-80 justify-center items-center z-50 transition-opacity duration-300 ease-in-out"
    :class="`${isOpen ? 'flex' : 'hidden'}`"
  >
    <div
      class="absolute overflow-y-auto top-5 w-[630px] p-6 mx-3 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg max-w-md"
    >
      <div
        class="flex items-center justify-between border-b border-gray-300 pb-3"
      >
        <h4
          class="w-11/12 text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
        >
          {{ headerTitle }}
        </h4>

        <button
          class="w-1/12 flex justify-center"
          @click="emits('toggleModal')"
        >
          <img src="@/assets/close-icon-2.svg" alt="Close icon" />
        </button>
      </div>
      <!-- Form -->
      <form @submit.prevent="onSubmit">
        <!-- Selectmenu 1 -->
        <div v-if="!input && !step">
          <h4
            class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
          >
            Which installation step?
          </h4>
          <div>
            <SelectMenu
              v-model="selectedStep"
              :is-error="!!errors.selectedStep"
              :options="steps.map((item:any)=>{
              return{
                id:item.id,
                name:item.name
              }
            })"
            />
            <p
              v-if="errors.selectedStep"
              class="text-sm text-red-400 font-medium mt-1"
            >
              {{ errors.selectedStep }}
            </p>
          </div>
        </div>
        <!-- Selectmenu 2 -->
        <div v-if="!input && !step && inputs && inputs.length > 0">
          <h4
            class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
          >
            Which description?
          </h4>
          <div>
            <SelectMenu v-model="selectedInput" :options="inputs" />
          </div>
        </div>
        <div v-if="selectedStep && selectedStep.id === -1" class="mt-3">
          <h4
            class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
          >
            Which description?
          </h4>
          <textarea
            rows="4"
            class="w-full outline-none text-base text-primary-text-color font-normal tracking-[0.01rem] bg-[#F9F8F6] p-3 border border-[#E3E1EA] rounded-[5px] resize-none placeholder:text-primary-text-color"
            placeholder="Description"
            v-model="description"
          ></textarea>
        </div>
        <!-- Selectmenu 3 -->
        <div>
          <h4
            class="text-base leading-[26px] tracking-[0.01rem] text-[#898790] font-medium pt-4"
          >
            On which appointment has the picture been taken?
          </h4>
          <div>
            <SelectMenu
              v-model="selectedTermin"
              :is-error="!!errors.selectedTermin"
              :options="termins?.map((item:any)=>{
                return {
                  id:item.id,
                  name:item.name
                }
              })"
            />
            <p
              v-if="errors.selectedTermin"
              class="text-sm text-red-400 font-medium mt-1"
            >
              {{ errors.selectedTermin }}
            </p>
          </div>
        </div>
        <!-- Attach files -->
        <div
          v-if="imagePreviews.length"
          class="flex w-full overflow-x-auto gap-1 mt-6 whitespace-nowrap scrollable-container"
        >
          <div
            v-for="(src, index) in imagePreviews"
            :key="index"
            class="shrink-0 w-[123px] h-[123px]"
          >
            <img
              :src="src"
              alt="Uploaded image"
              class="shrink-0 object-cover w-[123px] h-[123px] rounded-md"
            />
          </div>
        </div>
        <div class="flex items-center gap-2 mt-[36px]">
          <label
            for="file"
            class="px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108"
                stroke="#393840"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Attach Files
          </label>
          <input
            multiple
            class="hidden"
            type="file"
            id="file"
            @change="handleFileChange"
          />
        </div>
        <!-- Submit button -->
        <div class="flex justify-end">
          <div
            @click="onSubmit"
            class="mt-5 flex items-center justify-center py-3 px-[56px] text-base text-white bg-[#393840] tracking-[0.01rem] font-semibold outline-none rounded-primary-radius border-[2px] border-[#393840] cursor-pointer hover:bg-[#28272c] duration-200"
          >
            <div
              v-if="isLoading"
              class="w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
            ></div>
            <span v-else>Save</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.scrollable-container::-webkit-scrollbar {
  width: 2px;
  height: 6px; /* Adjust scrollbar width */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb hover color */
}
</style>
