import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'
import _imports_1 from '@/assets/example-archive-trash-icon.svg'


const _hoisted_1 = { class: "w-[628px] p-5 mx-3 bg-white rounded-primary-radius flex flex-col items-start" }
const _hoisted_2 = { class: "w-full flex items-center justify-between pb-[18px]" }
const _hoisted_3 = { class: "w-full flex flex-col gap-4 pt-3 border-t border-[#e3e1ea]" }
const _hoisted_4 = { class: "self-end flex items-center gap-3" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_7 = {
  key: 1,
  class: "flex"
}


type Emits = {
  (event: "closeModal"): void;
  (event: "onDelete"): void;
};

type Props = {
  isLoading: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteModal',
  props: {
    isLoading: { type: Boolean }
  },
  emits: ["closeModal", "onDelete"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "text-primary-text-color text-xl leading-8 tracking-[0.01rem] font-semibold" }, " Delete section ", -1)),
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('closeModal'))),
        class: "cursor-pointer h-[16px] w-[16px]",
        src: _imports_0,
        alt: ""
      })
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-xl leading-8 tracking-[0.01rem] text-primary-text-color font-normal pb-2" }, [
      _createTextVNode(" Are you sure you want to delete this content?"),
      _createElementVNode("br"),
      _createTextVNode(" This cannot be undone. ")
    ], -1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('closeModal'))),
          class: "text-base border-2 border-primary-text-color leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-[10px] rounded-primary-radius"
        }, " Cancel "),
        _createElementVNode("button", {
          disabled: _ctx.isLoading,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onDelete'))),
          class: "flex min-w-6 items-center gap-2 text-base border-2 border-transparent leading-5 tracking-[0.01rem] bg-[#F4B6B2] text-primary-text-color font-semibold p-[10px] rounded-primary-radius"
        }, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  class: "w-[18px] h-[18px]",
                  src: _imports_1,
                  alt: ""
                }, null, -1),
                _createElementVNode("p", null, "Delete content", -1)
              ])))
        ], 8, _hoisted_5)
      ])
    ])
  ]))
}
}

})