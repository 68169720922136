<script setup lang="ts">
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import AbnahmeView from "./AbnahmeView.vue";
import QcView from "./QcView.vue";
import RegularView from "./RegularView.vue";
import { createClient } from "@supabase/supabase-js";
import { ImageBatch } from "@/types";

const route = useRoute();

const phaseData = ref();
const phaseStatusName = ref("");
const isLoading = ref(false);

const selectedPhase = ref();

let supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL || "",
  process.env.VUE_APP_SUPABASE_API_KEY || ""
);

let batches = ref<Array<ImageBatch>>([]);

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

// Fetch data
const getPhaseDetails = async () => {
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;
  isLoading.value = true;
  try {
    const response = await client.get(
      `/projects/${projectId}/phase/${phaseId}`
    );
    phaseData.value = response.data;
    batches.value = response.data.batches;

    let tempPhase = response.data.phases.find(
      (item: any) => Number(item.phaseId) === Number(phaseId)
    );

    phaseStatusName.value = tempPhase ? tempPhase.phaseStatus.name : "";
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const handleImagesChanges = (payload: any) => {
  let newBatchImage = payload.new;
  if (payload.eventType === "INSERT") {
    let tempBatch: any = batches.value.find(
      (item: any) => item.id === newBatchImage.images_batch
    );
    if (tempBatch) {
      tempBatch.images.push(newBatchImage);
    }
  }
  if (payload.eventType === "UPDATE") {
    let tempBatch: any = batches.value.find(
      (item: any) => item.id === newBatchImage.images_batch
    );
    if (tempBatch) {
      let tempImage = tempBatch.images.find(
        (image: any) => image.id === newBatchImage.id
      );
      if (tempImage) {
        tempImage.status = newBatchImage.status;
        tempImage.internet_speed = newBatchImage.internet_speed;
      }
    }
  }
};

const handleImagesBatchChanges = (payload: any) => {
  if (payload.eventType === "INSERT") {
    let newBatch = payload.new;
    newBatch.images = [];
    batches.value.push(newBatch);
  }
};

watch(
  () => route.params.phaseId, // Watch for the 'id' param change in the route
  async (newId, oldId) => {
    if (newId !== oldId) {
      await getPhaseDetails();

      let currentPhaseProgress = phaseData.value.phases.find(
        (item: any) => item.phaseId === Number(newId)
      );

      if (currentPhaseProgress) {
        supabase
          .channel("images_batch")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: "installation_images_batch",
              filter: `phase_progress_id=eq.${currentPhaseProgress?.phaseProgressId}`,
            },
            handleImagesBatchChanges
          )
          .subscribe();
        supabase
          .channel("images")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: "installation_images",
              filter: `phase_progress_id=eq.${currentPhaseProgress?.phaseProgressId}`,
            },
            handleImagesChanges
          )
          .subscribe();
      } else {
        console.error("Cannot find phase progress id.");
      }
      selectedPhase.value = newId; // Trigger the API call when the route param changes
    }
  },
  { immediate: true } // Immediately run on component mount
);

onUnmounted(() => {
  supabase.removeAllChannels();
});
</script>

<template>
  <MainLayout>
    <div class="flex">
      <div class="">
        <MainSidebar
          :is-loading="isLoading"
          :phase-progress="phaseData?.phases"
          :phase-steps="phaseData?.steps"
          :selected-phase="Number(selectedPhase)"
          @on-phase-select="onPhaseSelect"
          :is-link="true"
        />
      </div>
      <div class="w-[calc(100%-220px)]">
        <div class="flex w-full" v-if="phaseData">
          <div class="flex w-full" v-if="phaseStatusName === 'abnahme'">
            <AbnahmeView
              :batches="batches"
              :phase-id="Number(route.params.phaseId)"
              :project-id="route.params.id + ''"
              :phase-details="phaseData"
              @refresh-data="getPhaseDetails"
            />
          </div>
          <div class="flex w-full" v-if="phaseStatusName === 'qc'">
            <QcView
              :batches="batches"
              :phase-id="Number(route.params.phaseId)"
              :project-id="route.params.id + ''"
              :phase-details="phaseData"
              @refresh-data="getPhaseDetails"
            />
          </div>

          <div
            class="flex w-full"
            v-if="phaseStatusName !== 'abnahme' && phaseStatusName !== 'qc'"
          >
            <RegularView
              :batches="batches"
              :phase-id="Number(route.params.phaseId)"
              :phase-details="phaseData"
              @refresh="getPhaseDetails"
            />
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
