<script setup lang="ts">
import CustomAccordion from "@/components/Re-usable/Phase/CustomAccordion.vue";
import { ref, defineProps, defineEmits, watch, onMounted } from "vue";
import TechnicalDetails from "@/components/Project/TechnicalDetails.vue";
import TerminFilter from "@/components/Re-usable/TerminFilter.vue";
import ProjectMap from "@/components/Project/ProjectMap.vue";
import client from "@/utils/axios";
import { useRoute } from "vue-router";
import AddImageModal from "@/components/Re-usable/AddImageModal.vue";
import CheckListBlock from "@/components/Re-usable/Phase/CheckListBlock.vue";
import MissingImageBlock from "@/components/Re-usable/Phase/MissingImageBlock.vue";
import GroupInput from "@/components/Re-usable/Phase/GroupInput.vue";
import InputFieldsBlock from "@/components/Re-usable/Phase/InputFieldsBlock.vue";
import SlideImagesBlock from "@/components/Re-usable/Phase/SlideImagesBlock.vue";
import IssueModal from "@/components/Project/Appointment/Modal/IssueModal.vue";
import NoteModal from "@/components/Project/Appointment/Modal/NoteModal.vue";
import InputModal from "@/components/Re-usable/InputModal.vue";
import { errorToast, successToast } from "@/utils/custom-toast";
import SyncImageWidget from "@/components/PhaseDetails/SyncImageWidget.vue";

const route = useRoute();

type Props = {
  phaseDetails: any;
  phaseId: number;
  batches: Array<any>;
};

type Emits = {
  (event: "refresh"): void;
};

const intersectionOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.1,
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    // TODO: ability to mark on which step we are on
    if (entry.isIntersecting) {
      // extract ID from the entry.target.id
      // const id = `scroll-${entry.target.id}`;
      // // Update the URL with the ID
      // history.replaceState(null, "", `#${id}`);
      // router.replace({ hash: `#${id}` });
    }
  });
}, intersectionOptions);

onMounted(() => {
  const elements = document.querySelectorAll(".observe");
  elements.forEach((element) => {
    observer.observe(element);
  });
});

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const isTerminFilterOpen = ref(false);
const isMap = ref(false);
const isLoading = ref(false);
const selectedIssue = ref<any>(null);
const selectedNote = ref(null);
const isIssueModalOpen = ref(false);
const isNoteModalOpen = ref(false);
const isInputModalOpen = ref(false);
const isAddImageModalOpen = ref(false);
const showDetails = ref(false);
const selectedInput = ref<any>(null);
const selectedStep = ref<any>(null);

const generateReport = async () => {
  isLoading.value = true;
  successToast("Your report is being generated, please wait.");
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;
  try {
    const resp = await client.post(
      `/projects/${projectId}/phase/${phaseId}/generate-report`
    );

    if (resp && resp.status === 200) {
      successToast("Report successfully generated.");
    } else {
      errorToast("Something went wrong!");
    }
    isLoading.value = false;
  } catch (error) {
    errorToast("Something went wrong!");
  } finally {
    isLoading.value = false;
  }
};

const toggleProjectMap = () => {
  isMap.value = !isMap.value;
};

const selectedTermins = ref(
  props.phaseDetails.termins.map((item: any) => item.id)
);

const onFilterSubmit = (termins: Array<string>) => {
  selectedTermins.value = [...termins];
  isTerminFilterOpen.value = false;
};

const toggleModalHandler: any = (input: any) => {
  selectedInput.value = input;
  isInputModalOpen.value = !isInputModalOpen.value;
};
let scrollableContainer = ref<any>(null);

watch(
  () => route.hash, // Watch for the 'id' param change in the route
  () => {
    if (route.hash) {
      let hashId = route.hash.replace("#", "");
      const targetElement = document.getElementById(hashId);
      if (targetElement && scrollableContainer.value) {
        const topPosition = targetElement.offsetTop - 200;

        // Use scrollTo to scroll to the adjusted position
        scrollableContainer.value.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }
  },
  { immediate: true } // Immediately run on component mount
);

const path = route.path;
const projectId = path.split("/")[2];
</script>

<template>
  <IssueModal
    v-if="selectedIssue && isIssueModalOpen"
    :issue="selectedIssue"
    :options="props.phaseDetails.issueTypes"
    :is-open="isIssueModalOpen"
    :issue-block-types="props.phaseDetails.issueBlockTypes"
    :just-view="true"
    :header-title="selectedIssue ? selectedIssue.title : ''"
    @toggle-modal="
      isIssueModalOpen = false;
      selectedIssue = null;
    "
  />
  <NoteModal
    v-if="selectedNote && isNoteModalOpen"
    :note="selectedNote"
    :is-open="isNoteModalOpen"
    :just-view="true"
    header-title="Note"
    @toggle-modal="
      isNoteModalOpen = false;
      selectedNote = null;
    "
  />
  <InputModal
    v-if="isInputModalOpen && selectedInput"
    :input="selectedInput"
    :is-open="isInputModalOpen"
    :project="route.params.id"
    :phase="route.params.phaseId ? Number(route.params.phaseId) : -1"
    :termins="props.phaseDetails.termins"
    @refresh="emits('refresh')"
    @toggle-modal="
      () => {
        isInputModalOpen = false;
        selectedInput = null;
      }
    "
  />
  <AddImageModal
    v-if="isAddImageModalOpen && selectedInput"
    :steps="phaseDetails.steps"
    :termins="phaseDetails.termins"
    :is-open="isAddImageModalOpen"
    :project="route.params.id"
    :input="selectedInput"
    :step="selectedStep"
    :header-title="selectedInput ? selectedInput.name : ''"
    :phase="route.params.phaseId ? Number(route.params.phaseId) : -1"
    @toggle-modal="
      () => {
        isAddImageModalOpen = false;
        selectedInput = null;
        selectedStep = null;
      }
    "
    @refresh="emits('refresh')"
  />

  <div
    class="h-[calc(100vh-64px)] flex items-start gap-4 overflow-y-hidden relative w-full"
  >
    <div
      class="h-full xl:pr-0 overflow-y-auto max-w-[930px] mx-auto relative"
      ref="scrollableContainer"
      :class="`${isMap ? 'w-3/5' : 'w-full'}`"
    >
      <!-- Header -->
      <div
        class="flex flex-col w-full sticky top-0 z-20 bg-[#efeeec] pb-2"
        :class="`${showDetails ? 'h-[316px]' : 'h-[120px]'}`"
      >
        <div
          class="flex flex-col md:flex-row items-start md:items-center justify-between mb-3 h-[44px]"
        >
          <div class="flex items-center gap-[2px]">
            <div class="relative group">
              <RouterLink
                :to="`/projects/${projectId}`"
                class="relative text-primary-text-color text-xl leading-[26px] tracking-[0.01rem] font-medium after:absolute after:bottom-[2px] after:left-0 after:w-full after:h-[1px] after:bg-primary-text-color after:opacity-0 group-hover:after:opacity-100 after:transition-opacity after:duration-200"
              >
                {{ phaseDetails.project.name }}
              </RouterLink>
            </div>
            <img src="@/assets/chevron-right.svg" alt="Caret right" />
            <p
              class="text-xl leading-[26px] tracking-[0.01rem] text-[#616068] font-medium"
            >
              {{
                props.phaseDetails.phases.find(
                  (phase: any) => phase.phaseId === props.phaseId
                )?.phase
              }}
            </p>
          </div>
          <button
            :disabled="isLoading"
            @click="generateReport"
            class="flex items-center gap-2 text-sm text-white font-semibold leading-[17px] tracking-[0.01rem] py-[13.5px] px-2 bg-[#393840] rounded-primary-radius outline-none hover:bg-[#202022] duration-300"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="`${isLoading ? 'animate-spin' : ''}`"
            >
              <path
                d="M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Generate report
          </button>
        </div>
        <div>
          <TechnicalDetails
            :show-details="showDetails"
            :project="phaseDetails.project"
            @toggle-details="showDetails = !showDetails"
          />
        </div>
      </div>
      <!-- Main content -->
      <!-- Issues -->
      <div>
        <CustomAccordion
          :step-name="`Issues (${phaseDetails.issues.filter((item:any)=> selectedTermins.includes(item.terminId)).length})`"
        >
          <div
            v-for="issue in phaseDetails.issues.filter((item:any)=> selectedTermins.includes(item.terminId))"
            :key="issue.id"
            class="mt-3 flex flex-col gap-3 p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow cursor-pointer"
            @click="
              selectedIssue = issue;
              isIssueModalOpen = true;
            "
          >
            <div class="flex items-center justify-between">
              <h3
                class="w-4/5 text-lg leading-[20px] tracking-[0.01rem] text-primary-text-color font-semibold"
              >
                {{ issue.title }}
              </h3>
              <div class="flex items-center gap-3 w-1/5 justify-end text-right">
                <p>{{ issue.terminName }}</p>
              </div>
            </div>

            <!-- Date - workers -->
            <div class="flex items-center gap-4">
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3 cursor-pointer"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar"
                />
                <p class="text-sm text-[#898790] foont-medium">
                  {{
                    issue.createdAt
                      ? new Date(issue.createdAt).toLocaleDateString("DE")
                      : ""
                  }}
                </p>
              </div>
              <div class="flex items-center gap-1">
                <img class="w-3 h-3" src="@/assets/gray-user.svg" alt="User" />
                <p
                  v-for="(worker, index) in issue.workers"
                  :key="index"
                  class="text-sm text-[#898790] foont-medium"
                >
                  {{ worker
                  }}<span v-if="index < issue.workers.length - 1">,</span>
                </p>
              </div>
              <div class="flex gap-1 items-center">
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.64551 6.1877L5.66718 2.16603C6.86316 0.970048 8.80226 0.970048 9.99824 2.16603C11.1942 3.36201 11.1941 5.30118 9.99809 6.49716L5.35771 11.1376C4.56039 11.9349 3.26789 11.9348 2.47057 11.1374C1.67325 10.3401 1.67306 9.04754 2.47038 8.25022L7.11077 3.60984C7.50943 3.21117 8.15613 3.21117 8.55479 3.60984C8.95345 4.0085 8.95317 4.6547 8.55451 5.05336L4.53284 9.07503"
                    stroke="#A7A5AE"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="text-sm text-[#898790]">{{
                  issue.attachments?.length
                }}</span>
              </div>
            </div>
          </div>
        </CustomAccordion>
      </div>
      <!-- Notes -->
      <div>
        <CustomAccordion
          :step-name="`Notes (${phaseDetails.notes.filter((item:any)=> selectedTermins.includes(item.terminId)).length})`"
        >
          <div
            v-for="note in phaseDetails.notes.filter((item:any)=> selectedTermins.includes(item.terminId))"
            :key="note.id"
            class="mt-3 flex flex-col gap-3 p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow cursor-pointer"
            @click="
              selectedNote = note;
              isNoteModalOpen = true;
            "
          >
            <div>
              <div class="flex items-center justify-between">
                <h3
                  class="w-4/5 text-lg leading-[20px] tracking-[0.01rem] text-primary-text-color font-semibold"
                >
                  {{ note.title }}
                </h3>
                <div
                  class="flex w-1/5 items-center gap-3 justify-end text-right"
                >
                  <p>{{ note.terminName }}</p>
                </div>
              </div>
            </div>
            <!-- Date - workers -->
            <div class="flex items-center gap-4">
              <div class="flex items-center gap-1">
                <img
                  class="w-3 h-3 cursor-pointer"
                  src="@/assets/calendar-icon.svg"
                  alt="Calendar"
                />
                <p class="text-sm text-[#898790] foont-medium">
                  {{
                    note.createdAt
                      ? new Date(note.createdAt).toLocaleDateString("DE")
                      : ""
                  }}
                </p>
              </div>
              <div class="flex items-center gap-1">
                <img class="w-3 h-3" src="@/assets/gray-user.svg" alt="User" />
                <p
                  v-for="(worker, index) in note.workers"
                  :key="index"
                  class="text-sm text-[#898790] foont-medium"
                >
                  {{ worker
                  }}<span v-if="index < note.workers.length - 1">,</span>
                </p>
              </div>
              <div class="flex gap-1 items-center">
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.64551 6.1877L5.66718 2.16603C6.86316 0.970048 8.80226 0.970048 9.99824 2.16603C11.1942 3.36201 11.1941 5.30118 9.99809 6.49716L5.35771 11.1376C4.56039 11.9349 3.26789 11.9348 2.47057 11.1374C1.67325 10.3401 1.67306 9.04754 2.47038 8.25022L7.11077 3.60984C7.50943 3.21117 8.15613 3.21117 8.55479 3.60984C8.95345 4.0085 8.95317 4.6547 8.55451 5.05336L4.53284 9.07503"
                    stroke="#A7A5AE"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="text-sm text-[#898790]">{{
                  note.attachments?.length
                }}</span>
              </div>
            </div>
          </div>
        </CustomAccordion>
      </div>
      <div v-for="step in phaseDetails.steps" :key="step.id" class="mt-[36px]">
        <div :id="'step-' + step.id">
          <CustomAccordion
            :step-id="step.id"
            :step-name="step?.name"
            class="observe relative"
          >
            <div class="flex flex-col gap-3">
              <!-- Missing -->
              <div
                v-if="step.checklist.length > 0 
                        || step.inputs.some((item:any)=> item.type !== 'image' && !item.value)
                        || step.inputs.some((input: any) => input.type === 'image' && input.value?.length === 0)"
                class="bg-[#DFDEDC] rounded-primary-radius"
              >
                <h2
                  class="p-3 text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold bg-[#DFDEDC] opacity-1 sticky z-10 flex items-center"
                  :class="`${step ? 'pb-3' : 'pb-0'} ${
                    showDetails ? 'top-[378px]' : 'top-[189px]'
                  }`"
                >
                  Missing
                </h2>
                <div class="flex p-3 pt-0 gap-3">
                  <!-- Checkblock list -->
                  <div
                    v-if="step.checklist.length > 0"
                    class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                  >
                    <CheckListBlock :checklist="step.checklist" />
                  </div>
                  <!-- Images text -->
                  <div
                    v-if="step.inputs.some((input: any) => input.type === 'image' && input.value?.length === 0)"
                    class="w-full"
                  >
                    <MissingImageBlock
                      @set-selected-input="(input:any)=> {selectedInput = input; isAddImageModalOpen =true; selectedStep = step}"
                      :step="step"
                    />
                  </div>
                  <!-- Input fields -->
                  <div
                    v-if="step.inputs.some((item:any)=> item.type !== 'image' && item.type !== 'group' && !item.value)"
                    class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                  >
                    <InputFieldsBlock
                      @toggle-modal-handler="toggleModalHandler"
                      :step="step"
                    />
                  </div>
                </div>
              </div>
              <!-- Group inputs -->
              <div
                v-if="step.inputs.some((input: any) => input.type === 'group' && input.finishedInputs > 0)"
                class="flex flex-col gap-3"
              >
                <div
                  v-for="input in step.inputs.filter((input: any) => input.type === 'group' && input.finishedInputs > 0)"
                  :key="input.id"
                  class="bg-[#DFDEDC] rounded-primary-radius flex flex-col gap-2 relative"
                >
                  <GroupInput
                    :showDetails="showDetails"
                    :selectedTermins="selectedTermins"
                    :input="input"
                    :step="step"
                    :child-inputs="step.inputs.filter((item:any)=> item.parentId === input.id)"
                    :project="route.params.id"
                    :phaseDetails="phaseDetails"
                    :phase="
                      route.params.phaseId ? Number(route.params.phaseId) : -1
                    "
                    @refresh="emits('refresh')"
                  />
                </div>
              </div>
              <!--IMAGE -->
              <div
                v-if="step.inputs.some((input: any) => input.type === 'image' && input.parentId === null && input.value?.length > 0)"
                class="col-span-2"
              >
                <div
                  v-for="input in step.inputs.filter((input: any) => input.type === 'image' && input.parentId === null && input.value?.length > 0)"
                  :key="input.id"
                  class="w-full p-3 bg-[#DFDEDC] rounded-primary-radius mb-3 relative"
                >
                  <SlideImagesBlock
                    :is-group="false"
                    :showDetails="showDetails"
                    :selectedTermins="selectedTermins"
                    @add-image-modal-open="
                      () => {
                        selectedInput = input;
                        selectedStep = step;
                        isAddImageModalOpen = true;
                      }
                    "
                    :input="input"
                    :step="step"
                  />
                </div>
              </div>
              <!-- TEXT -->
              <div
                v-if="step.inputs.some((input: any) => input.type === 'text' && input.value && !input.parentId)"
                class="col-span-2 p-3 bg-[#DFDEDC] rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
              >
                <div
                  v-for="input in step.inputs.filter((input:any) => input.type === 'text' && input.value && !input.parentId && selectedTermins?.includes(input.terminId))"
                  :key="input.id"
                  class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                >
                  <!-- Top side -->
                  <div class="w-full flex items-center justify-between">
                    <div class="flex items-center gap-3">
                      <div class="flex items-center gap-1">
                        <img
                          class="w-3 h-3 cursor-pointer"
                          src="@/assets/calendar-icon.svg"
                          alt="Calendar"
                        />

                        <p class="text-sm text-[#898790] foont-medium">
                          {{
                            input.createdAt
                              ? new Date(input.createdAt).toLocaleDateString(
                                  "de"
                                )
                              : ""
                          }}
                        </p>
                      </div>
                      <div class="flex items-center gap-1">
                        <img
                          class="w-3 h-3"
                          src="@/assets/gray-user.svg"
                          alt="User"
                        />
                        <p
                          v-for="(worker, index) in input.workers"
                          :key="index"
                          class="text-sm text-[#898790] foont-medium"
                        >
                          {{ worker
                          }}<span v-if="index < input.workers.length - 1"
                            >,</span
                          >
                        </p>
                      </div>
                    </div>
                    <img
                      @click="toggleModalHandler(input)"
                      class="w-4 h-4 cursor-pointer"
                      src="@/assets/pencil-gray.svg"
                      alt="Pencil"
                    />
                  </div>
                  <!-- Content -->
                  <div class="mt-4">
                    <p class="text-base font-normal text-">
                      {{ input.name }}
                    </p>
                    <div class="p-3 rounded-primary-radius bg-[#e5ecf6] mt-2">
                      <p class="text-base font-normal text-">
                        {{ input.value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- NUMBER -->
              <div
                v-if="step.inputs.some((input: any) => input.type === 'number' && input.value && !input.parentId && selectedTermins?.includes(input.terminId))"
                class="flex gap-1 bg-[#DFDEDC] rounded-primary-radius shadow-primary-box-shadow p-3"
              >
                <div
                  v-for="input in step.inputs.filter((input:any) => input.type === 'number' && input.value && !input.parentId)"
                  :key="input.id"
                  class="p-3 w-1/3 bg-white rounded-primary-radius shadow-primary-box-shadow"
                >
                  <!-- Top side -->
                  <div class="w-full flex items-center justify-between">
                    <div class="flex items-center gap-3">
                      <div class="flex items-center gap-1">
                        <img
                          class="w-3 h-3 cursor-pointer"
                          src="@/assets/calendar-icon.svg"
                          alt="Calendar"
                        />

                        <p class="text-sm text-[#898790] foont-medium">
                          {{
                            input.createdAt
                              ? new Date(input.createdAt).toLocaleDateString(
                                  "de"
                                )
                              : ""
                          }}
                        </p>
                      </div>
                      <div class="flex items-center gap-1">
                        <img
                          class="w-3 h-3"
                          src="@/assets/gray-user.svg"
                          alt="User"
                        />
                        <p
                          v-for="(worker, index) in input.workers"
                          :key="index"
                          class="text-sm text-[#898790] foont-medium"
                        >
                          {{ worker
                          }}<span v-if="index < input.workers.length - 1"
                            >,</span
                          >
                        </p>
                      </div>
                    </div>
                    <img
                      @click="toggleModalHandler(input)"
                      class="w-4 h-4 cursor-pointer"
                      src="@/assets/pencil-gray.svg"
                      alt="Pencil"
                    />
                  </div>
                  <!-- Content -->
                  <div class="mt-4 flex gap-2">
                    <p class="text-base font-normal grow">
                      {{ input.name }}
                    </p>
                    <div
                      class="px-3 py-[1px] rounded-primary-radius bg-[#e5ecf6] mt-2 h-fit w-fit"
                    >
                      <p class="text-base font-normal h-fit w-fit">
                        {{ input.value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SELECT -->
              <div
                v-if="step.inputs.some((input: any) => input.type === 'select' && input.value && !input.parentId && selectedTermins?.includes(input.terminId))"
                class="col-span-2 p-3 bg-[#DFDEDC] rounded-primary-radius shadow-primary-box-shadow flex flex-col gap-2"
              >
                <div
                  v-for="input in step.inputs.filter((input:any) => input.type === 'select' && input.value && !input.parentId)"
                  :key="input.id"
                  class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                >
                  <!-- Top side -->
                  <div class="w-full flex items-center justify-between">
                    <div class="flex items-center gap-3">
                      <div class="flex items-center gap-1">
                        <img
                          class="w-3 h-3 cursor-pointer"
                          src="@/assets/calendar-icon.svg"
                          alt="Calendar"
                        />

                        <p class="text-sm text-[#898790] foont-medium">
                          {{
                            input.createdAt
                              ? new Date(input.createdAt).toLocaleDateString(
                                  "de"
                                )
                              : ""
                          }}
                        </p>
                      </div>
                      <div class="flex items-center gap-1">
                        <img
                          class="w-3 h-3"
                          src="@/assets/gray-user.svg"
                          alt="User"
                        />
                        <p
                          v-for="(worker, index) in input.workers"
                          :key="index"
                          class="text-sm text-[#898790] foont-medium"
                        >
                          {{ worker
                          }}<span v-if="index < input.workers.length - 1"
                            >,</span
                          >
                        </p>
                      </div>
                    </div>
                    <img
                      @click="toggleModalHandler(input)"
                      class="w-4 h-4 cursor-pointer"
                      src="@/assets/pencil-gray.svg"
                      alt="Pencil"
                    />
                  </div>
                  <!-- Content -->
                  <div class="mt-4">
                    <p class="text-base font-normal">
                      {{ input.name }}
                    </p>
                    <div class="p-3 rounded-primary-radius bg-[#e5ecf6] mt-2">
                      <p class="text-base font-normal text-">
                        {{ input.value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomAccordion>
        </div>
      </div>
    </div>
    <!-- Right block -->
    <div class="flex flex-col ml-auto" :class="`${isMap ? 'w-2/5' : 'w-fit'}`">
      <div
        v-if="!isMap"
        class="flex items-center xl:gap-3 2xl:gap-4 justify-end z-[500]"
        :class="isMap ? 'flex-row' : 'flex-col xl:flex-row'"
      >
        <div
          v-if="phaseDetails.phases.find(
                  (phase: any) => phase.phaseId === phaseId
                )?.salesforceId"
          class="flex items-center justify-center"
        >
          <a
            :href="`https://smalt.lightning.force.com/lightning/r/Phase__c/${phaseDetails.phases.find(
                  (phase: any) => phase.phaseId === phaseId
                )?.salesforceId}/view`"
            target="_blank"
            class="w-[36px] h-[24px]"
          >
            <img
              class="w-full h-full cursor-pointer"
              src="@/assets/salesforce.svg"
              alt=""
          /></a>
        </div>
        <!-- Termin filter -->
        <div
          class="relative flex items-center justify-center w-[36px] h-[36px] rounded-full hover:bg-white"
          :class="isTerminFilterOpen === true ? 'bg-white' : 'bg-transparent'"
        >
          <img
            @click="isTerminFilterOpen = !isTerminFilterOpen"
            class="w-[24px] h-[24px] object-contain cursor-pointer"
            src="@/assets/filter-termin-icon.svg"
            alt=""
          />

          <TerminFilter
            :is-open="isTerminFilterOpen"
            :is-baumappe-open="props.phaseDetails.project.baummape === null"
            :project="phaseDetails"
            @close-termin-filter="isTerminFilterOpen = false"
            @on-filter-submit="onFilterSubmit"
          />
        </div>
        <!-- Add Image -->
        <div
          class="relative flex items-center justify-center w-[36px] h-[36px] rounded-full hover:bg-white"
          :class="
            isAddImageModalOpen === true && !selectedInput
              ? 'bg-white'
              : 'bg-transparent'
          "
        >
          <img
            @click="isAddImageModalOpen = true"
            class="w-[24px] h-[24px] object-contain cursor-pointer"
            src="@/assets/image-icon.svg"
            alt=""
          />
          <div
            v-if="isAddImageModalOpen === true && !selectedInput"
            class="w-full h-screen fixed top-0 right-0 bottom-0 left-0 z-[777] bg-black/25"
          >
            <div
              class="absolute top-[102px] xl:top-[65px] z-[777]"
              :class="
                props.phaseDetails.project.baummape === null
                  ? 'right-[24px]'
                  : 'right-[24px] xl:right-[70px]'
              "
            >
              <AddImageModal
                v-if="isAddImageModalOpen && !selectedInput"
                :steps="phaseDetails.steps"
                :termins="phaseDetails.termins"
                :is-open="isAddImageModalOpen"
                :project="route.params.id"
                header-title="Add picture"
                :phase="
                  route.params.phaseId ? Number(route.params.phaseId) : -1
                "
                @toggle-modal="
                  isAddImageModalOpen = false;
                  selectedInput = null;
                  selectedStep = null;
                "
                @refresh="emits('refresh')"
              />
            </div>
          </div>
        </div>
        <div
          v-if="props.phaseDetails.project.baummape !== null"
          @click="toggleProjectMap"
          class="col-start-2 col-span-1 xl:col-start-auto xl:col-span-1 relative flex items-center justify-center w-[36px] h-[36px] rounded-full hover:bg-white"
          :class="isMap ? 'bg-white' : 'bg-transparent'"
        >
          <img
            class="w-[24px] h-[24px] object-contain cursor-pointer"
            :class="
              props.phaseDetails.project.baummape === null ? 'hidden' : 'block'
            "
            src="@/assets/navigation-map-icon.svg"
            alt=""
          />
        </div>
      </div>
      <div v-if="isMap" class="h-[calc(100vh-64px)] overflow-y-auto">
        <ProjectMap
          :phase-details="props.phaseDetails"
          @toggle-project-map="toggleProjectMap"
        />
      </div>
      <!-- Batches -->
      <SyncImageWidget :workers="phaseDetails.workers" :batches="batches" />
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.v-enter-from {
  transform: translateX(100%);
}

.v-leave-to {
  transform: translateX(-100%);
}
</style>
